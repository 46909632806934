import { useToast, VStack } from "@chakra-ui/react"
import { startCase } from "lodash"
import React, { useEffect, useState } from "react"
import { stripHtml } from "string-strip-html"
import { CheckEmailVerification, RestrictedEntriesModal } from "./components"
import { ContextProvider } from "./context"
import { useAlertsUpdateSubscription, useMeQuery } from "./graphql"
import { SplashPage } from "./pages/Splash.page"
import { RootRouter } from "./router/Root.router"
import { UnauthorizedRouter } from "./router/Unauthorized.router"

export const App: React.FC = () => {
	const [{ data, fetching }] = useMeQuery()

	const [{ data: alertsData }] = useAlertsUpdateSubscription()

	const toast = useToast()

	const [toastsShown, setToastsShown] = useState<string[]>([])

	useEffect(() => {
		if (alertsData?.alertsUpdate && !toastsShown.includes(alertsData.alertsUpdate._id)) {
			setToastsShown((prev) => [...prev, alertsData.alertsUpdate._id])
			toast({
				title: startCase(alertsData.alertsUpdate.type),
				description: stripHtml(alertsData.alertsUpdate.emailMessage).result,
				status: "warning",
				position: "top-right",
				duration: null,
				isClosable: true,
			})
		}
	}, [alertsData])

	useEffect(() => {
		return () => {
			setToastsShown([])
		}
	}, [alertsData])

	if (fetching) {
		return <SplashPage />
	}
	return (
		<ContextProvider>
			<VStack w="full" align="stretch">
				<CheckEmailVerification />
				{data?.me ? (
					<VStack w="full">
						<RestrictedEntriesModal />
						<RootRouter />
					</VStack>
				) : (
					<UnauthorizedRouter />
				)}
			</VStack>
		</ContextProvider>
	)
}
