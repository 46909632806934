import { Flex, HStack, Link, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedTagFragment } from "../../graphql"
import { Stat } from "../common"

export type TagListItemProps = {
	tag: ExtendedTagFragment
}

export const TagListItem: React.FC<TagListItemProps> = ({ tag }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/tags/${tag._id}`} />
			<HStack w="full" justify="space-between" px="2">
				<Flex flex="1" justify="center" align="center" flexWrap="wrap">
					{tag.user && (
						<Stat
							label="User"
							value={
								<Link as={NavLink} to={`/users/${tag.user._id}`}>
									{tag.user.name}
								</Link>
							}
						/>
					)}
					{tag.name && <Stat label="Name" value={tag.name} />}
					<Stat label="UID" value={tag.tagDecimalId} />
					<Stat label="Status" value={tag.status} />
					<Stat label="Battery Status" value={tag.batteryStatus} />
				</Flex>
			</HStack>
		</ListItem>
	)
}
