import { Button, Heading, Icon, useDisclosure, VStack } from "@chakra-ui/react"
import React from "react"
import { ChevronDown } from "react-feather"
import { MeUserFragment } from "../../graphql"
import { Label } from "./Label"
import { Stat } from "./Stat"
import { SwitchSiteModal } from "./SwitchSiteModal"

type SiteProps = {
	user: MeUserFragment
}

export const Site: React.FC<SiteProps> = ({ user }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	if (user.scopedSiteIds.length <= 1) {
		return (
			<Stat
				label="Site"
				value={
					<Label
						name={
							<Heading fontSize="sm" color="primary.500">
								{user.site.label.name}
							</Heading>
						}
						description={user.site.label.description}
					/>
				}
			/>
		)
	}

	return (
		<Button onClick={onOpen} h="full" py="8" variant="unstyled" _hover={{ bgColor: "primary.50" }}>
			<VStack w="full" align="center" spacing="0">
				<Stat
					label="Site"
					value={
						<Label
							name={
								<Heading fontSize="sm" color="primary.500">
									{user.site.label.name}
								</Heading>
							}
							description={user.site.label.description}
						/>
					}
				/>
				<Icon as={ChevronDown} boxSize="5" opacity=".4" />
			</VStack>
			{isOpen && <SwitchSiteModal isOpen={isOpen} onClose={onClose} user={user} />}
		</Button>
	)
}
