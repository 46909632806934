import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ExtendedUserRoleGroupFragment } from "../../graphql"
import { UserRoleGroupListItem } from "./UserRoleGroupListItem"

export type UserRoleGroupsListProps = {
	userRoleGroups: ExtendedUserRoleGroupFragment[]
	loadMore: () => void
}

export const UserRoleGroupsList: React.FC<UserRoleGroupsListProps> = ({ userRoleGroups, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{userRoleGroups.map((userRoleGroup) => (
				<>
					<UserRoleGroupListItem key={userRoleGroup._id} userRoleGroup={userRoleGroup} />
					<Divider />
				</>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
