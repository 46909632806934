import { useContext, useEffect } from "react"
import * as dashboardActions from "../context/Dashboard/actions"
import { DashboardContext } from "../context/Dashboard/context"
import { DashboardTypes } from "../context/Dashboard/types"

export const useDashboardContext = () => {
	const [state, dispatch] = useContext(DashboardContext)

	useEffect(() => {
		const type = localStorage.getItem("@LIGHTHOUSE_SMARTWORLD/DASHBOARD_TYPE")

		if (type && Object.values(DashboardTypes).includes(type as DashboardTypes)) {
			dispatch(dashboardActions.setDashboardType(type as DashboardTypes))
		} else {
			dispatch(dashboardActions.setDashboardType(DashboardTypes.STANDARD))
		}
	}, [])

	return { ...state, dispatch, ...dashboardActions }
}
