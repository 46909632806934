import { Badge, Box, BoxProps, HStack, Link, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds, format, isToday } from "date-fns"
import { motion, MotionProps } from "framer-motion"
import React, { ComponentType, Fragment } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserRouteRecordFragment } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { UserRoutePoint } from "./UserRoutePoint"

export type UserRouteRecordProps = {
	userRouteRecord: ExtendedUserRouteRecordFragment
}

const MotionBox = motion<MotionProps & Omit<BoxProps, "transition">>(Box as unknown as ComponentType<MotionProps & Omit<BoxProps, "transition">>)

export const UserRouteRecord: React.FC<UserRouteRecordProps> = ({ userRouteRecord }) => {
	if (userRouteRecord.routePoints?.length) {
		return (
			<Fragment>
				<VStack w="full" maxH="xs" overflowY="auto">
					{userRouteRecord.routePoints.map((r, index) => (
						<UserRoutePoint
							key={index}
							index={index}
							totalRoutePointsLength={userRouteRecord.routePoints?.length || 0}
							routeFindings={userRouteRecord.findings?.filter((rf) => rf.lastRoutePointIndex === index) || []}
							userRoutePoint={r}
						/>
					))}
				</VStack>
			</Fragment>
		)
	} else {
		return (
			<VStack w="full" maxH="xs" overflowY="auto">
				{userRouteRecord.locationRecords?.map((r, index, arr) => (
					<MotionBox key={r._id} w="full" initial={{ opacity: 0, translateX: "24px" }} animate={{ opacity: 1, translateX: 0 }} transition={{ delay: Math.min(index / 10, 2) }}>
						<HStack w="full" h="full" align="stretch">
							<Box
								pos="relative"
								w="2"
								bgColor={r.zoneId !== userRouteRecord.expectedRouteZoneIds[index] ? "error.500" : "success.500"}
								ml={2}
								mb={index === (userRouteRecord.locationRecords?.length ?? 1) - 1 ? 0 : -2}
								roundedTop={index === 0 ? "full" : undefined}
								roundedBottom={index === (userRouteRecord.locationRecords?.length ?? 1) - 1 ? "full" : undefined}
							>
								<Box pos="absolute" w="4" h="4" top="50%" left="50%" transform="translate(-50%, -50%)" bgColor={r.zoneId !== userRouteRecord.expectedRouteZoneIds[index] ? "error.500" : "success.500"} rounded="full" />
							</Box>
							<HStack w="full" justifyContent="space-between" bgColor="whiteAlpha.600" px="4" py="2" rounded="md">
								{r.zoneId !== userRouteRecord.expectedRouteZoneIds[index] && userRouteRecord.expectedRouteZones.find((z) => z._id === userRouteRecord.expectedRouteZoneIds[index]) ? (
									<VStack align="flex-start" spacing={0}>
										<Link as={NavLink} to={`/zones/${userRouteRecord.expectedRouteZoneIds[index]}`} fontSize="xs" color="success.500" textDecoration="line-through">
											{userRouteRecord.expectedRouteZones.find((z) => z._id === userRouteRecord.expectedRouteZoneIds[index])?.label.name}
										</Link>

										<Link as={NavLink} to={`/zones/${r.zoneId}`} fontSize="sm" fontWeight="bold" color={r.zoneId !== userRouteRecord.expectedRouteZoneIds[index] ? "error.500" : "success.500"}>
											{r.zone.label.name}
										</Link>
									</VStack>
								) : (
									<Link as={NavLink} to={`/zones/${r.zoneId}`} fontSize="sm" fontWeight="bold" color={r.zoneId !== userRouteRecord.expectedRouteZoneIds[index] ? "error.500" : "success.500"}>
										{r.zone.label.name}
									</Link>
								)}

								<HStack>
									{index === 0 || (userRouteRecord.isFinal && index === arr.length - 1) ? (
										<Text fontSize="xs" fontWeight="semibold">
											{format(new Date(index === 0 ? r.endAt : r.startAt), isToday(new Date(r.startAt)) ? "HH:mm" : "MMM dd, HH:mm")}
										</Text>
									) : (
										<Text fontSize="xs" fontWeight="semibold">
											{format(new Date(r.startAt), isToday(new Date(r.startAt)) ? "HH:mm" : "MMM dd, HH:mm")} - {format(new Date(r.endAt), isToday(new Date(r.endAt)) ? "HH:mm" : "MMM dd, HH:mm")}
										</Text>
									)}

									{index === 0 || (userRouteRecord.isFinal && index === arr.length - 1) ? <></> : <Badge>{getDurationInWords(differenceInSeconds(new Date(r.endAt), new Date(r.startAt)) || 1)}</Badge>}
								</HStack>
							</HStack>
						</HStack>
					</MotionBox>
				))}
			</VStack>
		)
	}
}
