import { VStack } from "@chakra-ui/react"
import React from "react"
import { GenerateUserPatrollingRouteReportForm } from "../../../forms/GenerateUserPatrollingRouteReport.form"

export const GenerateUserPatrollingRouteReport: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<GenerateUserPatrollingRouteReportForm />
		</VStack>
	)
}
