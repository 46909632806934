import { Link, Text } from "@chakra-ui/react"
import React, { Fragment, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { TopBannerStatus } from "../../context/TopBanner/types"
import { useMeQuery } from "../../graphql"
import { useTopBannerContext } from "../../hooks"

export const CheckEmailVerification: React.FC = () => {
	const [{ data }] = useMeQuery()

	const { isVisible, dispatch, setVisible, setInvisible, setChildren, setStatus } = useTopBannerContext()

	useEffect(() => {
		if (data?.me) {
			if (!data.me.email?.address) {
				dispatch(
					setChildren(
						<Text textAlign="center" fontSize="sm" color="warning.900">
							Your email is not available, please{" "}
							<Link as={NavLink} to={`/users/${data.me._id}/updateProfile`} fontWeight="bold">
								add
							</Link>{" "}
							your email address or you will not be able to receive any email alerts.
						</Text>
					)
				)
				dispatch(setStatus(TopBannerStatus.WARNING))
				dispatch(setVisible())
			} else if (!data.me.email.isVerified) {
				dispatch(
					setChildren(
						<Text textAlign="center" fontSize="sm" color="warning.900">
							Your email is not verified, please{" "}
							<Link as={NavLink} to="/verifyEmail" fontWeight="bold">
								verify
							</Link>{" "}
							your email address or you will not be able to receive any email alerts.
						</Text>
					)
				)
				dispatch(setStatus(TopBannerStatus.WARNING))
				dispatch(setVisible())
			}
		} else if (isVisible) {
			dispatch(setInvisible())
		}

		return () => {
			dispatch(setInvisible())
		}
	}, [data])

	return <Fragment />
}
