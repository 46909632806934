import { Divider, List } from "@chakra-ui/react"
import React from "react"
import { ReportFragment } from "../../../graphql"
import { ReportListItem } from "./ReportListItem"

export type ReportsListProps = {
	reports: ReportFragment[]
}

export const ReportsList: React.FC<ReportsListProps> = ({ reports }) => {
	return (
		<List spacing={2}>
			{reports.map((report) => (
				<>
					<ReportListItem key={report._id} report={report} />
					<Divider />
				</>
			))}
		</List>
	)
}
