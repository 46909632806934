import { Box, Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { AlertTargetGroupsByKeywordQueryVariables, useAlertTargetGroupsByKeywordQuery } from "../../graphql"

export type AlertTargetGroupSelectorProps = {
	onUpdate: (zoneIds: string[]) => void
	value: string[]
}

export const AlertTargetGroupSelector: React.FC<AlertTargetGroupSelectorProps> = ({ value, onUpdate }) => {
	const [variables, setVariables] = useState<AlertTargetGroupsByKeywordQueryVariables>({ keyword: "", pagination: { limit: 20, page: 1 } })

	const [{ data, fetching, error }] = useAlertTargetGroupsByKeywordQuery({ variables })

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.alertTargetGroupsByKeyword.hasNextPage) {
			setVariables((prev) => ({
				...prev,
				pagination: {
					...prev.pagination,
					page: (prev.pagination.page || 0) + 1,
				},
			}))
		}
	}, [data, fetching])

	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			nextPage()
		}
	}, [inViewport])

	const [keyword, setKeyword] = useState("")

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				keyword,
				pagination: { ...prev.pagination, page: 1 },
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (zoneId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, zoneId])
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(zoneId), 1)
			onUpdate(_value)
		}
	}

	const isAlertTargetGroupselected = useCallback((zoneId: string) => value.includes(zoneId), [value])

	const isAllSelected = useMemo(() => data?.alertTargetGroupsByKeyword.alertTargetGroups.map((r) => r._id).every((rid) => value.includes(rid)), [data, value])
	const isNoneSelected = useMemo(() => !value.length, [data, value])

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
			</HStack>
			{fetching && !data?.alertTargetGroupsByKeyword.alertTargetGroups.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading alert target groups</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.alertTargetGroupsByKeyword.alertTargetGroups.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any alert target groups.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isDisabled={data.alertTargetGroupsByKeyword.hasNextPage}
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked ? onUpdate(data.alertTargetGroupsByKeyword.alertTargetGroups.map((r) => r._id)) : onUpdate([])
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>Group</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.alertTargetGroupsByKeyword.alertTargetGroups.map((alertTargetGroup) => (
								<Tr key={alertTargetGroup._id}>
									<Td>
										<Checkbox isChecked={isAlertTargetGroupselected(alertTargetGroup._id)} onChange={(e) => handleChange(alertTargetGroup._id, e.target.checked)} />
									</Td>
									<Td>{alertTargetGroup.label.name}</Td>
								</Tr>
							))}
							<Box w="full" h="2" ref={ref as any} />
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more alert target groups</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
