import { Flex, Link } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedAlertFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type AlertDetailsProps = {
	alert: ExtendedAlertFragment
}

export const AlertDetails: React.FC<AlertDetailsProps> = ({ alert }) => {
	return (
		<Card title="Details">
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(alert.type)} />
				<Stat
					label="Alert Flow"
					value={
						<Link as={NavLink} to={`/alerts/flows/${alert.alertFlowId}`}>
							{alert.alertFlow.label.name}
						</Link>
					}
				/>

				<Stat label="Created" value={format(new Date(alert.createdAt), "MMM dd, yyyy HH:mm")} />
			</Flex>
		</Card>
	)
}
