import { Flex, Heading, HStack, LinkBox, LinkOverlay, ListItem, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserGroupFragment } from "../../graphql"

export type UserGroupListItemProps = {
	userGroup: ExtendedUserGroupFragment
}

export const UserGroupListItem: React.FC<UserGroupListItemProps> = ({ userGroup }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/users/groups/${userGroup._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start">
					<Heading fontSize="md" color="grayscale.title">
						{userGroup.label.name}
					</Heading>

					<Flex maxW="sm">
						<Text fontSize="sm" color="grayscale.label">
							{userGroup.users.map((user) => user.name).join(", ")}
						</Text>
					</Flex>
				</VStack>
			</HStack>
		</ListItem>
	)
}
