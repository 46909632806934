import { Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import TimePicker from "react-time-picker"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateWorkShiftMutationVariables, useCreateWorkShiftMutation } from "../graphql"

type CreateWorkShiftFormValues = CreateWorkShiftMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateWorkShiftFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	startAt: yup.string().required().label("Start Time"),
	endAt: yup.string().required().label("End Time"),
})

const initialValues: CreateWorkShiftFormValues = {
	label: { name: "", description: "" },
	startAt: "",
	endAt: "",
}

export const CreateWorkShiftForm: React.FC = () => {
	const [{ fetching }, createWorkShift] = useCreateWorkShiftMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateWorkShiftFormValues) => {
		const { data, error } = await createWorkShift({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createWorkShift) {
			navigate(`/workShifts/${data.createWorkShift._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateWorkShiftFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.errors.startAt && formik.touched.startAt)}>
					<FormLabel fontWeight="bold">Start Time</FormLabel>
					<TimePicker
						className="default-time-picker"
						disableClock
						hourPlaceholder="hh"
						minutePlaceholder="mm"
						format="hh:mm a"
						clearIcon={null}
						{...formik.getFieldProps("startAt")}
						onChange={(time) => formik.setFieldValue("startAt", time)}
					/>

					<FormErrorMessage>{formik.errors.startAt}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.errors.endAt && formik.touched.endAt)}>
					<FormLabel fontWeight="bold">End Time</FormLabel>
					<TimePicker
						className="default-time-picker"
						disableClock
						hourPlaceholder="hh"
						minutePlaceholder="mm"
						format="hh:mm a"
						clearIcon={null}
						{...formik.getFieldProps("endAt")}
						onChange={(time) => formik.setFieldValue("endAt", time)}
					/>

					<FormErrorMessage>{formik.errors.endAt}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
