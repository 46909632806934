import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { ExtendedZoneFragment, useRemoveReaderFromZoneMutation } from "../../graphql"

export type RemoveReaderFromZoneProps = {
	zone: ExtendedZoneFragment
	readerId: string
}

export const RemoveReaderFromZone: React.FC<RemoveReaderFromZoneProps> = ({ zone, readerId }) => {
	const [{ fetching }, remove] = useRemoveReaderFromZoneMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleRemove = async () => {
		const { error, data } = await remove({ zoneId: zone._id, readerId })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.removeReaderFromZone) {
			toast({ description: "Removed the reader successfully", status: "success" })
			navigate(`/zones/${zone._id}`)
			return
		} else {
			toast({ description: "Couldn't remove the reader", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to remove the reader from {zone.label.name}?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/zones/${zone._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="error" isLoading={fetching} onClick={handleRemove}>
						Remove
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
