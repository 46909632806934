import { Heading, HStack } from "@chakra-ui/react"
import React from "react"
import { DescriptionPopover } from "./DescriptionPopover"

export type LabelProps = {
	name: string | React.ReactNode
	description?: string | React.ReactNode
}

export const Label: React.FC<LabelProps> = ({ name, description }) => {
	return (
		<HStack>
			{typeof name === "string" ? (
				<Heading fontSize="md" color="grayscale.title">
					{name}
				</Heading>
			) : (
				name
			)}
			{description && <DescriptionPopover>{description}</DescriptionPopover>}
		</HStack>
	)
}
