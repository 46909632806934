import { ChakraProvider } from "@chakra-ui/react"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "urql"
import { App } from "./App"
import { createUrqlClient } from "./graphql"
import "./styles/global.css"
import { theme } from "./theme"

const urqlClient = createUrqlClient()

const container = document.getElementById("root")
if (!container) throw new Error("Failed to find the root element")
const root = ReactDOM.createRoot(container)

root.render(
	<React.StrictMode>
		<Provider value={urqlClient}>
			<BrowserRouter>
				<ChakraProvider theme={theme}>
					<App />
				</ChakraProvider>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
)
