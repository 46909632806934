import { Button, Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedAlertTargetGroupFragment, useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type AlertTargetGroupDetailsProps = {
	alertTargetGroup: ExtendedAlertTargetGroupFragment
}

export const AlertTargetGroupDetails: React.FC<AlertTargetGroupDetailsProps> = ({ alertTargetGroup }) => {
	const [{ data }] = useMeQuery()

	const canUpdate = useMemo(() => checkForPermissions(data?.me, "alerts/update-alert-target-group")[0], [data])

	return (
		<Card
			title="Details"
			action={
				canUpdate && (
					<Button as={NavLink} to={`/alerts/targetGroups/${alertTargetGroup._id}/update`} colorScheme="primary" size="sm">
						Update
					</Button>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Name" value={alertTargetGroup.label.name} />
				<Stat label="Created" value={format(new Date(alertTargetGroup.createdAt), "MMM dd, yyyy HH:mm")} />
			</Flex>
		</Card>
	)
}
