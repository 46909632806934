export const global = {
	p: {
		color: "grayscale.body",
	},
	span: {
		color: "grayscale.body",
	},
	label: {
		color: "grayscale.label",
	},
	h1: {
		color: "grayscale.title",
	},
	h2: {
		color: "grayscale.title",
	},
	h3: {
		color: "grayscale.title",
	},
	h4: {
		color: "grayscale.title",
	},
	h5: {
		color: "grayscale.title",
	},
	h6: {
		color: "grayscale.title",
	},
	a: {
		color: "grayscale.title",
	},
}
