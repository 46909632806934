import { VStack } from "@chakra-ui/react"
import React from "react"
import { GenerateZoneRestrictedEntriesReportForm } from "../../../forms"

export const GenerateZoneRestrictedEntriesReport: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<GenerateZoneRestrictedEntriesReportForm />
		</VStack>
	)
}
