import { VStack } from "@chakra-ui/react"
import React from "react"
import { CreateAlertFlowForm } from "../../forms"

export const CreateAlertFlow: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<CreateAlertFlowForm />
		</VStack>
	)
}
