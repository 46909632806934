import { Heading, HStack, LinkBox, LinkOverlay, ListItem, Tag, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { PermissionsGroupFragment } from "../../graphql"

export type PermissionsGroupListItemProps = {
	permissionsGroup: PermissionsGroupFragment
}

export const PermissionsGroupListItem: React.FC<PermissionsGroupListItemProps> = ({ permissionsGroup }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/permissionsGroups/${permissionsGroup._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start">
					<Heading fontSize="md" color="grayscale.title">
						{permissionsGroup.label.name}
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						{permissionsGroup.tags.length} tags
					</Heading>
				</VStack>

				<VStack align="flex-end">
					{permissionsGroup.isPrivateToAdmin && (
						<Tag variant="outline" colorScheme="secondary">
							Admin
						</Tag>
					)}
				</VStack>
			</HStack>
		</ListItem>
	)
}
