import { Flex, HStack, Link, ListItem, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import { startCase } from "lodash"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserRoleFragment, UserRoleStatus, UserRoleTypes } from "../../graphql"
import { Stat } from "../common"

export type UserRoleListItemProps = {
	userRole: ExtendedUserRoleFragment
	includeUser?: boolean
}

export const UserRoleListItem: React.FC<UserRoleListItemProps> = ({ userRole, includeUser }) => {
	return (
		<ListItem _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<HStack justifyContent="space-between" p="2">
				<Flex justify="center" align="center" flexWrap="wrap">
					{includeUser ? (
						<Stat
							label="User"
							value={
								<Link as={NavLink} to={`/users/${userRole.userId}`}>
									{userRole.user.name}
								</Link>
							}
						/>
					) : (
						<></>
					)}
					<Stat label="Role Type" value={startCase(userRole.type)} />
					<Stat label="Role Group" value={userRole.userRoleGroup.label.name} />
					<Stat label="Department" value={userRole.userRoleGroup.department.label.name} />
					<Stat label="Designation" value={userRole.designation || "Not available"} />
					{(userRole.type === UserRoleTypes.Stationary || userRole.type === UserRoleTypes.Patrolling) && userRole.userRoleGroup.workShift && (
						<Stat label="Work Shift" value={`${userRole.userRoleGroup.workShift.label.name} (${userRole.userRoleGroup.workShift.startAt}-${userRole.userRoleGroup.workShift.endAt})`} />
					)}
					{userRole.userRoleGroup.allowedZones?.length ? (
						<Stat label="Allowed Zones" value={userRole.userRoleGroup.allowedZones.map((zone) => zone.label.name).join(", ")} />
					) : userRole.userRoleGroup.allowedZoneGroups?.length ? (
						<Stat label="Allowed Zone Groups" value={userRole.userRoleGroup.allowedZoneGroups.map((group) => group.label.name).join(", ")} />
					) : (
						<></>
					)}
					<Stat label="Start" value={format(new Date(userRole.startAt), "MMM dd, yyyy HH:mm")} />
					<Stat label="End" value={userRole.endAt ? format(new Date(userRole.endAt), "MMM dd, yyyy HH:mm") : "Not available"} />
					<Stat label="Assigned Since" value={format(new Date(userRole!.assignedAt), "MMM dd, yyyy HH:mm")} />
					<Stat
						label="Status"
						value={
							userRole.status === UserRoleStatus.Current ? (
								<Text color="success.600">Current</Text>
							) : userRole.status === UserRoleStatus.Expired ? (
								<Text color="error.600">Expired</Text>
							) : userRole.status === UserRoleStatus.Future ? (
								<Text color="warning.600">Future</Text>
							) : (
								<Text color="warning.600">Inactive</Text>
							)
						}
					/>
				</Flex>
			</HStack>
		</ListItem>
	)
}
