import flatten from "lodash.flatten"
import { MeUserFragment } from "../graphql"

export const checkForPermissions = (user?: MeUserFragment | null, ...requiredPermissionTags: string[]): [boolean, string[]] => {
	const unavailablePermissionTags = new Set<string>()

	if (!user) return [false, requiredPermissionTags]

	const userPermissionTags = flatten(user.permissionsGroups?.map((g) => g.tags) || [])

	requiredPermissionTags.forEach((permissionTags) => {
		if (!userPermissionTags.includes(permissionTags)) {
			unavailablePermissionTags.add(permissionTags)
		}
	})

	return [[...unavailablePermissionTags].length === 0, [...unavailablePermissionTags]]
}

export const checkForSomePermissions = (user?: MeUserFragment | null, ...requiredPermissionTags: string[]): [boolean, string[]] => {
	const unavailablePermissionTags = new Set<string>()

	if (!user) return [false, requiredPermissionTags]

	const userPermissionTags = flatten(user.permissionsGroups?.map((g) => g.tags) || [])

	requiredPermissionTags.forEach((permissionTags) => {
		if (!userPermissionTags.includes(permissionTags)) {
			unavailablePermissionTags.add(permissionTags)
		}
	})

	return [[...unavailablePermissionTags].length < requiredPermissionTags.length, [...unavailablePermissionTags]]
}
