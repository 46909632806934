import { AccordionButton, AccordionItem, AccordionPanel, VStack } from "@chakra-ui/react"
import React, { Fragment, PropsWithChildren, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"

export type SidebarAccordionItemProps = {
	button: (isActive: boolean) => React.ReactElement
	tos: string[]
	permissionTags?: string[]
	permissionsType?: "or" | "and"
} & PropsWithChildren

export const SidebarAccordionItem: React.FC<SidebarAccordionItemProps> = ({ button, tos, permissionTags, permissionsType = "and", children }) => {
	const isActive = new RegExp(`^(${tos.join("|")})(/|)$`).test(useLocation().pathname)

	const [{ data }] = useMeQuery()

	const isAllowed = useMemo(() => {
		if (!permissionTags?.length) return true

		if (!data?.me) return false

		if (permissionsType === "and") {
			return checkForPermissions(data.me, ...permissionTags)[0]
		} else {
			return permissionTags.every((tag) => checkForPermissions(data.me, tag)[0]) || permissionTags.some((tag) => checkForPermissions(data.me, tag)[0])
		}
	}, [data, permissionTags, children])

	if (!isAllowed) return <Fragment />

	return (
		<AccordionItem>
			<AccordionButton p="0 !important">{button(isActive)}</AccordionButton>

			<AccordionPanel as={VStack} w="full" align="stretch" pr="0">
				{children}
			</AccordionPanel>
		</AccordionItem>
	)
}
