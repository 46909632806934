import { Heading, HStack, LinkBox, LinkOverlay, ListItem, Tag, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertFragment, AlertTypes } from "../../graphql"

export type AlertListItemProps = {
	alert: AlertFragment
}

export const AlertListItem: React.FC<AlertListItemProps> = ({ alert }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/alerts/${alert._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{startCase(alert.type)}
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						{format(new Date(alert.createdAt), "MMM dd, yyyy HH:mm")}
					</Heading>
				</VStack>

				<VStack align="flex-end">
					{(alert.type === AlertTypes.IdleZone || alert.type === AlertTypes.Crowd) && <Text fontSize="sm">{extractZoneNameFromIdleorCrowdedZoneMessage(alert.notificationMessage)}</Text>}
					{alert.type === AlertTypes.InactiveReader && <Text fontSize="sm">{extractZoneNameFromInactiveReaderMessage(alert.notificationMessage)}</Text>}
					{alert.type === AlertTypes.IncorrectRoute && <Text fontSize="sm">{extractUserNameFromIncorrectRouteMessage(alert.notificationMessage)}</Text>}
					<Tag variant="outline" colorScheme={alert.isResolved === true ? "success" : "error"}>
						{alert.isResolved && alert.isResolved === true ? "Closed" : "Open"}
					</Tag>
				</VStack>
			</HStack>
		</ListItem>
	)
}

function extractZoneNameFromIdleorCrowdedZoneMessage(inputString: string) {
	const start = inputString.indexOf("The") + 3
	const end = inputString.indexOf("zone") - 1
	return inputString.substring(start, end)
}

function extractZoneNameFromInactiveReaderMessage(inputString: string) {
	const start = inputString.indexOf("at") + 3
	const end = inputString.indexOf("zone") - 1
	return inputString.substring(start, end)
}

function extractUserNameFromIncorrectRouteMessage(inputString: string) {
	const start = inputString.indexOf("User") + 5
	const end = inputString.indexOf(" has")
	return inputString.substring(start, end)
}
