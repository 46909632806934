import { Button, Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { Fragment, useCallback } from "react"
import { NavLink } from "react-router-dom"
import { VerifyEmailForm } from "../../forms"
import { useMeQuery, useSendEmailVerificationSecretCodeMutation } from "../../graphql"

export const VerifyEmail: React.FC = () => {
	const [{ data }] = useMeQuery()

	const [{ fetching, error, data: sendData }, send] = useSendEmailVerificationSecretCodeMutation()

	const handleSendEmail = useCallback(async () => {
		await send({})
	}, [])

	if (!data?.me?.email?.address) {
		return (
			<VStack w="full" maxW="sm" spacing={6} align="flex-start">
				<Heading fontSize="sm" color="grayscale.title">
					You don&apos;t have any email address on your profile.
				</Heading>
				<Heading fontSize="sm" color="grayscale.label">
					You need to add your email first to verify it.
				</Heading>
			</VStack>
		)
	}

	if (data.me.email.isVerified) {
		return (
			<VStack w="full" maxW="sm" spacing={6} align="flex-start">
				<Heading fontSize="sm" color="success.500">
					Your email {data?.me?.email?.address} has been verified.
				</Heading>
				<Button as={NavLink} to="/profile" variant="link" size="sm">
					Go to your profile
				</Button>
			</VStack>
		)
	}

	return (
		<VStack w="full" maxW="sm" spacing={6} align="flex-start">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Sending a secret code to your email</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : sendData?.sendEmailVerificationSecretCode ? (
				<Fragment>
					<Heading fontSize="sm" color="grayscale.title">
						We&apos;ve sent a secret code on your email {data.me.email.address}.
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						You need to enter the secret code here to verify your email.
					</Heading>

					<VerifyEmailForm />
				</Fragment>
			) : (
				<Fragment>
					<Heading fontSize="sm" color="grayscale.title">
						We will send a secret code on your email {data.me.email.address}.
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						You need to enter the secret code here to verify your email.
					</Heading>

					<Button colorScheme="primary" size="sm" onClick={handleSendEmail}>
						Send Secret Code
					</Button>
				</Fragment>
			)}
		</VStack>
	)
}
