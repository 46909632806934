import { Flex, Icon, ListItem, Tag, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import React from "react"
import { XCircle } from "react-feather"
import { ExtendedUserLocationRecordFragment } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { transformDateTime } from "../../utils/transformDateTime"
import { Stat } from "../common"

export type UserLocationListItemProps = {
	userLocationRecord: ExtendedUserLocationRecordFragment
}

export const UserLocationListItem: React.FC<UserLocationListItemProps> = ({ userLocationRecord }) => {
	return (
		<ListItem>
			<VStack w="full" align="flex-start" p="2">
				{userLocationRecord.isRestricted && (
					<Tag colorScheme="error" variant="solid">
						Restricted <Icon as={XCircle} ml="2" />
					</Tag>
				)}
				<Flex w="full" justify="space-between" align="center">
					<Stat label="Zone" value={userLocationRecord.zone.label.name} />
					<Stat label="Since" value={transformDateTime(new Date(userLocationRecord.startAt))} />
					<Stat label="Until" value={transformDateTime(new Date(userLocationRecord.endAt))} />
					<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(userLocationRecord.endAt), new Date(userLocationRecord.startAt)))} />
				</Flex>
			</VStack>
		</ListItem>
	)
}
