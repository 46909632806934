import { Button, Divider, Flex, HStack, Input, List, Text, useToast, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { Fragment, useCallback, useState } from "react"
import { ExtendedAlertFragment, useMeQuery } from "../../graphql"
import { useAcknowledgeAlertMutation, useResolveAlertMutation } from "../../graphql/generated"
import { Card, Stat } from "../common"
import { UserListItem } from "../users/UserListItem"

export type AlertedUsersProps = {
	alert: ExtendedAlertFragment
}

export const AlertedUsers: React.FC<AlertedUsersProps> = ({ alert }) => {
	const [{ data }] = useMeQuery()
	const [resolveVisible, setResolveVisible] = React.useState(false)
	const [acknowledgeVisible, setAcknowledgeVisible] = React.useState(false)
	const toast = useToast()

	/*eslint no-extra-boolean-cast: "off"*/
	const canAcknowledge = useCallback(
		(index: number) => !Boolean(alert.levels[index]?.responses?.find((response) => response.userId === data?.me?._id)),

		[data]
	)

	const canResolve = useCallback(
		(index: number) =>
			Boolean(alert.levels[index]?.responses!.find((response) => response.userId === data?.me?._id)) && (!Boolean(alert.levels[index]?.responses!.find((response) => response.userId === data?.me?._id)?.resolvedAt) as boolean),
		[data]
	)

	const [, setIndex] = useState(-1)

	const [text, setText] = React.useState("")
	const [{ fetching: resolveAlertFetching }, resolveAlert] = useResolveAlertMutation()
	const [{ fetching: acknowledgeAlertFetching }, acknowledgeAlert] = useAcknowledgeAlertMutation()

	const handleAcknowledgeAlert = async (index: number) => {
		const { data, error } = await acknowledgeAlert({ alertId: alert._id, message: text, level: index as number })
		if (error) {
			toast({
				title: "Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
				duration: 9000,
				isClosable: true,
			})
		}
		if (data?.acknowledgeAlert) {
			toast({
				title: "Success",
				description: "Alert acknowledged",
				status: "success",
				duration: 9000,
				isClosable: true,
			})

			setAcknowledgeVisible(false)
		}
	}

	const handleResolveAlert = async (index: number) => {
		const { data, error } = await resolveAlert({ alertId: alert._id, message: text, level: index as number })
		if (error) {
			toast({
				title: "Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
				duration: 9000,
				isClosable: true,
			})
		}
		if (data?.resolveAlert) {
			toast({
				title: "Success",
				description: "Alert resolved",
				status: "success",
				duration: 9000,
				isClosable: true,
			})
			setResolveVisible(false)
		}
	}
	return (
		<Fragment>
			{alert.levels.map((level, index) => (
				<Card key={index} title={`Alerted Users - Level ${index + 1}`}>
					<HStack w="full" justify="center" align="center">
						{canAcknowledge(index) && !acknowledgeVisible && (
							<Button
								size="sm"
								variant="solid"
								colorScheme="purple"
								onClick={() => {
									setAcknowledgeVisible(true)
									setIndex(index)
								}}
							>
								Acknowledge
							</Button>
						)}
						{acknowledgeVisible && (
							<VStack w="fit-content" align="stretch" p="5" rounded={"md"} boxShadow=" 1px 3px 3px rgba(0, 0, 0, 0.2)">
								<Text fontSize="xs" color="grayscale.label">
									Acknowledgment
								</Text>
								<Input placeholder="Acknowledgment" value={text} onChange={(e) => setText(e.target.value)} />
								<HStack w="full" justify="flex-end">
									<Button
										size="sm"
										variant="outline"
										colorScheme="purple"
										onClick={() => {
											handleAcknowledgeAlert(index)
											setText("")
										}}
										isLoading={acknowledgeAlertFetching}
									>
										Acknowledge
									</Button>
									<Button
										size="sm"
										variant="outline"
										colorScheme="red"
										onClick={() => {
											setAcknowledgeVisible(false)
											setText("")
										}}
									>
										Cancel
									</Button>
								</HStack>
							</VStack>
						)}
						{canResolve(index) && !resolveVisible && (
							<Button
								size="sm"
								variant="outline"
								colorScheme="primary"
								onClick={() => {
									setResolveVisible(true)
									setIndex(index)
								}}
								isLoading={resolveAlertFetching}
							>
								Resolve
							</Button>
						)}
						{resolveVisible && (
							<VStack w="fit-content" align="stretch" p="5" rounded={"md"} boxShadow=" 1px 3px 3px rgba(0, 0, 0, 0.2)">
								<Text fontSize="xs" color="grayscale.label">
									Resolution
								</Text>
								<Input placeholder="Resolution" value={text} onChange={(e) => setText(e.target.value)} />
								<HStack w="full" justify="flex-end">
									<Button
										size="sm"
										variant="outline"
										colorScheme="primary"
										onClick={() => {
											handleResolveAlert(index)
											setText("")
										}}
										isLoading={resolveAlertFetching}
									>
										Resolve
									</Button>
									<Button
										size="sm"
										variant="outline"
										colorScheme="red"
										onClick={() => {
											setResolveVisible(false)
											setText("")
										}}
									>
										Cancel
									</Button>
								</HStack>
							</VStack>
						)}
					</HStack>
					<List w="full" spacing={2}>
						<VStack w="full" align="stretch" key={index}>
							{level.alertedUsers?.map((user) => (
								<Fragment key={user._id}>
									<VStack key={user._id} w="full" align="stretch" spacing={0} p="2">
										<UserListItem key={user._id} user={user} />
										{level.responses?.find((r) => r.userId === user._id) && (
											<VStack w="full" align="stretch">
												<Flex w="full" justify="space-around" flexWrap="wrap">
													{level.responses?.find((r) => r.userId === user._id)?.acknowlegedAt && (
														<Stat label="Acknowleged At" value={format(new Date(level.responses?.find((r) => r.userId === user._id)?.acknowlegedAt), "MMM dd, yyyy HH:mm")} />
													)}
													{level.responses?.find((r) => r.userId === user._id)?.resolvedAt && (
														<Stat label="Resolved At" value={format(new Date(level.responses?.find((r) => r.userId === user._id)?.resolvedAt), "MMM dd, yyyy HH:mm")} />
													)}
												</Flex>

												<Flex w="full" justify="space-around" flexWrap="wrap">
													{level.responses?.find((r) => r.userId === user._id)?.acknowlegment && <Stat label="Acknowledgment" value={level.responses?.find((r) => r.userId === user._id)?.acknowlegment} />}
													{level.responses?.find((r) => r.userId === user._id)?.resolution && <Stat label="Resolution" value={level.responses?.find((r) => r.userId === user._id)?.resolution} />}
												</Flex>
											</VStack>
										)}
									</VStack>
									<Divider />
								</Fragment>
							))}
						</VStack>
					</List>
				</Card>
			))}
		</Fragment>
	)
}
