import { Button, ButtonGroup, Text, useToast, VStack, Icon } from "@chakra-ui/react"
import React from "react"
import { Lock } from "react-feather"
import { NavLink, useNavigate } from "react-router-dom"

import { ExtendedUserFragment, useResetUserPasswordMutation } from "../../graphql"
import { UserTag } from "../common"

export type ResetUserPasswordProps = {
	user: ExtendedUserFragment
}

export const ResetUserPassword: React.FC<ResetUserPasswordProps> = ({ user }) => {
	const [{ fetching }, resetPassword] = useResetUserPasswordMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleDeactivate = async () => {
		const { error, data } = await resetPassword({ userId: user._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.resetUserPassword) {
			toast({ description: "Password reset successfully", status: "success" })
			navigate(`/users/${user._id}`)
			return
		} else {
			toast({ description: "Couldn't reset the password", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<UserTag user={user} />
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to reset the password of {user.name}? New password will be same as username
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/users/${user._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="primary" rightIcon={<Icon as={Lock} color="white" />} isLoading={fetching} onClick={handleDeactivate}>
						Reset Password
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
