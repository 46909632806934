import { Center, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds, format } from "date-fns"
import React from "react"
import { ExtendedUserFragment, useTodaysUserAttendanceQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Card, LiveIndicator, Stat } from "../common"
import { UserAttendanceMarkManualIn, UserAttendanceMarkManualOut } from "../userAttendance"

export type UserActiveAttendanceProps = {
	user: ExtendedUserFragment
}

export const UserActiveAttendance: React.FC<UserActiveAttendanceProps> = ({ user }) => {
	const [{ data, fetching, error }] = useTodaysUserAttendanceQuery({ variables: { userId: user._id } })

	return (
		<>
			<Card title="Active attendance" action={<LiveIndicator />} alwaysShowAction>
				{fetching ? (
					<Center w="full" py="4">
						<VStack w="full" color="grayscale.label">
							<Text fontSize="sm">Loading active attendance</Text>
							<Spinner size="sm" />
						</VStack>
					</Center>
				) : error ? (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							{error.message.replace("[GraphQL] ", "")}
						</Text>
					</Center>
				) : data?.todaysUserAttendance ? (
					<Flex w="full" justify="center" align="center" flexWrap="wrap">
						{data.todaysUserAttendance.inAt ? <Stat label="In Time" value={format(new Date(data.todaysUserAttendance.inAt), "hh:mm a")} /> : null}
						{data.todaysUserAttendance.outAt ? <Stat label="Out Time" value={format(new Date(data.todaysUserAttendance.outAt), "hh:mm a")} /> : null}
						{data.todaysUserAttendance.inAt && data.todaysUserAttendance.outAt ? (
							<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(data.todaysUserAttendance.outAt), new Date(data.todaysUserAttendance.inAt)))} />
						) : null}
						{data.todaysUserAttendance.inRemarks ? <Stat label="In Remarks" value={data.todaysUserAttendance.inRemarks} /> : null}
						{data.todaysUserAttendance.outRemarks ? <Stat label="Out Remarks" value={data.todaysUserAttendance.outRemarks} /> : null}
					</Flex>
				) : (
					<VStack w="full" align="flex-start" p="2">
						<Center w="full" py="4">
							<Text fontSize="md" color="error.500">
								Couldn&apos;t find active attendance
							</Text>
						</Center>
					</VStack>
				)}
			</Card>
			{!fetching &&
				!user.isBlacklisted &&
				(!data?.todaysUserAttendance ? (
					<UserAttendanceMarkManualIn userId={user._id} />
				) : !data.todaysUserAttendance.inAt ? (
					<UserAttendanceMarkManualIn userId={user._id} />
				) : (
					!data.todaysUserAttendance.outAt && <UserAttendanceMarkManualOut userId={user._id} />
				))}
		</>
	)
}
