import { Button, ButtonGroup, Flex, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserFragment, MeUserFragment, TagStatus, useMeQuery, UserRoleTypes } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type UserTagDetailsProps = {
	user: ExtendedUserFragment | MeUserFragment
	disableActions?: boolean
}

export const UserTagDetails: React.FC<UserTagDetailsProps> = ({ user, disableActions }) => {
	const [{ data }] = useMeQuery()

	const canAssign = useMemo(() => {
		if (user.tag) return false

		if (user.isBlacklisted) return false

		if (user.type.roleType === UserRoleTypes.Admin) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "tags/assign-tag")[0]
		}
	}, [data, user])

	const canFlush = useMemo(() => {
		if (!user.tag) return false

		if (user.type.roleType === UserRoleTypes.Admin) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "tags/flush-tag")[0]
		}
	}, [data, user])

	const canDeactivate = useMemo(() => {
		if (!user.tag || user.tag.status !== TagStatus.Active) return false

		if (user.type.roleType === UserRoleTypes.Admin) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "tags/deactivate-tag")[0]
		}
	}, [data, user])

	const canActivate = useMemo(() => {
		if (!user.tag || user.tag.status !== TagStatus.Inactive) return false

		if (user.type.roleType === UserRoleTypes.Admin) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "tags/activate-tag")[0]
		}
	}, [data, user])

	return (
		<Card
			title="Tag"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssign && (
							<Button as={NavLink} to={`/users/${user._id}/assignTag`} colorScheme="primary" size="sm">
								Assign
							</Button>
						)}
						{canFlush && (
							<Button as={NavLink} to={`/users/${user._id}/flushTag`} colorScheme="error" size="sm">
								Flush
							</Button>
						)}
						{canDeactivate && (
							<Button as={NavLink} to={`/users/${user._id}/deactivateTag`} colorScheme="error" size="sm">
								Deactivate
							</Button>
						)}
						{canActivate && (
							<Button as={NavLink} to={`/users/${user._id}/activateTag`} colorScheme="success" size="sm">
								Activate
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				{user.tag?.name && <Stat label="Name" value={user.tag.name} />}
				<Stat label="UID" value={user.tag?.tagDecimalId || "Not available"} />
				<Stat label="Status" value={user.tag?.status === TagStatus.Active ? <Text color="success.600">Active</Text> : user.tag?.status === TagStatus.Inactive ? <Text color="error.600">Inactive</Text> : "Not available"} />
				<Stat label="Battery Status" value={user.tag?.batteryStatus || "Not available"} />
				<Stat label="Assigned Since" value={user.tag?.assignedAt ? format(new Date(user.tag.assignedAt), "MMM dd, yyyy HH:mm") : "Not available"} />
			</Flex>
		</Card>
	)
}
