import { VStack } from "@chakra-ui/react"
import React from "react"
import { Header } from "../../../components"
import { GenerateUserPatrollingRouteReport } from "../../../components/reports/generate/GenerateUserPatrollingRouteReport"

const GenerateUserPatrollingRouteReportPage: React.FC = () => {
	return (
		<VStack w="full" align="stretch" p="8" spacing={4}>
			<Header title="Generate User Patrolling Route Report" />
			<VStack w="full" align="stretch">
				<VStack w="full" align="stretch" spacing={4}>
					<GenerateUserPatrollingRouteReport />
				</VStack>
			</VStack>
		</VStack>
	)
}

export default GenerateUserPatrollingRouteReportPage
