export enum DashboardContextActions {
	SET_DASHBOARD_TYPE,
}

export enum DashboardTypes {
	STANDARD = "Standard",
	CONFIDENTIALITY = "Confidentiality",
}

export type DashboardContextState = {
	type?: DashboardTypes
}

export type DashboardContextAction = { type: DashboardContextActions; payload: DashboardTypes }
