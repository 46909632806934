import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateZoneGroupMutationVariables, useCreateZoneGroupMutation, useZonesQuery } from "../graphql"

type CreateZoneGroupFormValues = CreateZoneGroupMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateZoneGroupFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	zoneIds: yup.array(yup.string()).required().min(1).label("Zones"),
})

const initialValues: CreateZoneGroupFormValues = {
	label: { name: "", description: "" },
	zoneIds: [],
}

export const CreateZoneGroupForm: React.FC = () => {
	const [{ fetching }, createZoneGroup] = useCreateZoneGroupMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateZoneGroupFormValues) => {
		const { data, error } = await createZoneGroup({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createZoneGroup) {
			navigate(`/zones/groups/${data.createZoneGroup._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateZoneGroupFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: zonesData, error: zonesError, fetching: zonesFetching }] = useZonesQuery()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.zoneIds && formik.errors.zoneIds)}>
					<FormLabel fontWeight="bold">Zones</FormLabel>

					{zonesFetching ? (
						<Text>Fetching zones</Text>
					) : zonesError ? (
						<VStack>
							<Text>Couldn&apos;t fetch zones</Text>
							<Text>{zonesError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !zonesData?.zones.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch zones</Text>
						</VStack>
					) : (
						<Select multiple resize="vertical" variant="filled" bgColor="grayscale.input-background" {...formik.getFieldProps("zoneIds")}>
							{zonesData.zones.map((zone) => (
								<option key={zone._id} style={{ backgroundColor: "transparent" }} value={zone._id}>
									{zone.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.zoneIds}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
