import React, { PropsWithChildren } from "react"
import { DashboardContextProvider } from "./Dashboard"
import { TopBannerContextProvider } from "./TopBanner"

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<TopBannerContextProvider>
			<DashboardContextProvider>{children}</DashboardContextProvider>
		</TopBannerContextProvider>
	)
}
