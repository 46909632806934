import { VStack } from "@chakra-ui/react"
import React from "react"
import { CreateAlertTargetGroupForm } from "../../forms"

export const CreateAlertTargetGroup: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<CreateAlertTargetGroupForm />
		</VStack>
	)
}
