import { useSearchParams as _useSearchParams } from "react-router-dom"

export const useSearchParams = (): [URLSearchParams, { getSearchParam: <T>(key: string) => T | undefined; handleSearchParamUpdate: (key: string, value: string) => void; handleSearchParamDelete: (key: string) => void }] => {
	const [searchParams, setSearchParams] = _useSearchParams()

	const getSearchParam = <T>(key: string): T | undefined => (searchParams.get(key) || undefined) as T | undefined

	const handleSearchParamUpdate = (key: string, value: string) => {
		setSearchParams((prev) => {
			prev.set(key, value)

			return prev
		})
	}

	const handleSearchParamDelete = (key: string) => {
		setSearchParams((prev) => {
			prev.delete(key)

			return prev
		})
	}

	return [searchParams, { getSearchParam, handleSearchParamDelete, handleSearchParamUpdate }]
}
