import { Center, Flex, Spinner, Stack, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { CreateUserRoleGroupForm } from "../../forms"
import { usePatrollingRouteByIdQuery } from "../../graphql"
import { Card, Stat } from "../common"

export const CreateUserRoleGroup: React.FC = () => {
	const [selectedPatrollingRouteId, setSelectedPatrollingRouteId] = useState("")

	const [{ data, fetching, error }] = usePatrollingRouteByIdQuery({ variables: { patrollingRouteId: selectedPatrollingRouteId }, pause: !selectedPatrollingRouteId })

	return (
		<VStack w="full" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }} spacing={6}>
				<VStack w="full" maxW="sm">
					<CreateUserRoleGroupForm onSelectPatrollingRoute={setSelectedPatrollingRouteId} />
				</VStack>
				{selectedPatrollingRouteId && (
					<Card title="Selected Route" maxW="xl">
						{fetching ? (
							<Center w="full" py="4">
								<VStack w="full" color="grayscale.label">
									<Text fontSize="sm">Loading details</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : error ? (
							<Center py="4">
								<Text fontSize="sm" fontWeight="semibold" color="error.500">
									{error.message.replace("[GraphQL] ", "")}
								</Text>
							</Center>
						) : data?.patrollingRouteById ? (
							<Flex justify="center" align="center" flexWrap="wrap" letterSpacing={0.5}>
								<Stat label="Name" value={data?.patrollingRouteById.label.name} />
								<Stat label="Min. Time" value={data.patrollingRouteById.minTime} />
								<Stat label="Max. Time" value={data.patrollingRouteById.maxTime} />
								<Flex justify="center" align="center" flexWrap="wrap">
									{data.patrollingRouteById.zoneIds
										.map((id) => data.patrollingRouteById.zones.find((z) => z._id === id))
										.filter(Boolean)
										.map((zone, index) => (
											<Text key={zone?._id} textAlign="center" fontSize="sm" fontWeight="bold" color="grayscale.body">
												{zone?.label.name}
												{index < (data.patrollingRouteById.zones.length || 0) && ", "}
											</Text>
										))}
								</Flex>
								<Text textAlign="center" fontSize="xs" color="grayscale.label">
									Route
								</Text>
							</Flex>
						) : (
							<Center py="4">
								<Text fontSize="sm" fontWeight="semibold" color="error.500">
									Couldn&apos;t find details
								</Text>
							</Center>
						)}
					</Card>
				)}
			</Stack>
		</VStack>
	)
}
