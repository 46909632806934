import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateUserScopedSitesForm } from "../../forms"
import { ExtendedUserFragment } from "../../graphql"

export type UpdateUserScopedSitesProps = {
	user: ExtendedUserFragment
}

export const UpdateUserScopedSites: React.FC<UpdateUserScopedSitesProps> = ({ user }) => {
	return (
		<VStack w="full" maxW="lg" spacing={6}>
			<UpdateUserScopedSitesForm user={user} />
		</VStack>
	)
}
