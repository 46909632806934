import { Button, FormControl, FormErrorMessage, FormLabel, Icon, IconButton, Input, InputGroup, InputRightElement, useToast, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React, { FormEvent, useState } from "react"
import { Eye, EyeOff } from "react-feather"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { SESSION_TOKEN_KEY, SITE_TYPE } from "../constants"
import { LoginMutationVariables, SiteTypes, useLoginMutation } from "../graphql"

type LoginFormValues = Omit<LoginMutationVariables, "siteType">

const validationSchema = yup.object<Record<keyof LoginFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	username: yup.string().required().label("Username"),
	password: yup.string().required().min(6).label("Password"),
})

const initialValues: LoginFormValues = {
	username: "",
	password: "",
}

export const LoginForm: React.FC = () => {
	const [{ fetching }, login] = useLoginMutation()

	const toast = useToast()

	const onSubmit = async (values: LoginFormValues, helpers: FormikHelpers<LoginFormValues>) => {
		if (!SITE_TYPE || !Object.values(SiteTypes).includes(SITE_TYPE)) {
			return toast({
				title: "Login Failed",
				description: "Site type is not defined",
			})
		}
		const { data, error } = await login({ ...values, siteType: SITE_TYPE })

		if (error) {
			return toast({
				title: "Login Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})
		}

		if (data?.login.errors?.length) {
			return data.login.errors.forEach(({ field, error }) => {
				helpers.setFieldError(field, error)
			})
		}

		if (data?.login.sessionToken) {
			return localStorage.setItem(SESSION_TOKEN_KEY, data.login.sessionToken)
		}
	}

	const formik = useFormik<Omit<LoginFormValues, "siteType">>({ initialValues, validationSchema, onSubmit })

	const [showPassword, setShowPassword] = useState(false)

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.username && formik.errors.username)}>
					<FormLabel fontWeight="bold">Username</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter username" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("username")} />
					<FormErrorMessage>{formik.errors.username}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.password && formik.errors.password)}>
					<FormLabel fontWeight="bold">Password</FormLabel>
					<InputGroup>
						<Input
							variant="filled"
							bgColor="grayscale.input-background"
							type={showPassword ? "text" : "password"}
							placeholder="Enter password"
							_placeholder={{ color: "grayscale.placeholer" }}
							{...formik.getFieldProps("password")}
						/>
						<InputRightElement>
							<IconButton size="sm" aria-label="show-password-btn" icon={<Icon as={showPassword ? EyeOff : Eye} />} variant="ghost" onClick={() => setShowPassword((prev) => !prev)} />
						</InputRightElement>
					</InputGroup>
					<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Login
			</Button>
		</VStack>
	)
}
