import { VStack } from "@chakra-ui/react"
import React from "react"
import { AssignTagForm } from "../../forms"
import { ExtendedUserFragment } from "../../graphql"

export type AssignTagProps = {
	user: ExtendedUserFragment
}

export const AssignTag: React.FC<AssignTagProps> = ({ user }) => {
	return (
		<VStack w="full" spacing={6} maxW="sm">
			<AssignTagForm user={user} />
		</VStack>
	)
}
