import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { AllUserGroupsQueryVariables, useAllUserGroupsQuery } from "../../graphql"
import { UserGroupsList } from "./UserGroupsList"

export const AllUserGroups: React.FC = () => {
	const [pagination, setPagination] = useState<AllUserGroupsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useAllUserGroupsQuery({
		variables: { pagination },
	})

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.allUserGroups.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.allUserGroups ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading user groups</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.allUserGroups.userGroups && data.allUserGroups.userGroups.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.allUserGroups.userGroups.length || 0} user group
						{(data.allUserGroups.userGroups.length || 0) > 1 ? "s" : ""}
					</Text>

					<UserGroupsList userGroups={data.allUserGroups.userGroups} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more user groups</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any user groups.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
