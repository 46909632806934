import { VStack } from "@chakra-ui/react"
import React from "react"
import { PatrollingUsersStatsTable } from "./PatrollingUsersStatsTable"

export const StandardDashboardPatrollingTab: React.FC = () => {
	return (
		<VStack w="full" align="stretch">
			<PatrollingUsersStatsTable />
		</VStack>
	)
}
