export const colors = {
	primary: {
		50: "#dbd0fa",
		100: "#b8a2f6",
		200: "#a68bf3",
		300: "#9474f1",
		400: "#825cef",
		500: "#5F2EEA",
		600: "#4615d0",
		700: "#3e13b9",
		800: "#3610a2",
		900: "#270c73",
	},
	secondary: {
		50: "#c2f0fa",
		100: "#93e5f7",
		200: "#7bdff5",
		300: "#63d9f3",
		400: "#4bd3f2",
		500: "#1CC8EE",
		600: "#0fa7c8",
		700: "#0d93b0",
		800: "#0b7f99",
		900: "#085869",
	},
	error: {
		50: "#fbd2e3",
		100: "#f7a3c6",
		200: "#f58cb8",
		300: "#f374a9",
		400: "#f15d9b",
		500: "#ED2E7E",
		600: "#d61264",
		700: "#be1059",
		800: "#a70e4e",
		900: "#780a38",
	},
	success: {
		50: "#6dffd8",
		100: "#3affca",
		200: "#21ffc3",
		300: "#08ffbc",
		400: "#00edad",
		500: "#00BA88",
		600: "#008763",
		700: "#006e50",
		800: "#00543d",
		900: "#002118",
	},
	warning: {
		50: "#fef7e9",
		100: "#fbe4b9",
		200: "#fadba0",
		300: "#f8d288",
		400: "#f7c970",
		500: "#F4B740",
		600: "#f1a510",
		700: "#db950d",
		800: "#c3850b",
		900: "#936408",
	},
	grayscale: {
		"off-white": "#FCFCFC",
		background: "#F7F7FC",
		"input-background": "#EFF0F6",
		line: "#D9DBE9",
		placeholer: "#A0A3BD",
		label: "#6E7191",
		body: "#4E4B66",
		title: "#14142B",
	},
}
