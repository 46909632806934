import { Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { ExtendedWorkShiftFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type WorkShiftDetailsProps = {
	workShift: ExtendedWorkShiftFragment
}

export const WorkShiftDetails: React.FC<WorkShiftDetailsProps> = ({ workShift }) => {
	return (
		<Card title="Details">
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Timing" value={`${workShift.startAt} - ${workShift.endAt}`} />
				<Stat label="Created" value={format(new Date(workShift.createdAt), "MMM dd, yyyy HH:mm")} />
			</Flex>
		</Card>
	)
}
