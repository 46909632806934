import { VStack } from "@chakra-ui/react"
import React from "react"
import { useRootTileQuery } from "../../graphql"
import { PatrollingRouteTileContainer } from "./PatrollingRouteTileContainer"

export type PatrollingRoutesProps = {
	patrollingRouteId: string
}

export const PatrollingRoutes: React.FC<PatrollingRoutesProps> = ({ patrollingRouteId }) => {
	const [{ data }] = useRootTileQuery()

	return (
		<VStack w="full" h="100vh" overflowY="hidden">
			<PatrollingRouteTileContainer tileId={data?.rootTile?._id || ""} patrollingRouteId={patrollingRouteId} />
		</VStack>
	)
}
