import { VStack } from "@chakra-ui/react"
import React from "react"
import { ChangePasswordForm } from "../../forms"

export const ChangePassword: React.FC = () => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<ChangePasswordForm />
		</VStack>
	)
}
