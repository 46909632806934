import { Button, FormControl, FormErrorMessage, Heading, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import * as Yup from "yup"
import { SiteFragment, UpdateManpowerCountMutationVariables, useUpdateManpowerCountMutation } from "../graphql"

const validationSchema = Yup.object({
	manpowerCount: Yup.number().min(1).required().label("Manpower Count"),
})

export type UpdateManpowerCountFormProps = {
	site: SiteFragment | undefined
	onClose: () => void
}

export const UpdateManpowerCountForm: React.FC<UpdateManpowerCountFormProps> = ({ site, onClose }) => {
	const [{ fetching }, updateManpowerCount] = useUpdateManpowerCountMutation()

	const toast = useToast()

	const onSubmit = async ({ manpowerCount }: UpdateManpowerCountMutationVariables) => {
		const { error, data } = await updateManpowerCount({ manpowerCount })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (!data?.updateManpowerCount) {
			return toast({
				description: "Could not update manpower count of the site",
				status: "error",
			})
		}

		onClose()

		toast({ description: "Updated manpower count", status: "success" })
	}

	const formik = useFormik<UpdateManpowerCountMutationVariables>({
		initialValues: { manpowerCount: site?.manpowerCount || 0 },
		onSubmit,
		validationSchema,
	})

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Update Manpower Count</Heading>
				<FormControl isInvalid={Boolean(formik.errors.manpowerCount && formik.touched.manpowerCount)}>
					<NumberInput min={1} maxW="md" bg="white.500" rounded="xl" py="1" {...formik.getFieldProps("manpowerCount")} onChange={(valueStr) => formik.setFieldValue("manpowerCount", Number(valueStr))}>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
					<FormErrorMessage>{formik.errors.manpowerCount}</FormErrorMessage>
				</FormControl>

				<Button size="sm" type="submit" colorScheme="primary" isLoading={fetching}>
					Update
				</Button>
			</VStack>
		</VStack>
	)
}
