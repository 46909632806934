import { Heading, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { AlertTypes, useActiveRestrictedEntriesQuery, useAlertsUpdateSubscription, useRestrictedUserLocationRecordsUpdateSubscription, UserLocationRecordStatus } from "../../graphql"
import { LiveIndicator } from "../common"

export const ActiveRestrictedUserLocationRecords: React.FC = () => {
	const [restrictedEntries, setRestrictedEntries] = useState<string[]>([])

	const [{ data }] = useActiveRestrictedEntriesQuery()

	const [{ data: alertData }] = useAlertsUpdateSubscription()
	const [{ data: restrictedEntriesData }] = useRestrictedUserLocationRecordsUpdateSubscription({ variables: { recordIds: restrictedEntries.map((recordId) => recordId) }, pause: !restrictedEntries.length })

	useEffect(() => {
		if (data?.activeRestrictedEntries.recordIds.length) {
			data.activeRestrictedEntries.recordIds.forEach((recordId) => {
				if (!restrictedEntries.includes(recordId)) {
					restrictedEntries.push(recordId)
				}
			})
		}
	}, [data])

	useEffect(() => {
		if (alertData?.alertsUpdate.type === AlertTypes.RestrictedEntry && alertData.alertsUpdate.userLocationRecord && !restrictedEntries.find((recordId) => recordId === alertData.alertsUpdate.userLocationRecord?._id)) {
			setRestrictedEntries((prev) => [alertData.alertsUpdate.userLocationRecord!._id, ...(prev || [])])
		}
	}, [alertData])

	useEffect(() => {
		if (
			restrictedEntriesData?.restrictedUserLocationRecordsUpdate &&
			(!restrictedEntriesData.restrictedUserLocationRecordsUpdate.isRestricted || restrictedEntriesData.restrictedUserLocationRecordsUpdate.status === UserLocationRecordStatus.Final) &&
			restrictedEntries.find((recordId) => recordId === restrictedEntriesData.restrictedUserLocationRecordsUpdate._id)
		) {
			const _restrictedEntries = [...restrictedEntries]
			_restrictedEntries.splice(
				restrictedEntries.findIndex((recordId) => recordId === restrictedEntriesData.restrictedUserLocationRecordsUpdate._id),
				1
			)

			setRestrictedEntries(_restrictedEntries)
		}
	}, [restrictedEntriesData])

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">Restricted entries</Heading>

			<VStack align="stretch">
				<Heading color="red.400">{restrictedEntries.length || 0}</Heading>
				<LiveIndicator />
			</VStack>
		</VStack>
	)
}
