import React from "react"
import MarkManualUserAttendanceOutForm from "../../forms/MarkManualUserAttendanceOut.form"
import { Card } from "../common"

export type UserAttendanceMarkManualOutProps = {
	userId: string
}

export const UserAttendanceMarkManualOut: React.FC<UserAttendanceMarkManualOutProps> = ({ userId }) => {
	return (
		<Card title="Mark Attendance">
			<MarkManualUserAttendanceOutForm userId={userId} />
		</Card>
	)
}
