import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreatePermissionsGroupMutationVariables, useCreatePermissionsGroupMutation, useMeQuery, usePermissionTagsQuery, UserRoleTypes } from "../graphql"

type CreatePermissionsGroupFormValues = CreatePermissionsGroupMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreatePermissionsGroupFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	isPrivateToAdmin: yup.boolean().nullable().label("Admin"),
	tags: yup.array(yup.string()).required().min(1).label("Tags"),
})

const initialValues: CreatePermissionsGroupFormValues = {
	label: { name: "", description: "" },
	isPrivateToAdmin: false,
	tags: [],
}

export const CreatePermissionsGroupForm: React.FC = () => {
	const [{ data: meData }] = useMeQuery()

	const [{ fetching }, createPermissionsGroup] = useCreatePermissionsGroupMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreatePermissionsGroupFormValues) => {
		const { data, error } = await createPermissionsGroup({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createPermissionsGroup) {
			navigate(`/permissionsGroups/${data.createPermissionsGroup._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreatePermissionsGroupFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: tagsData, error: tagsError, fetching: tagsFetching }] = usePermissionTagsQuery()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.tags && formik.errors.tags)}>
					<FormLabel fontWeight="bold">Tags</FormLabel>

					{tagsFetching ? (
						<Text>Fetching tags</Text>
					) : tagsError ? (
						<VStack>
							<Text>Couldn&apos;t fetch tags</Text>
							<Text>{tagsError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !tagsData?.permissionTags.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch tags</Text>
						</VStack>
					) : (
						<Select multiple resize="vertical" variant="filled" bgColor="grayscale.input-background" {...formik.getFieldProps("tags")}>
							{tagsData.permissionTags.map((tag) => (
								<option key={tag} style={{ backgroundColor: "transparent" }} value={tag}>
									{tag}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.tags}</FormErrorMessage>
				</FormControl>

				{meData?.me?.type.roleType === UserRoleTypes.Admin && (
					<FormControl isInvalid={Boolean(formik.touched.isPrivateToAdmin && formik.errors.isPrivateToAdmin)}>
						<FormLabel fontWeight="bold">Private To Admin</FormLabel>

						<Checkbox isChecked={formik.values.isPrivateToAdmin || false} onChange={(e) => formik.setFieldValue("isPrivateToAdmin", e.target.checked)} colorScheme="primary">
							Yes
						</Checkbox>

						<FormErrorMessage>{formik.errors.isPrivateToAdmin}</FormErrorMessage>
					</FormControl>
				)}
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
