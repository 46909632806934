import { Center, chakra, Divider, Heading, HStack, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import { startCase } from "lodash"
import React from "react"
import { NavLink } from "react-router-dom"
import { useZonesQuery, ZoneTypes } from "../../graphql"

export const ZonesStatus: React.FC = () => {
	const [{ data, fetching, error }] = useZonesQuery()

	return (
		<VStack flex="1.5" align="stretch" spacing={4}>
			<Heading fontSize="sm">All zones</Heading>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.zones ? (
				<VStack w="full" align="stretch">
					<HStack w="full" justify="space-between">
						<VStack align="stretch">
							<Link as={NavLink} to="/zones?status=Active">
								<Heading color="green.400">
									{data.zones.filter((r) => r.isActive).length || 0}{" "}
									<chakra.span fontSize="sm" fontWeight="semibold" color="green.400">
										active
									</chakra.span>
								</Heading>
							</Link>
							<Text fontSize="sm" color="grayscale.label">
								{Object.values(ZoneTypes).map((type, i, _) => (
									<>
										<Link key={"Active" + type} as={NavLink} to={`/zones?status=Active&type=${type}`}>
											<chakra.span key={type} fontWeight="semibold">
												{data.zones.filter((r) => r.isActive && r.type === type).length || 0}{" "}
												<chakra.span key={type} fontWeight="normal">
													{startCase(type).toLowerCase()}
												</chakra.span>
											</chakra.span>
										</Link>
										{_.length - 1 !== i && <chakra.span fontWeight="semibold"> • </chakra.span>}
									</>
								))}
							</Text>
						</VStack>
						<Divider orientation="vertical" />
						<VStack align="stretch">
							<Link as={NavLink} to="/zones?status=Idle">
								<Heading color="red.400">
									{data.zones.filter((r) => !r.isActive).length || 0}{" "}
									<chakra.span fontSize="sm" fontWeight="semibold" color="red.400">
										Idle
									</chakra.span>
								</Heading>
							</Link>
							<Text fontSize="sm" color="grayscale.label">
								{Object.values(ZoneTypes).map((type, i, _) => (
									<>
										<Link key={"Idle" + type} as={NavLink} to={`/zones?status=Idle&type=${type}`}>
											<chakra.span key={type} fontWeight="semibold">
												{data.zones.filter((r) => !r.isActive && r.type === type).length || 0}{" "}
												<chakra.span key={type} fontWeight="normal">
													{startCase(type).toLowerCase()}
												</chakra.span>
											</chakra.span>
										</Link>
										{_.length - 1 !== i && <chakra.span fontWeight="semibold"> • </chakra.span>}
									</>
								))}
							</Text>
						</VStack>
					</HStack>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
