import { AspectRatio, Image, Modal, ModalBody, ModalCloseButton, ModalContent, useDisclosure, VStack } from "@chakra-ui/react"
import React, { Fragment, useState } from "react"
import { ExtendedUserAttendanceFragment } from "../../graphql"
export type UserInAttendanceImageProps = {
	userAttendance: ExtendedUserAttendanceFragment
}

export const UserInAttendanceImage: React.FC<UserInAttendanceImageProps> = ({ userAttendance }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const [isImageAvailable, setIsImageAvailable] = useState<boolean>(true)

	return (
		<Fragment>
			<Image
				borderRadius="full"
				boxSize="50px"
				style={{ objectFit: "cover" }}
				onClick={isImageAvailable ? onOpen : onClose}
				onError={() => setIsImageAvailable(false)}
				src={`data:image/png;base64,${userAttendance.inImage}` || ""}
				alt="User In Attendance Image"
				fallbackSrc="/no-image-available.png"
				cursor="pointer"
			/>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalContent>
					<ModalCloseButton />
					<ModalBody as={VStack} w="full">
						<AspectRatio w="full" maxW="xs" ratio={1 / 1}>
							<Image src={`data:image/png;base64,${userAttendance.inImage}` || ""} alt="User In Attendance Image" />
						</AspectRatio>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Fragment>
	)
}
