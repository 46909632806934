import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { useReadersAtZoneQuery, useReaderUpdateAtZoneSubscription } from "../../graphql"
import { Card } from "../common"
import { ReadersList } from "./ReadersList"

export type ReadersAtZoneProps = {
	zoneId: string
}

export const ReadersAtZone: React.FC<ReadersAtZoneProps> = ({ zoneId }) => {
	const [{ data, fetching, error }] = useReadersAtZoneQuery({ variables: { zoneId } })
	useReaderUpdateAtZoneSubscription({ variables: { zoneId } })

	return (
		<Card
			title="Readers"
			action={
				<Button as={NavLink} to={`/zones/${zoneId}/addReader`} colorScheme="primary" size="sm">
					Add
				</Button>
			}
		>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading readers</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.readersAtZone.length ? (
				<VStack w="full" align="stretch">
					<ReadersList readers={data.readersAtZone} zoneId={zoneId} />
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any reader.
					</Text>
				</Center>
			)}
		</Card>
	)
}
