import { VStack } from "@chakra-ui/react"
import React from "react"
import { CreateUserGroupForm } from "../../forms"

export const CreateUserGroup: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<CreateUserGroupForm />
		</VStack>
	)
}
