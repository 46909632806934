import { VStack } from "@chakra-ui/react"
import React from "react"
import { GenerateReadersStatusLogsReportForm } from "../../../forms"

export const GenerateReadersStatusLogsReport: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<GenerateReadersStatusLogsReportForm />
		</VStack>
	)
}
