import { Heading, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { LoginForm } from "../../forms"

export const Login: React.FC = () => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<VStack w="full" align="flex-start" spacing={0}>
				<Heading>Welcome Back!</Heading>
				<Text>Enter your credentials to login</Text>
			</VStack>
			<LoginForm />
		</VStack>
	)
}
