import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { UploadMobileAppForm } from "../../forms"
import { MeUserFragment, useMobileAppsQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { MobileAppTable } from "./MobileAppTable"

export type MobileAppModalProps = {
	isOpen: boolean
	onClose: () => void
	meData: MeUserFragment
}

export const MobileAppModal: React.FC<MobileAppModalProps> = ({ isOpen, onClose, meData }) => {
	const [{ data: mobileAppData, fetching: mobileAppFetching, error: mobileAppError }] = useMobileAppsQuery()

	const [uploadNewMobileApp, setUploadNewMobileApp] = useState<boolean>(false)

	const handleUpload = () => {
		setUploadNewMobileApp(true)
	}

	const cancelUpload = () => {
		setUploadNewMobileApp(false)
	}

	const uploadCompleted = () => {
		setUploadNewMobileApp(false)
	}

	const canUpdate = useMemo(() => {
		return checkForPermissions(meData, "mobile-apps/upload-mobile-app")[0]
	}, [meData])

	return (
		<Modal size="2xl" onClose={onClose} isOpen={isOpen} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{uploadNewMobileApp ? "Update" : "App Older Versions"}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{uploadNewMobileApp ? (
						<VStack w="full" maxW="2xl" align="stretch">
							<UploadMobileAppForm uploadCompleted={uploadCompleted} />
						</VStack>
					) : (
						<VStack w="full" maxW="2xl" align="stretch">
							{mobileAppFetching && !mobileAppData?.mobileApps ? (
								<Center w="full" py="4">
									<VStack w="full" color="grayscale.label">
										<Text fontSize="sm">Loading mobile apps</Text>
										<Spinner size="sm" />
									</VStack>
								</Center>
							) : mobileAppError ? (
								<Center py="4">
									<Text fontSize="sm" fontWeight="semibold" color="error.500">
										{mobileAppError.message.replace("[GraphQL] ", "")}
									</Text>
								</Center>
							) : mobileAppData?.mobileApps && mobileAppData?.mobileApps.length ? (
								<MobileAppTable mobileApps={mobileAppData.mobileApps} />
							) : (
								<Center py="4">
									<Text fontSize="sm" fontWeight="semibold" color="error.500">
										Couldn&apos;t find any mobile apps.
									</Text>
								</Center>
							)}
						</VStack>
					)}
				</ModalBody>
				<ModalFooter>
					{!uploadNewMobileApp && canUpdate && (
						<Button colorScheme="green" mr={3} onClick={handleUpload}>
							Update
						</Button>
					)}
					{uploadNewMobileApp && (
						<Button colorScheme="pink" mr={3} onClick={cancelUpload}>
							Cancel
						</Button>
					)}
					<Button variant="solid" onClick={onClose}>
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
