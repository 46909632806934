import { HStack, LinkBox, LinkOverlay, ListItem, Tag, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserFragment, UserFragment, UserStatus } from "../../graphql"
import { UserTag } from "../common"

export type UserListItemProps = {
	user: ExtendedUserFragment | UserFragment
}

export const UserListItem: React.FC<UserListItemProps> = ({ user }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: user.isBlacklisted ? "red.200" : "blackAlpha.100" }} bgColor={user.isBlacklisted ? "red.100" : ""} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/users/${user._id}`} />
			<HStack justifyContent="space-between" p="2">
				<UserTag user={user} />

				<VStack align="flex-end">
					{user.__typename === "ExtendedUser" && <Text fontSize="sm">{user.type.label.name}</Text>}
					<Tag variant="outline" colorScheme={user.status === UserStatus.Active ? "success" : "error"}>
						{user.isBlacklisted ? "Blacklisted" : user.status}
					</Tag>
				</VStack>
			</HStack>
		</ListItem>
	)
}
