import { VStack } from "@chakra-ui/react"
import React from "react"
import { ExtendedZoneGroupFragment } from "../../graphql"
import { Card } from "../common"
import { ZonesList } from "./ZonesList"

export type ZonesOfZoneGroupProps = {
	zoneGroup: ExtendedZoneGroupFragment
}

export const ZonesOfZoneGroup: React.FC<ZonesOfZoneGroupProps> = ({ zoneGroup }) => {
	return (
		<Card title="Zones">
			<VStack w="full" align="stretch">
				<ZonesList zones={zoneGroup.zones} />
			</VStack>
		</Card>
	)
}
