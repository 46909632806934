import { Center, chakra, Heading, LinkBox, LinkOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { useActiveRestrictedEntriesQuery, ZoneFragment } from "../../graphql"

export type ZoneWiseRestrictedEntriesItemProps = {
	zone: ZoneFragment
}

export const ZoneWiseRestrictedEntriesItem: React.FC<ZoneWiseRestrictedEntriesItemProps> = ({ zone }) => {
	const [{ data, fetching, error }] = useActiveRestrictedEntriesQuery()

	const zoneWiseData = useMemo(() => data?.activeRestrictedEntries.zoneWiseRecordIds.filter(({ zoneId }) => zoneId === zone._id).flatMap((z) => z.recordIds) || [], [data])

	return (
		<VStack
			maxW="3xs"
			as={LinkBox}
			align="flex-start"
			px="6"
			py="4"
			m="1"
			bgColor="grayscale.background"
			transition="background-color, border-color .1s ease-in"
			borderWidth="4px"
			borderStyle="solid"
			borderColor={zoneWiseData.length ? "red.500" : "green.100"}
			_hover={{ bgColor: "primary.50", borderColor: "primary.200" }}
			rounded="xl"
			cursor="pointer"
			justify="space-between"
		>
			<LinkOverlay as={NavLink} to={`/zones/${zone._id}`} />
			<Heading fontSize="sm" color="grayscale.title">
				{zone.label.name}
			</Heading>

			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : (
				<VStack align="stretch">
					<Heading fontSize="2xl" color={zoneWiseData.length ? "red.500" : "green.400"}>
						{zoneWiseData.length || 0}{" "}
						<chakra.span fontSize="sm" fontWeight="normal">
							users
						</chakra.span>
					</Heading>
				</VStack>
			)}
		</VStack>
	)
}
