import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { ReportsByKeywordQueryVariables, useReportsByKeywordQuery } from "../../../graphql"

import { ReportsList } from "./ReportsList"

export type ReportsByKeywordProps = {
	keyword: string
	type?: string
	status?: string
	startDate?: Date
	endDate?: Date
}

export const ReportsByKeyword: React.FC<ReportsByKeywordProps> = ({ keyword, type, status, startDate, endDate }) => {
	const [filter, setFilter] = useState({ type, status, keyword, startDate, endDate })

	const [pagination, setPagination] = useState<ReportsByKeywordQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useReportsByKeywordQuery({
		variables: { ...filter, pagination },
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ keyword, type, status, startDate, endDate })
		}, 500)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword, type, status, startDate, endDate])

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.reportsByKeyword.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading reports</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.reportsByKeyword.reports && data.reportsByKeyword.reports.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.reportsByKeyword.reports.length || 0} report
						{(data.reportsByKeyword.reports.length || 0) > 1 ? "s" : ""}
					</Text>

					<ReportsList reports={data.reportsByKeyword.reports} />

					{data.reportsByKeyword.hasNextPage && (
						<Button size="sm" variant="link" colorScheme="blackAlpha" color="black" alignSelf="center" onClick={nextPage}>
							Load more
						</Button>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any reports.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
