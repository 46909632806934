import { Flex, HStack, Link } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertSourceTypes, ExtendedAlertFlowFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type AlertFlowSourceProps = {
	alertFlow: ExtendedAlertFlowFragment
}

export const AlertFlowSource: React.FC<AlertFlowSourceProps> = ({ alertFlow }) => {
	if (!alertFlow.source) return <></>

	return (
		<Card title="Source">
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(alertFlow.source.type)} />
				{alertFlow.source.type === AlertSourceTypes.Zones && alertFlow.source.zones?.length && (
					<Stat
						label="Zones"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{alertFlow.source.zones.map((o, i) => (
									<Link key={o._id} as={NavLink} to={`/zones/${o._id}`}>
										{o.label.name}
										{i < (alertFlow.source?.zones?.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						}
					/>
				)}
				{alertFlow.source.type === AlertSourceTypes.ZoneGroups && alertFlow.source.zoneGroups?.length && (
					<Stat
						label="Zone Groups"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{alertFlow.source.zoneGroups.map((o, i) => (
									<Link key={o._id} as={NavLink} to={`/zones/groups/${o._id}`}>
										{o.label.name}
										{i < (alertFlow.source?.zoneGroups?.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						}
					/>
				)}
				{alertFlow.source.type === AlertSourceTypes.Users && alertFlow.source.users?.length && (
					<Stat
						label="Users"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{alertFlow.source.users.map((o, i) => (
									<Link key={o._id} as={NavLink} to={`/users/${o._id}`}>
										{o.name}
										{i < (alertFlow.source?.users?.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						}
					/>
				)}
				{alertFlow.source.type === AlertSourceTypes.UserGroups && alertFlow.source.userGroups?.length && (
					<Stat
						label="Zone Groups"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{alertFlow.source.userGroups.map((o, i) => (
									<Link key={o._id} as={NavLink} to={`/users/groups/${o._id}`}>
										{o.label.name}
										{i < (alertFlow.source?.userGroups?.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						}
					/>
				)}
			</Flex>
		</Card>
	)
}
