import { Button, ButtonGroup, Flex, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedZoneFragment, useMeQuery } from "../../graphql"
import { checkForSomePermissions } from "../../utils"
import { Card, Stat } from "../common"

export type ZoneDetailsProps = {
	zone: ExtendedZoneFragment
	disableActions?: boolean
}

export const ZoneDetails: React.FC<ZoneDetailsProps> = ({ zone, disableActions }) => {
	const [{ data: meData }] = useMeQuery()

	const canUpdate = useMemo(
		() => checkForSomePermissions(meData?.me, "zones/update-zone")[0],

		[meData]
	)

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/zones/${zone._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={zone.type} />
				<Stat label="Since" value={format(new Date(zone.createdAt), "MMM dd, yyyy HH:mm")} />
				<Stat label="Crowd Limit" value={zone.crowdLimit} />
				<Stat label="Status" value={zone.isActive ? <Text color="success.600">Active</Text> : <Text color="error.600">Idle</Text>} />
			</Flex>
		</Card>
	)
}
