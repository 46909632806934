import { Heading, HStack, Icon, ListItem, VStack } from "@chakra-ui/react"
import React from "react"
import { CheckCircle, Clock, TrendingDown, TrendingUp, XCircle } from "react-feather"
import { ExtendedUserRouteRecordFragment } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { UserRouteRecord } from "./UserRouteRecord"

export type UserRouteListItemProps = {
	userRouteRecord: ExtendedUserRouteRecordFragment
}

export const UserRouteListItem: React.FC<UserRouteListItemProps> = ({ userRouteRecord }) => {
	return (
		<ListItem>
			<VStack w="full" align="flex-start" bgColor="whiteAlpha.400" p="4" rounded="xl" spacing={4}>
				<HStack w="full" justify="space-between">
					{userRouteRecord.isPartiallyFinal ? (
						<HStack bgColor="warning.100" px="2" py="1" rounded="lg">
							<Heading fontSize="sm" color="warning.800" fontWeight="semibold">
								Route
							</Heading>
							<Icon fontSize="sm" color="warning.800" as={XCircle} />
						</HStack>
					) : !userRouteRecord.isRouteCorrect ? (
						<HStack bgColor="red.100" px="2" py="1" rounded="lg">
							<Heading fontSize="sm" color="red.700" fontWeight="semibold">
								Route
							</Heading>
							<Icon fontSize="sm" color="red.700" as={XCircle} />
						</HStack>
					) : (
						<HStack bgColor="green.100" px="2" py="1" rounded="lg">
							<Heading fontSize="sm" color="green.700" fontWeight="semibold">
								Route
							</Heading>
							<Icon fontSize="sm" color="green.700" as={CheckCircle} />
						</HStack>
					)}
					{userRouteRecord.isPartiallyFinal ? (
						<HStack bgColor="warning.100" px="2" py="1" rounded="lg">
							<Heading fontSize="sm" color="warning.800" fontWeight="semibold">
								Partial
							</Heading>
							<Icon fontSize="sm" color="warning.800" as={XCircle} />
						</HStack>
					) : !userRouteRecord.isTimeCorrect ? (
						<HStack bgColor={userRouteRecord.isTimeHigh ? "yellow.100" : userRouteRecord.isTimeLow ? "blue.100" : "red.100"} px="2" py="1" rounded="lg">
							<Heading fontSize="sm" color={userRouteRecord.isTimeHigh ? "yellow.700" : userRouteRecord.isTimeLow ? "blue.700" : "red.700"} fontWeight="semibold">
								Time
							</Heading>
							<Icon
								fontSize="sm"
								color={userRouteRecord.isTimeHigh ? "yellow.700" : userRouteRecord.isTimeLow ? "blue.700" : "red.700"}
								as={userRouteRecord.isTimeHigh ? TrendingUp : userRouteRecord.isTimeLow ? TrendingDown : XCircle}
							/>
						</HStack>
					) : (
						<HStack bgColor="green.100" px="2" py="1" rounded="lg">
							<Heading fontSize="sm" color="green.700" fontWeight="semibold">
								Time
							</Heading>
							<Icon fontSize="sm" color="green.700" as={CheckCircle} />
						</HStack>
					)}
					{userRouteRecord.timeTakenInMinutes && (
						<HStack bgColor={userRouteRecord.isPartiallyFinal ? "warning.100" : userRouteRecord.isTimeCorrect ? "green.100" : "red.100"} px="2" py="1" rounded="lg">
							<Heading fontSize="sm" color={userRouteRecord.isPartiallyFinal ? "warning.800" : userRouteRecord.isTimeCorrect ? "green.700" : "red.700"} fontWeight="semibold">
								{getDurationInWords(userRouteRecord.timeTakenInMinutes * 60)}
							</Heading>
							<Icon fontSize="sm" color={userRouteRecord.isPartiallyFinal ? "warning.800" : userRouteRecord.isTimeCorrect ? "green.700" : "red.700"} as={Clock} />
						</HStack>
					)}
				</HStack>

				<UserRouteRecord userRouteRecord={userRouteRecord} />
			</VStack>
		</ListItem>
	)
}
