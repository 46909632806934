/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AddReaderToZoneMutationVariables, ExtendedZoneFragment, useAddReaderToZoneMutation } from "../graphql"

type AddReaderToZoneFormValues = Pick<AddReaderToZoneMutationVariables, "readerDecimalId">

const validationSchema = yup.object<Record<keyof AddReaderToZoneFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	readerDecimalId: yup.string().required().label("Reader ID"),
})

export type AddReaderToZoneFormProps = {
	zone: ExtendedZoneFragment
}

export const AddReaderToZoneForm: React.FC<AddReaderToZoneFormProps> = ({ zone }) => {
	const initialValues: AddReaderToZoneFormValues = {
		readerDecimalId: "",
	}

	const [{ fetching }, addReader] = useAddReaderToZoneMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ readerDecimalId }: AddReaderToZoneFormValues) => {
		const { data, error } = await addReader({ zoneId: zone._id, readerDecimalId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.addReaderToZone) {
			navigate(`/zones/${zone._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<AddReaderToZoneFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.readerDecimalId && formik.errors.readerDecimalId)}>
					<FormLabel fontWeight="bold">Reader ID</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter reader ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("readerDecimalId")} />

					<FormErrorMessage>{formik.errors.readerDecimalId}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
