import { Divider, List } from "@chakra-ui/react"
import React from "react"

import { ExtendedUserAttendanceFragment } from "../../graphql"
import { UserAttendanceListItem } from "./UserAttendanceListItem"

export type UserAttendanceListProps = {
	userAttendances: ExtendedUserAttendanceFragment[]
}

export const UserAttendanceList: React.FC<UserAttendanceListProps> = ({ userAttendances }) => {
	return (
		<List spacing={2}>
			{userAttendances.map((userAttendance) => (
				<React.Fragment key={userAttendance._id}>
					<UserAttendanceListItem userAttendance={userAttendance} />
					<Divider />
				</React.Fragment>
			))}
		</List>
	)
}
