import React from "react"
import { Route, Routes } from "react-router-dom"
import { Scaffold } from "../components/common/Scaffold"

import loadable from "@loadable/component"
import { Permissions } from "../components"
import GenerateUserPatrollingRouteReportPage from "../pages/reports/generate/GenerateUserPatrollingRouteReport.page"
import MarkAttendanceInPage from "../pages/attendance/MarkAttendanceIn.page"
import MarkAttendanceOutPage from "../pages/attendance/MarkAttendanceOut.page"

const DashboardPage = loadable(() => import("../pages/dashboard/Dashboard.page"))

const UsersPage = loadable(() => import("../pages/users/Users.page"))
const CreateUserPage = loadable(() => import("../pages/users/CreateUser.page"))
const UpdateUserProfilePage = loadable(() => import("../pages/users/UpdateUserProfile.page"))
const UpdateUserPermissionsGroupsPage = loadable(() => import("../pages/users/UpdateUserPermissionsGroups.page"))
const UpdateUserScopedSitesPage = loadable(() => import("../pages/users/UpdateUserScopedSitesPage"))
const AssignUserRolePage = loadable(() => import("../pages/users/AssignUserRole.page"))
const RemoveCurrentUserRolePage = loadable(() => import("../pages/users/RemoveCurrentUserRole.page"))
const ActivateUserPage = loadable(() => import("../pages/users/ActivateUser.page"))
const DeactivateUserPage = loadable(() => import("../pages/users/DeactivateUser.page"))
const UserPageWrapper = loadable(() => import("../components/auth/UserPageWrapper"))
const ProfilePage = loadable(() => import("../pages/Profile.page"))
const BlacklistUserPage = loadable(() => import("../pages/users/BlacklistUser.page"))
const WhitelistUserPage = loadable(() => import("../pages/users/WhitelistUser.page"))
const ResetUserPasswordPage = loadable(() => import("../pages/users/ResetUserPassword.page"))

const UserGroupsPage = loadable(() => import("../pages/userGroups/UserGroups.page"))
const UserGroupPage = loadable(() => import("../pages/userGroups/UserGroup.page"))
const CreateUserGroupPage = loadable(() => import("../pages/userGroups/CreateUserGroup.page"))

const AssignTagPage = loadable(() => import("../pages/users/AssignTag.page"))
const FlushTagPage = loadable(() => import("../pages/users/FlushTag.page"))
const ActivateTagOfUserPage = loadable(() => import("../pages/users/ActivateTagOfUser.page"))
const DeactivateTagOfUserPage = loadable(() => import("../pages/users/DeactivateTagOfUser.page"))

const UserTypesPage = loadable(() => import("../pages/userTypes/UserTypes.page"))
const UserTypePage = loadable(() => import("../pages/userTypes/UserType.page"))
const CreateUserTypePage = loadable(() => import("../pages/userTypes/CreateUserType.page"))

const MapPage = loadable(() => import("../pages/Map.page"))

const ZonesPage = loadable(() => import("../pages/Zones.page"))
const ZonePage = loadable(() => import("../pages/Zone.page"))
const UpdateZonePage = loadable(() => import("../pages/zones/UpdateZone.page"))
const AddReaderToZonePage = loadable(() => import("../pages/zones/AddReaderToZone.page"))
const RemoveReaderFromZonePage = loadable(() => import("../pages/zones/RemoveReaderFromZone.page"))

const ZoneGroupsPage = loadable(() => import("../pages/ZoneGroups/ZoneGroups.page"))
const ZoneGroupPage = loadable(() => import("../pages/ZoneGroups/ZoneGroup.page"))
const CreateZoneGroupPage = loadable(() => import("../pages/ZoneGroups/CreateZoneGroup.page"))

const PermissionsGroupsPage = loadable(() => import("../pages/permissionsGroups/PermissionsGroups.page"))
const PermissionsGroupPage = loadable(() => import("../pages/permissionsGroups/PermissionsGroup.page"))
const CreatePermissionsGroupPage = loadable(() => import("../pages/permissionsGroups/CreatePermissionsGroup.page"))

const GenerateUserMovementReportPage = loadable(() => import("../pages/reports/generate/GenerateUserMovementReport.page"))
const GenerateReadersStatusLogsReportPage = loadable(() => import("../pages/reports/generate/GenerateReadersStatusLogsReport.page"))
const GenerateUserRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateUserRestrictedEntriesReport.page"))
const GenerateZoneRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateZoneRestrictedEntriesReport.page"))
const GenerateZoneGroupRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateZoneGroupRestrictedEntriesReport.page"))
const GenerateComprehensiveUserAttendanceReportPage = loadable(() => import("../pages/reports/generate/GenerateComprehensiveUserAttendanceReport.page"))
const GenerateSummarizedUserAttendanceReportPage = loadable(() => import("../pages/reports/generate/GenerateSummarizedUserAttendanceReport.page"))

const ReportPage = loadable(() => import("../pages/reports/past/Report"))
const ReportsPage = loadable(() => import("../pages/reports/past/Reports"))

const ChangePassword = loadable(() => import("../pages/ChangePassword.page"))
const VerifyEmail = loadable(() => import("../pages/VerifyEmail.page"))

const UserRoleGroupsPage = loadable(() => import("../pages/userRoleGroups/UserRoleGroups.page"))
const CreateUserRoleGroupPage = loadable(() => import("../pages/userRoleGroups/CreateUserRoleGroup.page"))
const UserRoleGroupPage = loadable(() => import("../pages/userRoleGroups/UserRoleGroup.page"))

const DepartmentsPage = loadable(() => import("../pages/departments/Departments.page"))
const DepartmentPage = loadable(() => import("../pages/departments/Department.page"))
const CreateDepartmentPage = loadable(() => import("../pages/departments/CreateDepartment.page"))

const WorkShiftsPage = loadable(() => import("../pages/workShifts/WorkShifts.page"))
const WorkShiftPage = loadable(() => import("../pages/workShifts/WorkShift.page"))
const CreateWorkShiftPage = loadable(() => import("../pages/workShifts/CreateWorkShift.page"))

const ReadersPage = loadable(() => import("../pages/readers/Readers.page"))
const ReaderPage = loadable(() => import("../pages/readers/Reader.page"))
const CreateReaderPage = loadable(() => import("../pages/readers/CreateReader.page"))

const TagsPage = loadable(() => import("../pages/tags/Tags.page"))
const TagPage = loadable(() => import("../pages/tags/Tag.page"))
const CreateTagPage = loadable(() => import("../pages/tags/CreateTag.page"))

const AlertTargetGroupsPage = loadable(() => import("../pages/alerts/targetGroups/AlertTargetGroups.page"))
const AlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/AlertTargetGroup.page"))
const CreateAlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/CreateAlertTargetGroup.page"))
const UpdateAlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/UpdateAlertTargetGroup.page"))

const AlertsPage = loadable(() => import("../pages/alerts/Alerts.page"))
const AlertPage = loadable(() => import("../pages/alerts/Alert.page"))

const AlertFlowsPage = loadable(() => import("../pages/alerts/flows/AlertFlows.page"))
const AlertFlowPage = loadable(() => import("../pages/alerts/flows/AlertFlow.page"))
const CreateAlertFlowPage = loadable(() => import("../pages/alerts/flows/CreateAlertFlow.page"))
const UpdateAlertFlowPage = loadable(() => import("../pages/alerts/flows/UpdateAlertFlow.page"))

const PatrollingRoutesPage = loadable(() => import("../pages/patrollingRoutes/PatrollingRoutes.page"))

const ServicesPage = loadable(() => import("../pages/services/Services.page"))
const ServicePage = loadable(() => import("../pages/services/Service.page"))
const CreateServicePage = loadable(() => import("../pages/services/CreateService.page"))

export const RootRouter: React.FC = () => {
	return (
		<Routes>
			<Route
				path="/map"
				element={
					<Permissions tags={["tiles/read-tile", "tiles/read-tile-embedded-zone"]}>
						<MapPage />
					</Permissions>
				}
			/>
			<Route
				path="/map/:tileId"
				element={
					<Permissions tags={["tiles/read-tile", "tiles/read-tile-embedded-zone"]}>
						<MapPage />
					</Permissions>
				}
			/>

			<Route
				path="/patrollingRoutes"
				element={
					<Permissions tags={["patrolling-routes/read-patrolling-route", "zones/read-zone"]}>
						<PatrollingRoutesPage />
					</Permissions>
				}
			/>

			<Route
				path="/patrollingRoutes/:patrollingRouteId"
				element={
					<Permissions tags={["patrolling-routes/read-patrolling-route", "zones/read-zone"]}>
						<PatrollingRoutesPage />
					</Permissions>
				}
			/>

			<Route path="/" element={<Scaffold />}>
				<Route path="/" element={<DashboardPage />} />
				<Route
					path="/users"
					element={
						<Permissions tags={["users/read-user"]}>
							<UsersPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/create"
					element={
						<Permissions tags={["users/create-user"]}>
							<CreateUserPage />
						</Permissions>
					}
				/>
				<Route path="/users/:userId" element={<UserPageWrapper />} />
				<Route
					path="/users/:userId/updateProfile"
					element={
						<Permissions tags={["users/update-user"]}>
							<UpdateUserProfilePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/deactivate"
					element={
						<Permissions tags={["users/deactivate-user"]}>
							<DeactivateUserPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/blacklist"
					element={
						<Permissions tags={["users/blacklist-user"]}>
							<BlacklistUserPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/whitelist"
					element={
						<Permissions tags={["users/whitelist-user"]}>
							<WhitelistUserPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/resetPassword"
					element={
						<Permissions tags={["users/reset-user-password"]}>
							<ResetUserPasswordPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/activate"
					element={
						<Permissions tags={["users/activate-user"]}>
							<ActivateUserPage />
						</Permissions>
					}
				/>
				<Route path="/users/:userId/deactivateTag" element={<DeactivateTagOfUserPage />} />
				<Route path="/users/:userId/activateTag" element={<ActivateTagOfUserPage />} />
				<Route path="/users/:userId/assignTag" element={<AssignTagPage />} />
				<Route path="/users/:userId/flushTag" element={<FlushTagPage />} />
				<Route
					path="/users/:userId/updatePermissionsGroups"
					element={
						<Permissions tags={["permissions/update-user-permissions-group"]}>
							<UpdateUserPermissionsGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/updateScopedSites"
					element={
						<Permissions tags={["scoped-sites/update-scoped-sites"]}>
							<UpdateUserScopedSitesPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/assignUserRole"
					element={
						<Permissions tags={["user-roles/assign"]} checkType="or">
							<AssignUserRolePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/removeUserRole"
					element={
						<Permissions tags={["user-roles/remove"]} checkType="or">
							<RemoveCurrentUserRolePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/types"
					element={
						<Permissions tags={["user-types/read-user-type"]}>
							<UserTypesPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/types/create"
					element={
						<Permissions tags={["user-types/create-user-type"]}>
							<CreateUserTypePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/types/:userTypeId"
					element={
						<Permissions tags={["user-types/read-user-type"]}>
							<UserTypePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/groups"
					element={
						<Permissions tags={["users/read-user-group"]}>
							<UserGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/groups/create"
					element={
						<Permissions tags={["users/create-user-group"]}>
							<CreateUserGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/groups/:userGroupId"
					element={
						<Permissions tags={["users/read-user-group"]}>
							<UserGroupPage />
						</Permissions>
					}
				/>
				<Route path="/profile" element={<ProfilePage />} />
				<Route
					path="/zones"
					element={
						<Permissions tags={["zones/read-zone"]}>
							<ZonesPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId"
					element={
						<Permissions tags={["zones/read-zone"]}>
							<ZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/update"
					element={
						<Permissions tags={["zones/update-zone"]}>
							<UpdateZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/addReader"
					element={
						<Permissions tags={["readers/add-reader-to-zone"]}>
							<AddReaderToZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/:readerId/remove"
					element={
						<Permissions tags={["readers/remove-reader-from-zone"]}>
							<RemoveReaderFromZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups"
					element={
						<Permissions tags={["zones/read-zone-group"]}>
							<ZoneGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups/create"
					element={
						<Permissions tags={["zones/create-zone-group"]}>
							<CreateZoneGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups/:zoneGroupId"
					element={
						<Permissions tags={["zones/read-zone-group"]}>
							<ZoneGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
							<PermissionsGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups/create"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group", "permissions/create-permissions-group"]}>
							<CreatePermissionsGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups/:permissionsGroupId"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
							<PermissionsGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups"
					element={
						<Permissions tags={["user-roles/read-group"]} checkType="or">
							<UserRoleGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups/create"
					element={
						<Permissions tags={["user-roles/create-group"]} checkType="or">
							<CreateUserRoleGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups/:userRoleGroupId"
					element={
						<Permissions tags={["user-roles/read-group"]} checkType="or">
							<UserRoleGroupPage />
						</Permissions>
					}
				/>
				<Route path="/reports/past" element={<ReportsPage />} />
				<Route path="/reports/past/:reportId" element={<ReportPage />} />
				<Route
					path="/reports/generate/userMovement"
					element={
						<Permissions tags={["reports/create-user-location-records-report"]}>
							<GenerateUserMovementReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/comprehensiveUserAttendance"
					element={
						<Permissions tags={["reports/create-comprehensive-user-attendance-report"]}>
							<GenerateComprehensiveUserAttendanceReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/summarizedUserAttendance"
					element={
						<Permissions tags={["reports/create-summarized-user-attendance-report"]}>
							<GenerateSummarizedUserAttendanceReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/userPatrollingRoute"
					element={
						<Permissions tags={["reports/create-comprehensive-user-attendance-report"]}>
							<GenerateUserPatrollingRouteReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/readersStatusLogs"
					element={
						<Permissions tags={["reports/create-reader-status-logs-report"]}>
							<GenerateReadersStatusLogsReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/restrictedEntries/users"
					element={
						<Permissions tags={["reports/create-user-restricted-entry-report"]}>
							<GenerateUserRestrictedEntriesReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/restrictedEntries/zones"
					element={
						<Permissions tags={["reports/create-zone-restricted-entry-report"]}>
							<GenerateZoneRestrictedEntriesReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/restrictedEntries/zoneGroups"
					element={
						<Permissions tags={["reports/create-zone-group-restricted-entry-report"]}>
							<GenerateZoneGroupRestrictedEntriesReportPage />
						</Permissions>
					}
				/>
				<Route path="/changePassword" element={<ChangePassword />} />
				<Route path="/verifyEmail" element={<VerifyEmail />} />
				<Route
					path="/departments"
					element={
						<Permissions tags={["departments/read-department"]}>
							<DepartmentsPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/create"
					element={
						<Permissions tags={["departments/create-department"]}>
							<CreateDepartmentPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/:departmentId"
					element={
						<Permissions tags={["departments/read-department"]}>
							<DepartmentPage />
						</Permissions>
					}
				/>
				<Route
					path="/workShifts"
					element={
						<Permissions tags={["work-shifts/read-work-shift"]}>
							<WorkShiftsPage />
						</Permissions>
					}
				/>
				<Route
					path="/workShifts/create"
					element={
						<Permissions tags={["work-shifts/create-work-shift"]}>
							<CreateWorkShiftPage />
						</Permissions>
					}
				/>
				<Route
					path="/workShifts/:workShiftId"
					element={
						<Permissions tags={["work-shifts/read-work-shift"]}>
							<WorkShiftPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers"
					element={
						<Permissions tags={["readers/read-reader"]}>
							<ReadersPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/:readerId"
					element={
						<Permissions tags={["readers/read-reader"]}>
							<ReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/create"
					element={
						<Permissions tags={["readers/create-reader"]}>
							<CreateReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/tags"
					element={
						<Permissions tags={["tags/read-tag"]}>
							<TagsPage />
						</Permissions>
					}
				/>
				<Route
					path="/tags/create"
					element={
						<Permissions tags={["tags/create-tag"]}>
							<CreateTagPage />
						</Permissions>
					}
				/>
				<Route
					path="/tags/:tagId"
					element={
						<Permissions tags={["tags/read-tag"]}>
							<TagPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts"
					element={
						<Permissions tags={["alerts/read-alert"]}>
							<AlertsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/:alertId"
					element={
						<Permissions tags={["alerts/read-alert"]}>
							<AlertPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups"
					element={
						<Permissions tags={["alerts/read-alert-target-group"]}>
							<AlertTargetGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/create"
					element={
						<Permissions tags={["alerts/create-alert-target-group"]}>
							<CreateAlertTargetGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/:alertTargetGroupId"
					element={
						<Permissions tags={["alerts/read-alert-target-group"]}>
							<AlertTargetGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/:alertTargetGroupId/update"
					element={
						<Permissions tags={["alerts/update-alert-target-group"]}>
							<UpdateAlertTargetGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows"
					element={
						<Permissions tags={["alerts/read-alert-flow"]}>
							<AlertFlowsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/create"
					element={
						<Permissions tags={["alerts/create-alert-flow"]}>
							<CreateAlertFlowPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/:alertFlowId"
					element={
						<Permissions tags={["alerts/read-alert-flow"]}>
							<AlertFlowPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/:alertFlowId/update"
					element={
						<Permissions tags={["alerts/update-alert-flow"]}>
							<UpdateAlertFlowPage />
						</Permissions>
					}
				/>
				<Route
					path="/services"
					element={
						<Permissions tags={["services/read-service"]}>
							<ServicesPage />
						</Permissions>
					}
				/>
				<Route
					path="/services/create"
					element={
						<Permissions tags={["services/create-service"]}>
							<CreateServicePage />
						</Permissions>
					}
				/>
				<Route
					path="/services/:serviceId"
					element={
						<Permissions tags={["services/read-service"]}>
							<ServicePage />
						</Permissions>
					}
				/>
				<Route
					path="/markattendancein"
					element={
						<Permissions tags={["services/read-service"]}>
							<MarkAttendanceInPage />
						</Permissions>
					}
				/>
				<Route
					path="/markattendanceout"
					element={
						<Permissions tags={["services/read-service"]}>
							<MarkAttendanceOutPage />
						</Permissions>
					}
				/>
			</Route>
		</Routes>
	)
}
