import { Flex, ListItem } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ZoneFragment } from "../../graphql"
import { Stat } from "../common"

export type ZoneListItemProps = {
	zone: ZoneFragment
}

export const ZoneListItem: React.FC<ZoneListItemProps> = ({ zone }) => {
	return (
		<ListItem>
			<Flex justify="center" align="center" flexWrap="wrap">
				<NavLink to={`/zones/${zone._id}`}>
					<Stat label="Name" value={zone.label.name} />
				</NavLink>
				<Stat label="Type" value={zone.type} />
			</Flex>
		</ListItem>
	)
}
