import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useUserLocationRecordsAtZoneQuery, useUserLocationRecordUpdateAtZoneSubscription } from "../../graphql"
import { Card } from "../common"
import { ZonedUserLocationsList } from "./ZonedUserLocationsList"

export type UserLocationRecordsAtZoneProps = {
	zoneId: string
}

export const UserLocationRecordsAtZone: React.FC<UserLocationRecordsAtZoneProps> = ({ zoneId }) => {
	const [{ data, fetching, error }] = useUserLocationRecordsAtZoneQuery({ variables: { zoneId } })

	useUserLocationRecordUpdateAtZoneSubscription({ variables: { zoneId } })

	return (
		<Card title="Current Users">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading current users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userLocationRecordsAtZone.length ? (
				<VStack w="full" align="stretch">
					<ZonedUserLocationsList userLocationRecords={data.userLocationRecordsAtZone} />
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						No current users found
					</Text>
				</Center>
			)}
		</Card>
	)
}
