import { Button, ButtonGroup, Center, HStack, LinkBox, LinkOverlay, List, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Stack, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { format, isValid } from "date-fns"
import React, { Fragment, useState } from "react"
import DatePicker from "react-date-picker"
import { NavLink } from "react-router-dom"
import { useTodaysShiftWiseAttendanceStatsQuery } from "../../graphql"
import { Card, Stat, UserTag } from "../common"
import { UserAttendanceList } from "./UserAttendanceList"

export const StandardDashboardAttendanceTab: React.FC = () => {
	const [date, setDate] = useState(new Date(format(new Date(), "MM/dd/yyyy")))
	const { onOpen, isOpen, onClose } = useDisclosure()
	const [{ data, fetching, error }] = useTodaysShiftWiseAttendanceStatsQuery({ variables: { date } })

	const [shiftType, setShiftType] = useState<"morning" | "evening">("morning")
	const [attendanceType, setAttendanceType] = useState<string>("Present")

	return (
		<VStack w="full" align="stretch" minH="100vh" spacing={4}>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text>Loading users attendances</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.todaysShiftWiseAttendanceStats ? (
				<Card
					title="Attendance"
					alwaysShowAction
					action={
						<HStack spacing={10} justifyContent="space-between">
							<ButtonGroup size="sm" isAttached>
								<Button colorScheme="purple" variant={shiftType === "morning" ? "solid" : "outline"} onClick={() => setShiftType("morning")} isActive={shiftType === "morning"}>
									Morning
								</Button>
								<Button colorScheme="purple" variant={shiftType === "evening" ? "solid" : "outline"} onClick={() => setShiftType("evening")} isActive={shiftType === "evening"}>
									Evening
								</Button>
							</ButtonGroup>
							<DatePicker onChange={(value) => isValid(value) && setDate(value as Date)} value={date} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />
							<Button colorScheme="purple" size="sm" variant="outline" onClick={onOpen}>
								Mark Manually
							</Button>
						</HStack>
					}
					w="full"
					maxW="4xl"
					align="stretch"
				>
					<Modal onClose={onClose} isOpen={isOpen} isCentered>
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>Mark Manually</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Text fontSize="md" fontWeight="semibold">
									Which type of attendance you want to mark?
								</Text>
							</ModalBody>
							<HStack spacing={4} justifyContent="center" p="4">
								<Button colorScheme="green" variant={"outline"} mr={3} as={NavLink} to="/markAttendancein">
									Bulk Mark In
								</Button>
								<Button colorScheme="green" variant={"outline"} mr={3} as={NavLink} to="/markAttendanceout">
									Bulk Mark Out
								</Button>
							</HStack>
						</ModalContent>
					</Modal>
					{shiftType === "morning" && (
						<Fragment>
							<HStack spacing={10} justifyContent="space-between">
								<Text>
									<Text>
										Showing {attendanceType === "Present" ? data.todaysShiftWiseAttendanceStats.morningPresent.length || 0 : data.todaysShiftWiseAttendanceStats.morningAbsent.length || 0}{" "}
										{attendanceType === "Present" ? " present" : " absent"} user{(data.todaysShiftWiseAttendanceStats.eveningPresent.length || 0) > 1 ? "s" : ""}
									</Text>
								</Text>
								<ButtonGroup size="sm" isAttached>
									<Button colorScheme="green" variant={attendanceType === "Present" ? "solid" : "outline"} onClick={() => setAttendanceType("Present")} isActive={attendanceType === "Present"}>
										Present
									</Button>
									<Button colorScheme="red" variant={attendanceType === "Absent" ? "solid" : "outline"} onClick={() => setAttendanceType("Absent")} isActive={attendanceType === "Absent"}>
										Absent
									</Button>
								</ButtonGroup>
							</HStack>
							<Modal onClose={onClose} isOpen={isOpen} isCentered>
								<ModalOverlay />
								<ModalContent>
									<ModalHeader>Mark Manually</ModalHeader>
									<ModalCloseButton />
									<ModalBody>
										<Text fontSize="md" fontWeight="semibold">
											Which type of attendance you want to mark?
										</Text>
									</ModalBody>
									<HStack spacing={4} justifyContent="center" p="4">
										<Button colorScheme="green" variant={"outline"} mr={3} as={NavLink} to="/markAttendancein">
											Bulk Mark In
										</Button>
										<Button colorScheme="green" variant={"outline"} mr={3} as={NavLink} to="/markAttendanceout">
											Bulk Mark Out
										</Button>
									</HStack>
								</ModalContent>
							</Modal>

							{attendanceType === "Present" && <UserAttendanceList userAttendances={data.todaysShiftWiseAttendanceStats.morningPresent} />}
							{attendanceType === "Absent" && (
								<Fragment>
									<List spacing={2}>
										{data.todaysShiftWiseAttendanceStats.morningAbsent.map((user) => (
											<HStack w="full" justifyContent="center" p="4" key={user._id} bgColor="red.100" rounded="xl">
												<VStack w="full" align="flex-start" spacing={0}>
													<HStack w="full" justify="space-between">
														<Stack as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
															<LinkOverlay as={NavLink} to={`/users/${user._id}`} />
															<HStack justifyContent="space-between" p="3">
																<UserTag user={user} />
															</HStack>
														</Stack>
														<HStack w="full" maxW="2xl" justify="space-around">
															<Stat label="Status" value="Absent" />
														</HStack>
													</HStack>
												</VStack>
											</HStack>
										))}
									</List>
								</Fragment>
							)}
						</Fragment>
					)}

					{shiftType === "evening" && (
						<Fragment>
							<Modal onClose={onClose} isOpen={isOpen} isCentered>
								<ModalOverlay />
								<ModalContent>
									<ModalHeader>Mark Manually</ModalHeader>
									<ModalCloseButton />
									<ModalBody>
										<Text fontSize="md" fontWeight="semibold">
											Which type of attendance you want to mark?
										</Text>
									</ModalBody>
									<HStack spacing={4} justifyContent="center" p="4">
										<Button colorScheme="green" variant={"outline"} mr={3} as={NavLink} to="/markAttendancein">
											Bulk Mark In
										</Button>
										<Button colorScheme="green" variant={"outline"} mr={3} as={NavLink} to="/markAttendanceout">
											Bulk Mark Out
										</Button>
									</HStack>
								</ModalContent>
							</Modal>
							<HStack spacing={10} justifyContent="space-between">
								<Text>
									Showing {attendanceType === "Present" ? data.todaysShiftWiseAttendanceStats.eveningPresent.length || 0 : data.todaysShiftWiseAttendanceStats.eveningAbsent.length || 0}
									{attendanceType === "Present" ? " present" : " absent"} user
									{(data.todaysShiftWiseAttendanceStats.eveningPresent.length || 0) > 1 ? "s" : ""}
								</Text>
								<ButtonGroup size="sm" isAttached>
									<Button colorScheme="green" variant={attendanceType === "Present" ? "solid" : "outline"} onClick={() => setAttendanceType("Present")} isActive={attendanceType === "Present"}>
										Present
									</Button>
									<Button colorScheme="red" variant={attendanceType === "Absent" ? "solid" : "outline"} onClick={() => setAttendanceType("Absent")} isActive={attendanceType === "Absent"}>
										Absent
									</Button>
								</ButtonGroup>
							</HStack>
							{attendanceType === "Present" && <UserAttendanceList userAttendances={data.todaysShiftWiseAttendanceStats.eveningPresent} />}
							{attendanceType === "Absent" && (
								<Fragment>
									<List spacing={2}>
										{data.todaysShiftWiseAttendanceStats.eveningAbsent.map((user) => (
											<HStack w="full" justifyContent="center" p="4" key={user._id} bgColor="red.100" rounded="xl">
												<VStack w="full" align="flex-start" spacing={0}>
													<HStack w="full" justify="space-between">
														<Stack as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
															<LinkOverlay as={NavLink} to={`/users/${user._id}`} />
															<HStack justifyContent="space-between" p="3">
																<UserTag user={user} />
															</HStack>
														</Stack>
														<HStack w="full" maxW="2xl" justify="space-around">
															<Stat label="Status" value="Absent" />
														</HStack>
													</HStack>
												</VStack>
											</HStack>
										))}
									</List>
								</Fragment>
							)}
						</Fragment>
					)}
				</Card>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any user attendance.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
