import { Divider, Flex, VStack } from "@chakra-ui/react"
import React from "react"
import { CurrentlyActiveUsers } from "./CurrentlyActiveUsers"
import { ReadersStatus } from "./ReadersStatus"
import { TodayPresentUsers } from "./TodayPresentUsers"
import { ZonesStatus } from "./ZonesStatus"
import { ZoneWiseUsers } from "./ZoneWiseUsers"

export const StandardDashboardStatsTab: React.FC = () => {
	return (
		<VStack w="full" align="stretch" spacing={4}>
			<Flex w="full" maxW="6xl" flexWrap="wrap">
				<TodayPresentUsers />
				<Divider orientation="vertical" mx="6" />
				<CurrentlyActiveUsers />
				<Divider orientation="vertical" mx="6" />
				<ZonesStatus />
				<Divider orientation="vertical" mx="6" />
				<ReadersStatus />
			</Flex>
			<Divider />
			<ZoneWiseUsers />
		</VStack>
	)
}
