import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { useVerifyEmailWithSecretCodeMutation } from "../graphql"

type VerifyEmailFormValues = { code: string }

const validationSchema = yup.object<Record<keyof VerifyEmailFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	code: yup.string().required().label("Code"),
})

const initialValues: VerifyEmailFormValues = {
	code: "",
}

export const VerifyEmailForm: React.FC = () => {
	const [{ fetching }, verify] = useVerifyEmailWithSecretCodeMutation()

	const toast = useToast()

	const onSubmit = async ({ code }: VerifyEmailFormValues) => {
		const { error, data } = await verify({ code })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.verifyEmailWithSecretCode) {
			return toast({
				description: "Verified the email successfully",
				status: "success",
			})
		}
	}

	const formik = useFormik<VerifyEmailFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.code && formik.errors.code)}>
					<FormLabel fontWeight="bold">Secret Code</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter secret code" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("code")} />

					<FormErrorMessage>{formik.errors.code}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Verify
			</Button>
		</VStack>
	)
}
