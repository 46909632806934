import { Badge, Center, HStack, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UserCurrentLocation, UserCurrentRoute, UserPastLocations, UserPastRoutes, UserPermissionsGroups, UserProfile, UserRoleDetails, UserScopedSites, UserTag, UserTagDetails } from "../../components"
import { Header } from "../../components/common/Header"
import { UserActiveAttendance } from "../../components/users/UserActiveAttendance"
import { useMeQuery, UserRoleTypes, useUserByIdQuery, useUserLocationRecordUpdateSubscription, useUserRouteRecordsUpdateSubscription } from "../../graphql"
import { checkForPermissions } from "../../utils"

const UserPage: React.FC = () => {
	const { userId = "" } = useParams<{ userId: string }>()

	const [{ data: meData }] = useMeQuery()

	const navigate = useNavigate()

	useEffect(() => {
		if (meData?.me?._id === userId) {
			navigate("/profile", { replace: true })
		}
	}, [meData, userId])

	const [{ data, fetching, error }] = useUserByIdQuery({ variables: { userId }, pause: !userId || meData?.me?._id === userId })

	useUserLocationRecordUpdateSubscription({ variables: { userId }, pause: !userId })
	useUserRouteRecordsUpdateSubscription({ variables: { userId }, pause: !userId })

	const canRead: [boolean, string[]] = useMemo(() => {
		if (!data?.userById || !meData?.me) return [true, []]

		if (data.userById.type.roleType === UserRoleTypes.Stationary || data.userById.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(meData.me, "users/read-user", "users/read-user")
		}

		return checkForPermissions(meData.me, "users/read-user")
	}, [data, meData])

	const canReadUserPermissionsGroups = useMemo(() => {
		if (!data?.userById || !meData?.me) return true

		if (data.userById.type.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(meData.me, "permissions/read-admin-user-permissions-group")[0]
		}

		return checkForPermissions(meData.me, "permissions/read-user-permissions-group")[0]
	}, [data, meData])

	const canReadUserScopedSites = useMemo(() => {
		if (!data?.userById || !meData?.me) return true

		return checkForPermissions(meData.me, "scoped-sites/read-scoped-sites")[0]
	}, [data, meData])

	if (!canRead[0]) return <Text>Not allowed, require {canRead[1]?.join(",")}</Text>

	return (
		<VStack w="full" align="stretch" p="8" spacing={4}>
			<Header title="User" />
			<VStack w="full" align="stretch">
				{fetching ? (
					<Center w="full" py="4">
						<VStack w="full" color="grayscale.label">
							<Text fontSize="sm">Loading user</Text>
							<Spinner size="sm" />
						</VStack>
					</Center>
				) : error ? (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							{error.message.replace("[GraphQL] ", "")}
						</Text>
					</Center>
				) : data?.userById ? (
					<VStack w="full" align="stretch" spacing={4}>
						<HStack align="flex-start">
							<UserTag user={data.userById} allowAvatarChange />
							{data.userById.isBlacklisted && (
								<Badge px="2" py="1" variant="subtle" rounded="full" color="red.600" colorScheme="red">
									Blacklisted
								</Badge>
							)}
						</HStack>

						<Stack w="full" direction={{ base: "column", xl: "row" }}>
							<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
								{data.userById.type.roleType !== UserRoleTypes.Admin && <UserTagDetails user={data.userById} />}
								<UserProfile user={data.userById} />
								{canReadUserScopedSites && data.userById.type.roleType === UserRoleTypes.Admin && <UserScopedSites user={data.userById} />}
								{data.userById.type.roleType !== UserRoleTypes.Admin && <UserRoleDetails user={data.userById} />}
								{canReadUserPermissionsGroups && <UserPermissionsGroups user={data.userById} />}
							</VStack>
							<VStack w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4}>
								<Tabs w="full" variant="soft-rounded" colorScheme="primary" isLazy lazyBehavior="unmount">
									<TabList>
										<Tab>Location</Tab>
										{data.userById.type.roleType === UserRoleTypes.Patrolling && <Tab>Route</Tab>}
										<Tab>Attendance</Tab>
									</TabList>
									<TabPanels w="full">
										<TabPanel as={VStack} w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4} px="0">
											<UserCurrentLocation userId={data.userById._id} />
											<UserPastLocations userId={data.userById._id} />
										</TabPanel>
										{data.userById.type.roleType === UserRoleTypes.Patrolling && (
											<TabPanel as={VStack} w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4} px="0">
												<UserCurrentRoute userId={data.userById._id} />
												<UserPastRoutes userId={data.userById._id} />
											</TabPanel>
										)}
										<TabPanel as={VStack} w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4} px="0">
											<UserActiveAttendance user={data.userById} />
										</TabPanel>
									</TabPanels>
								</Tabs>
							</VStack>
						</Stack>
					</VStack>
				) : (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							Couldn&apos;t find the user.
						</Text>
					</Center>
				)}
			</VStack>
		</VStack>
	)
}

export default UserPage
