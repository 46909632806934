export const getDurationInWords = (seconds: number) => {
	switch (true) {
		case seconds < 60: {
			return `${seconds.toFixed()}s`
		}

		case seconds < 60 * 60: {
			return `${Math.round(seconds / 60)}m`
		}

		default: {
			const [hours, minsPercent] = (seconds / (60 * 60)).toFixed(2).split(".").map(Number)

			if (!minsPercent) return `${hours}h`

			return `${hours}h ${Math.round((minsPercent / 100) * 60)}m`
		}
	}
}
