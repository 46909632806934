import { Button, FormControl, FormErrorMessage, FormLabel, Input, NumberInput, NumberInputField, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import startCase from "lodash/startCase"
import React, { FormEvent, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateUserRoleGroupMutationVariables, UserRoleTypes, useCreateUserRoleGroupMutation, useDepartmentsQuery, useZonesQuery, useZoneGroupsQuery, useWorkShiftsQuery, usePatrollingRoutesQuery } from "../graphql/generated"

type CreateUserRoleGroupFormValues = CreateUserRoleGroupMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateUserRoleGroupFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	type: yup.string().oneOf(Object.values(UserRoleTypes)).required().label("Type"),
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	allowedZoneGroupIds: yup.array(yup.string()).nullable().label("Allowed Zone Groupș"),
	allowedZoneIds: yup.array(yup.string()).nullable().label("Allowed Zones"),
	departmentId: yup.string().required().label("Deparment"),
	workShiftId: yup.string().nullable().label("Work Shift"),
	patrollingRouteId: yup.string().nullable().label("Patrolling Route"),
	rounds: yup.number().min(1).nullable().label("Rounds"),
})

export type CreateUserRoleGroupFormProps = {
	onSelectPatrollingRoute: (id: string) => void
}

const initialValues: CreateUserRoleGroupFormValues = {
	type: null as unknown as UserRoleTypes,
	label: { name: "", description: "" },
	allowedZoneGroupIds: [],
	allowedZoneIds: [],
	departmentId: "",
	workShiftId: "",
	patrollingRouteId: "",
	rounds: null,
}

export const CreateUserRoleGroupForm: React.FC<CreateUserRoleGroupFormProps> = ({ onSelectPatrollingRoute }) => {
	const [{ fetching }, createUserRoleGroup] = useCreateUserRoleGroupMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateUserRoleGroupFormValues) => {
		const { data, error } = await createUserRoleGroup({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createUserRoleGroup) {
			navigate(`/userRoleGroups/${data.createUserRoleGroup._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateUserRoleGroupFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: departmentsData, error: departmentsError, fetching: departmentsFetching }] = useDepartmentsQuery()

	const [{ data: zonesData, error: zonesError, fetching: zonesFetching }] = useZonesQuery()
	const [{ data: zoneGroupsData, error: zoneGroupsError, fetching: zoneGroupsFetching }] = useZoneGroupsQuery()
	const [{ data: workShiftsData, error: workShiftsError, fetching: workShiftsFetching }] = useWorkShiftsQuery()
	const [{ data: patrollingRoutesData, error: patrollingRoutesError, fetching: patrollingRoutesFetching }] = usePatrollingRoutesQuery()

	useEffect(() => {
		onSelectPatrollingRoute(formik.values.patrollingRouteId || "")
	}, [formik.values.patrollingRouteId])

	useEffect(() => {
		formik.values.allowedZoneIds = []
		formik.values.allowedZoneGroupIds = []
		formik.values.patrollingRouteId = ""
		formik.values.rounds = null
	}, [formik.values.type])

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)}>
					<FormLabel fontWeight="bold">Type</FormLabel>

					<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select type" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("type")}>
						{Object.values(UserRoleTypes)
							.filter((r) => r !== UserRoleTypes.Admin)
							.map((type) => (
								<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
									{startCase(type)}
								</option>
							))}
					</Select>

					<FormErrorMessage>{formik.errors.departmentId}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.departmentId && formik.errors.departmentId)}>
					<FormLabel fontWeight="bold">Department</FormLabel>

					{departmentsFetching ? (
						<Text>Fetching departments</Text>
					) : departmentsError ? (
						<VStack>
							<Text>Couldn&apos;t fetch departments</Text>
							<Text>{departmentsError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !departmentsData?.departments.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch departments</Text>
						</VStack>
					) : (
						<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select department" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("departmentId")}>
							{departmentsData.departments.map((department) => (
								<option key={department._id} style={{ backgroundColor: "transparent" }} value={department._id}>
									{department.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.departmentId}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.workShiftId && formik.errors.workShiftId)}>
					<FormLabel fontWeight="bold">Work Shift</FormLabel>

					{workShiftsFetching ? (
						<Text>Fetching work shifts</Text>
					) : workShiftsError ? (
						<VStack>
							<Text>Couldn&apos;t fetch work shifts</Text>
							<Text>{workShiftsError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !workShiftsData?.workShifts.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch work shifts</Text>
						</VStack>
					) : (
						<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select work shift" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("workShiftId")}>
							{workShiftsData.workShifts.map((workShift) => (
								<option key={workShift._id} style={{ backgroundColor: "transparent" }} value={workShift._id}>
									{workShift.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.workShiftId}</FormErrorMessage>
				</FormControl>

				{formik.values.type === UserRoleTypes.Patrolling && (
					<VStack w="full" align="stretch">
						<FormControl isInvalid={Boolean(formik.touched.patrollingRouteId && formik.errors.patrollingRouteId)}>
							<FormLabel fontWeight="bold">Patrolling Route</FormLabel>

							{patrollingRoutesFetching ? (
								<Text>Fetching patrolling routes</Text>
							) : patrollingRoutesError ? (
								<VStack>
									<Text>Couldn&apos;t fetch patrolling routes</Text>
									<Text>{patrollingRoutesError.message.replace("[GraphQL] ", "")}</Text>
								</VStack>
							) : !patrollingRoutesData?.patrollingRoutes.length ? (
								<VStack>
									<Text>Couldn&apos;t fetch patrolling routes</Text>
								</VStack>
							) : (
								<Select
									resize="vertical"
									variant="filled"
									bgColor="grayscale.input-background"
									placeholder="Select patrolling route"
									_placeholder={{ color: "grayscale.placeholer" }}
									{...formik.getFieldProps("patrollingRouteId")}
								>
									{patrollingRoutesData.patrollingRoutes.map((patrollingRoute) => (
										<option key={patrollingRoute._id} style={{ backgroundColor: "transparent" }} value={patrollingRoute._id}>
											{patrollingRoute.label.name}
										</option>
									))}
								</Select>
							)}

							<FormErrorMessage>{formik.errors.patrollingRouteId}</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={Boolean(formik.touched.rounds && formik.errors.rounds)}>
							<FormLabel fontWeight="bold">Rounds</FormLabel>
							<NumberInput>
								<NumberInputField
									min={0}
									pattern="(-)?[0-9]*(.[0-9]+)?"
									bgColor="whiteAlpha.400"
									backdropFilter="blur(24px)"
									border="none"
									_focus={{ border: "none" }}
									placeholder="Rounds"
									value={formik.values.rounds ? formik.values.rounds : undefined}
									onChange={(e) => formik.setFieldValue("rounds", Number(e.target.value))}
								/>
							</NumberInput>
							<FormErrorMessage>{formik.errors.rounds}</FormErrorMessage>
						</FormControl>
					</VStack>
				)}

				{formik.values.type === UserRoleTypes.Stationary && (
					<Tabs
						colorScheme="primary"
						variant="soft-rounded"
						isFitted
						size="sm"
						pt="2"
						onChange={(index) => {
							if (index === 1) {
								formik.setFieldValue("allowedZoneIds", [])
							} else {
								formik.setFieldValue("allowedZoneGroupIds", [])
							}
						}}
					>
						<TabList>
							<Tab>Zones</Tab>
							<Tab>Zone Groups</Tab>
						</TabList>
						<TabPanels>
							<TabPanel px="0">
								<FormControl isInvalid={Boolean(formik.touched.allowedZoneIds && formik.errors.allowedZoneIds)}>
									<FormLabel fontWeight="bold">Allowed Zones</FormLabel>

									{zonesFetching ? (
										<Text>Fetching zones</Text>
									) : zonesError ? (
										<VStack>
											<Text>Couldn&apos;t fetch zones</Text>
											<Text>{zonesError.message.replace("[GraphQL] ", "")}</Text>
										</VStack>
									) : !zonesData?.zones.length ? (
										<VStack>
											<Text>Couldn&apos;t fetch zones</Text>
										</VStack>
									) : (
										<Select multiple resize="vertical" variant="filled" bgColor="grayscale.input-background" {...formik.getFieldProps("allowedZoneIds")}>
											{zonesData.zones.map((zone) => (
												<option key={zone._id} style={{ backgroundColor: "transparent" }} value={zone._id}>
													{zone.label.name}
												</option>
											))}
										</Select>
									)}

									<FormErrorMessage>{formik.errors.allowedZoneIds}</FormErrorMessage>
								</FormControl>
							</TabPanel>
							<TabPanel px="0">
								<FormControl isInvalid={Boolean(formik.touched.allowedZoneGroupIds && formik.errors.allowedZoneGroupIds)}>
									<FormLabel fontWeight="bold">Allowed Zone Groups</FormLabel>

									{zoneGroupsFetching ? (
										<Text>Fetching zone groups</Text>
									) : zoneGroupsError ? (
										<VStack>
											<Text>Couldn&apos;t fetch zone groups</Text>
											<Text>{zoneGroupsError.message.replace("[GraphQL] ", "")}</Text>
										</VStack>
									) : !zoneGroupsData?.zoneGroups.length ? (
										<VStack>
											<Text>Couldn&apos;t fetch zone groups</Text>
										</VStack>
									) : (
										<Select multiple resize="vertical" variant="filled" bgColor="grayscale.input-background" {...formik.getFieldProps("allowedZoneGroupIds")}>
											{zoneGroupsData.zoneGroups.map((zoneGroup) => (
												<option key={zoneGroup._id} style={{ backgroundColor: "transparent" }} value={zoneGroup._id}>
													{zoneGroup.label.name}
												</option>
											))}
										</Select>
									)}

									<FormErrorMessage>{formik.errors.allowedZoneIds}</FormErrorMessage>
								</FormControl>
							</TabPanel>
						</TabPanels>
					</Tabs>
				)}
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
