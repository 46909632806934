import { AspectRatio, Center, HStack, Icon, Image, Text, useDisclosure } from "@chakra-ui/react"
import { format, isToday } from "date-fns"
import React from "react"
import { Search } from "react-feather"
import { RouteFindingFragment } from "../../graphql"
import { UserRouteFindingImageModal } from "./UserRouteFindingImageModal"

type RouteFindingProps = {
	finding: RouteFindingFragment
	bgColor: string
}

export const RouteFinding: React.FC<RouteFindingProps> = ({ finding, bgColor }) => {
	const { isOpen, onClose, onOpen } = useDisclosure()

	return (
		<>
			<HStack
				as="button"
				_hover={{
					textDecoration: "underline",
				}}
				px="4"
				justify="space-between"
				onClick={onOpen}
			>
				<AspectRatio pos="absolute" left="0" w="6" ratio={1}>
					<Center p="1" bgColor={bgColor} rounded="full">
						<Icon as={Search} fontSize="md" color="white" />
					</Center>
				</AspectRatio>
				<HStack w="full">
					<Image src={finding.image.url} w="8" h="8" rounded="lg" />
					<Text fontSize="xs" maxW="50%" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
						{finding.remarks}
					</Text>
				</HStack>
				<Text fontSize="xs" fontWeight="semibold">
					{format(new Date(finding.createdAt), isToday(new Date(finding.createdAt)) ? "HH:mm" : "MMM dd, HH:mm")}
				</Text>
			</HStack>
			<UserRouteFindingImageModal isOpen={isOpen} onClose={onClose} imageUrl={finding.image.url} remarks={finding.remarks} />
		</>
	)
}
