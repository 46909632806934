/* eslint-disable @typescript-eslint/indent */
import {
	Box,
	BoxProps,
	Button,
	ButtonGroup,
	Center,
	CloseButton,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	Fade,
	Heading,
	HStack,
	Icon,
	IconButton,
	Image,
	Img,
	SlideFade,
	Stack,
	Text,
	ToastId,
	Tooltip,
	useDisclosure,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { motion, MotionProps } from "framer-motion"
import React, { ComponentType, useEffect, useMemo, useRef, useState } from "react"
import { Menu, RefreshCw, X } from "react-feather"
import LineTo from "react-lineto"
import { NavLink } from "react-router-dom"
import { CreatePatrollingRouteForm } from "../../forms/CreatePatrollingRoute.form"
import { TileFragment, usePatrollingRoutesQuery, useTileEmbeddedZonesByTileIdQuery, useZonesQuery, ZoneFragment, ZoneTypes } from "../../graphql"
import useWindowDimensions from "../../hooks/useWindowDimensions"

export type PatrollingRouteTileProps = {
	tile: TileFragment
	patrollingRouteId: string
}

const MotionBox = motion<MotionProps & Omit<BoxProps, "transition">>(Box as unknown as ComponentType<MotionProps & Omit<BoxProps, "transition">>)

export const PatrollingRouteTile: React.FC<PatrollingRouteTileProps> = ({ tile, patrollingRouteId }) => {
	const [{ data, error }] = useTileEmbeddedZonesByTileIdQuery({ variables: { tileId: tile._id } })

	const [{ data: routesData }] = usePatrollingRoutesQuery()

	const [{ data: zonesData }] = useZonesQuery()

	const toast = useToast()

	useEffect(() => {
		let toastId: ToastId

		if (error) {
			toastId = toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
		}

		return () => {
			toast.close(toastId)
		}
	}, [error])

	const ref = useRef<HTMLImageElement>()

	const [original, setOriginal] = useState([0, 0])

	const { height } = useWindowDimensions()

	useEffect(() => {
		const bounds = ref.current?.getBoundingClientRect()

		if (bounds && ((bounds.height === 0 && bounds.width === 0) || (original[0] !== bounds.width && original[1] !== bounds.height))) {
			setOriginal([bounds.width, bounds.height])
		}
	}, [ref, original])

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [selectedZoneIds, setSelectedZoneIds] = useState<string[]>([])

	const selectedZones = useMemo(() => selectedZoneIds.map((id) => zonesData?.zones.find((z) => z._id === id)).filter(Boolean) as unknown as ZoneFragment[], [selectedZoneIds, zonesData])

	const selectedRoute = useMemo(() => routesData?.patrollingRoutes.find((r) => r._id === patrollingRouteId), [patrollingRouteId, routesData])

	useEffect(() => {
		if (selectedRoute) {
			setSelectedZoneIds(selectedRoute.zoneIds)
		} else {
			setSelectedZoneIds([])
		}
	}, [selectedRoute])

	const deselectZone = (index: number) => {
		setSelectedZoneIds((prev) => {
			const _ = [...prev]
			_.splice(index, 1)
			return _
		})
	}

	return (
		<HStack align="revert" spacing="10" ml="5">
			<Center maxW="full" h="full">
				<Box pos="relative" w="full" minH={height < original[1] ? "100vh" : undefined}>
					{tile.picture ? (
						<SlideFade in>
							<Image ref={ref as any} w="full" h="auto" src={tile.picture.original.url} alt={tile.label.name} style={{ objectFit: "contain" }} />
						</SlideFade>
					) : (
						<Box cursor="pointer" bgColor="whiteAlpha.400" _hover={{ bgColor: "whiteAlpha.600" }} transition="background-color .2s ease-in-out" backdropFilter="blur(24px)" />
					)}

					{data?.tileEmbeddedZonesByTileId.length &&
						data.tileEmbeddedZonesByTileId
							.filter((tileEmbeddedZone) => tileEmbeddedZone.zone.type === ZoneTypes.Outdoor)
							.map((tileEmbeddedZone, i) => (
								<Fade key={tileEmbeddedZone._id} in delay={i / 10}>
									<VStack
										key={tileEmbeddedZone._id}
										justify="flex-end"
										pos="absolute"
										left={`${tileEmbeddedZone.position.leftPercent}%`}
										top={`${tileEmbeddedZone.position.topPercent}%`}
										transform="translate(-50%, -100%)"
										spacing={0}
										onClick={() => (selectedRoute ? undefined : setSelectedZoneIds((prev) => [...prev, tileEmbeddedZone.zoneId]))}
									>
										<VStack key={tileEmbeddedZone._id} pos="relative" spacing={0}>
											<Tooltip
												label={
													<VStack
														minW="56"
														pos="absolute"
														bgColor={
															selectedZoneIds.includes(tileEmbeddedZone.zoneId)
																? indexOfAll(selectedZoneIds, tileEmbeddedZone.zoneId).includes(selectedZoneIds.length - 1)
																	? "orange"
																	: indexOfAll(selectedZoneIds, tileEmbeddedZone.zoneId).includes(0)
																	? "blue"
																	: "green"
																: "gray.700"
														}
														left="50%"
														top="-100%"
														transform="translate(-50%, 0)"
														px="4"
														py="1"
														rounded="md"
														zIndex={2}
														spacing={0}
													>
														<Text color="white" fontSize="sm" fontWeight="bold">
															{selectedZoneIds.includes(tileEmbeddedZone.zoneId)
																? `${indexOfAll(selectedZoneIds, tileEmbeddedZone.zoneId)
																		.map((i: number) => i + 1)
																		.join("/")}. `
																: ""}
															{tileEmbeddedZone.zone.label.name}
														</Text>
													</VStack>
												}
											>
												<Img src="/icons/outdoor-reader.svg" h="6" w="6" zIndex={1} cursor="pointer" className={`zones-${tileEmbeddedZone.zoneId}`} />
											</Tooltip>

											{selectedZoneIds.map((z, i) => (i ? <LineTo key={z} from={`zones-${selectedZoneIds[i - 1]}`} to={`zones-${z}`} borderWidth={5} borderColor="green" borderStyle="dashed" /> : <></>))}
										</VStack>
									</VStack>
								</Fade>
							))}
				</Box>
			</Center>
			<VStack minW="4xs" h="full" spacing={10}>
				<IconButton aria-label="menu-btn" rounded="full" colorScheme="purple" onClick={onOpen}>
					<Icon as={Menu} />
				</IconButton>
				{selectedZones.length && (
					<VStack w="full" maxH="xl" h="full" overflowY="auto" overflowX="hidden">
						{selectedZones.map((z, index) => (
							<MotionBox key={z._id} w="full" initial={{ opacity: 0, translateX: "24px" }} animate={{ opacity: 1, translateX: 0 }} transition={{ delay: Math.min(index / 10, 2) }}>
								<HStack w="full" h="full" align="stretch">
									<Box
										pos="relative"
										w="2"
										bgColor="purple.400"
										ml={2}
										mb={index === selectedZones.length - 1 ? 0 : -2}
										roundedTop={index === 0 ? "full" : undefined}
										roundedBottom={index === selectedZones.length - 1 ? "full" : undefined}
									>
										<Box pos="absolute" w="4" h="4" top="50%" left="50%" transform="translate(-50%, -50%)" bgColor="purple.500" rounded="full" />
									</Box>

									<HStack w="full" justifyContent="space-between" bgColor="whiteAlpha.600" px="0" py="2" rounded="md">
										<Text>{z.label.name}</Text>
										{selectedZones.length && !selectedRoute && <CloseButton onClick={() => deselectZone(index)} />}
									</HStack>
								</HStack>
							</MotionBox>
						))}
					</VStack>
				)}
			</VStack>

			<Drawer isOpen={isOpen} placement="right" onClose={onClose}>
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Patrolling Routes</DrawerHeader>

					<DrawerBody>
						<VStack w="full" maxW="sm" h="full" bgColor="whiteAlpha.400" backdropFilter="blur(24px)" rounded="xl" p="2" align="stretch" zIndex={3}>
							{selectedZones.length && selectedZoneIds.length && !selectedRoute ? (
								<VStack w="full" align="stretch">
									<HStack w="full" justifyContent="space-between">
										<Heading fontSize="lg">New Route</Heading>
										<ButtonGroup>
											<Button variant="ghost" onClick={() => setSelectedZoneIds([])}>
												Clear
											</Button>
											<IconButton
												aria-label="reverse-route-btn"
												variant="ghost"
												onClick={() =>
													setSelectedZoneIds((prev) => {
														const reversed = [...prev]
														reversed.reverse()
														return reversed
													})
												}
											>
												<Icon as={RefreshCw} strokeWidth="2px" cursor="pointer" />
											</IconButton>
										</ButtonGroup>
									</HStack>
									<CreatePatrollingRouteForm zones={selectedZones} onDeselectZone={deselectZone} onFinish={() => setSelectedZoneIds([])} />
								</VStack>
							) : (
								<VStack w="full" align="stretch">
									{selectedRoute ? (
										<VStack w="full" as={NavLink} to="/patrollingRoutes">
											<MotionBox key={selectedRoute._id} w="full" initial={{ opacity: 0, translateX: "24px" }} animate={{ opacity: 1, translateX: 0 }} cursor="pointer">
												<Box cursor="Router" bgColor="purple.500" color="white" _hover={{ bgColor: "purple.600" }} transition="background-color .2s ease-in-out" backdropFilter="blur(24px)" rounded="xl" p="4">
													<HStack>
														<VStack w="full" align="stretch">
															<Heading fontSize="md" textColor="white">
																{selectedRoute.label.name}
															</Heading>
															<VStack align="flex-start" spacing={0}>
																<Text fontSize="sm" whiteSpace="nowrap" textColor="white">
																	{selectedRoute.minTime}-{selectedRoute.maxTime} minutes
																</Text>
															</VStack>
														</VStack>
														<Tooltip label="Deselect" hasArrow>
															<Icon as={X} boxSize="20px" />
														</Tooltip>
													</HStack>
												</Box>
											</MotionBox>
											<VStack w="full" align="stretch" maxH="xs" overflowY="auto">
												{selectedRoute.zoneIds
													.map((id) => selectedRoute.zones.find((z) => z._id === id))
													.filter(Boolean)
													.map((z, index) => (
														<MotionBox key={index} w="full" initial={{ opacity: 0, translateX: "24px" }} animate={{ opacity: 1, translateX: 0 }} transition={{ delay: Math.min(index / 10, 2) }}>
															<HStack w="full" h="full" align="stretch">
																<Box
																	pos="relative"
																	w="2"
																	bgColor="purple.400"
																	ml={2}
																	mb={index === selectedRoute.zones.length - 1 ? 0 : -2}
																	roundedTop={index === 0 ? "full" : undefined}
																	roundedBottom={index === selectedRoute.zones.length - 1 ? "full" : undefined}
																>
																	<Box pos="absolute" w="4" h="4" top="50%" left="50%" transform="translate(-50%, -50%)" bgColor="purple.500" rounded="full" />
																</Box>
																<HStack w="full" justifyContent="space-between" bgColor="whiteAlpha.600" px="4" py="2" rounded="md">
																	<Text>{z?.label.name}</Text>
																</HStack>
															</HStack>
														</MotionBox>
													))}
											</VStack>
										</VStack>
									) : (
										<VStack w="full" align="stretch" spacing={2}>
											{routesData?.patrollingRoutes.map((route, index) => (
												<Stack key={route._id} as={NavLink} to={`/patrollingRoutes/${route._id}`}>
													<MotionBox key={route._id} w="full" initial={{ opacity: 0, translateX: "24px" }} animate={{ opacity: 1, translateX: 0 }} transition={{ delay: Math.min(index / 10, 2) }}>
														<Box
															bgColor="blackAlpha.200"
															color="black"
															_hover={{ bgColor: "blackAlpha.400" }}
															transition="background-color .2s ease-in-out"
															backdropFilter="blur(24px)"
															rounded="xl"
															p="4"
															cursor="pointer"
														>
															<HStack w="full" justifyContent="space-between">
																<VStack w="full" align="stretch">
																	<Heading fontSize="md">{route.label.name}</Heading>
																	<Text fontSize="sm">{route.zones.map((zone) => zone.label.name).join(" - ")}</Text>
																</VStack>
																<VStack align="flex-end" spacing={0}>
																	<Text fontSize="sm" whiteSpace="nowrap">
																		{route.minTime}-{route.maxTime} minutes
																	</Text>
																</VStack>
															</HStack>
														</Box>
													</MotionBox>
												</Stack>
											))}
										</VStack>
									)}
								</VStack>
							)}
						</VStack>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</HStack>
	)
}

const indexOfAll = (arr: any[], val: any) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), [])
