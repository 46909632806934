import { Button, Flex, HStack, Text } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserFragment, MeUserFragment, useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type UserScopedSitesProps = {
	user: ExtendedUserFragment | MeUserFragment
}

export const UserScopedSites: React.FC<UserScopedSitesProps> = ({ user }) => {
	const [{ data: meData }] = useMeQuery()

	const canUpdate = useMemo(() => {
		return checkForPermissions(meData?.me, "scoped-sites/update-scoped-sites")[0]
	}, [meData, user])

	return (
		<Card
			title="Scoped Sites"
			action={
				canUpdate &&
				user._id !== meData?.me?._id && (
					<Button as={NavLink} to={`/users/${user._id}/updateScopedSites`} colorScheme="primary" size="sm">
						Update
					</Button>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat
					label="Sites"
					value={
						<HStack flexWrap="wrap" justify="center" maxW="xs">
							{user.scopedSites.map((g, i) => (
								<Text key={g._id}>
									{g.label.name}
									{i < (user.scopedSiteIds?.length || 0) - 1 && ","}
								</Text>
							))}
						</HStack>
					}
				/>
			</Flex>
		</Card>
	)
}
