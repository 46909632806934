import { Divider, List } from "@chakra-ui/react"
import React from "react"
import { ZoneFragment } from "../../graphql"
import { ZoneListItem } from "./ZoneListItem"

export type ZonesListProps = {
	zones: ZoneFragment[]
}

export const ZonesList: React.FC<ZonesListProps> = ({ zones }) => {
	return (
		<List spacing={2}>
			{zones.map((zone) => (
				<>
					<ZoneListItem key={zone._id} zone={zone} />
					<Divider />
				</>
			))}
		</List>
	)
}
