import {
	Button,
	ButtonGroup,
	Divider,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Icon,
	IconButton,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Stack,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import startCase from "lodash/startCase"
import React, { FormEvent, useMemo } from "react"
import { Plus, X } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AlertTargetGroupSelector, UserGroupSelector, UserSelector, ZoneGroupSelector, ZoneSelector } from "../components"
import { AlertSourceTypes, AlertTypes, CreateAlertFlowMutationVariables, useCreateAlertFlowMutation, AlertSourceInput } from "../graphql"

type CreateAlertFlowFormValues = CreateAlertFlowMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateAlertFlowFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	type: yup.string().oneOf(Object.values(AlertTypes)).required().label("Type"),
	source: yup.object<Record<keyof CreateAlertFlowFormValues["source"], yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
		type: yup.string().oneOf(Object.values(AlertSourceTypes)).nullable().label("Source Type"),
		zoneIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.Zones, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Zones"),
		zoneGroupIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.ZoneGroups, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Zone Groups"),
		userIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.Users, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Users"),
		userGroupIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.UserGroups, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("User Groups"),
	}),
	levels: yup
		.array()
		.of(
			yup.object<Record<keyof CreateAlertFlowFormValues["levels"][0], yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
				targetGroupIds: yup.array(yup.string()).required().min(1).label("Target Groups"),
				timeout: yup.number().min(0).required().label("Timeout"),
			})
		)
		.label("Target Level"),
})

const initialValues: CreateAlertFlowFormValues = {
	label: { name: "", description: "" },
	type: AlertTypes.InactiveReader,
	source: { type: undefined as any, zoneIds: [], zoneGroupIds: [], userIds: [], userGroupIds: [] },
	levels: [{ targetGroupIds: [], timeout: 0 }],
}

export const CreateAlertFlowForm: React.FC = () => {
	const [{ fetching }, createAlertFlow] = useCreateAlertFlowMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateAlertFlowFormValues) => {
		const { data, error } = await createAlertFlow({ input: { ...values, source: [AlertTypes.InactiveService, AlertTypes.TagBatteryLow, AlertTypes.AllInactiveReaders].includes(values.type) ? undefined : values.source } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createAlertFlow) {
			navigate(`/alerts/flows/${data.createAlertFlow._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateAlertFlowFormValues>({ initialValues, validationSchema, onSubmit })

	const sourceTypes = useMemo(() => {
		if ([AlertTypes.UserRoleExpiry, AlertTypes.IncorrectRoute, AlertTypes.IncorrectRouteTime, AlertTypes.SOS].includes(formik.values.type)) {
			if (formik.values.source?.type !== AlertSourceTypes.Users && formik.values.source?.type !== AlertSourceTypes.UserGroups) {
				formik.setFieldValue("source.type", AlertSourceTypes.Users)
			}

			return [AlertSourceTypes.Users, AlertSourceTypes.UserGroups]
		} else if ([AlertTypes.InactiveReader, AlertTypes.RestrictedEntry, AlertTypes.Crowd, AlertTypes.IdleZone].includes(formik.values.type)) {
			if (formik.values.source?.type !== AlertSourceTypes.Zones && formik.values.source?.type !== AlertSourceTypes.ZoneGroups) {
				formik.setFieldValue("source.type", AlertSourceTypes.Zones)
			}

			return [AlertSourceTypes.Zones, AlertSourceTypes.ZoneGroups]
		} else if ([AlertTypes.InactiveService, AlertTypes.TagBatteryLow, AlertTypes.AllInactiveReaders, AlertTypes.AppUpdate].includes(formik.values.type)) {
			formik.setFieldValue("source", undefined, false)
		}

		return []
	}, [formik.values.type])

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }}>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
						<FormLabel fontWeight="bold">Name</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

						<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
						<FormLabel fontWeight="bold">Description</FormLabel>

						<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

						<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)} isRequired>
						<FormLabel fontWeight="bold">Type</FormLabel>

						<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("type")}>
							{Object.values(AlertTypes).map((type) => (
								<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
									{startCase(type)}
								</option>
							))}
						</Select>

						<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
					</FormControl>

					{formik.values.type !== AlertTypes.TagBatteryLow && formik.values.type !== AlertTypes.InactiveService && formik.values.type !== AlertTypes.AllInactiveReaders && formik.values.type !== AlertTypes.AppUpdate && (
						<FormControl isInvalid={Boolean((formik.touched.source as unknown as AlertSourceInput)?.type && (formik.errors.source as AlertSourceInput)?.type)} isRequired>
							<FormLabel fontWeight="bold">Source Type</FormLabel>

							<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Source Type" {...formik.getFieldProps("source.type")}>
								{Object.values(sourceTypes).map((type) => (
									<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
										{startCase(type)}
									</option>
								))}
							</Select>

							<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
						</FormControl>
					)}

					{formik.values.source?.type === AlertSourceTypes.Zones ? (
						<FormControl isInvalid={Boolean((formik.touched.source as unknown as AlertSourceInput)?.zoneIds && (formik.errors.source as AlertSourceInput)?.zoneIds)}>
							<FormLabel fontWeight="bold">Source Zones</FormLabel>

							<ZoneSelector value={formik.values.source?.zoneIds || []} onUpdate={(zoneIds) => formik.setFieldValue("source.zoneIds", zoneIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.zoneIds}</FormErrorMessage>
						</FormControl>
					) : formik.values.source?.type === AlertSourceTypes.ZoneGroups ? (
						<FormControl isInvalid={Boolean((formik.touched.source as unknown as AlertSourceInput)?.zoneGroupIds && (formik.errors.source as AlertSourceInput)?.zoneGroupIds)}>
							<FormLabel fontWeight="bold">Source Zone Groups</FormLabel>

							<ZoneGroupSelector value={formik.values.source?.zoneGroupIds || []} onUpdate={(zoneGroupIds) => formik.setFieldValue("source.zoneGroupIds", zoneGroupIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.zoneGroupIds}</FormErrorMessage>
						</FormControl>
					) : formik.values.source?.type === AlertSourceTypes.Users ? (
						<FormControl isInvalid={Boolean((formik.touched.source as unknown as AlertSourceInput)?.userIds && (formik.errors.source as AlertSourceInput)?.userIds)}>
							<FormLabel fontWeight="bold">Source Users</FormLabel>

							<UserSelector value={formik.values.source?.userIds || []} onUpdate={(userIds) => formik.setFieldValue("source.userIds", userIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userIds}</FormErrorMessage>
						</FormControl>
					) : (
						formik.values.source?.type === AlertSourceTypes.UserGroups && (
							<FormControl isInvalid={Boolean((formik.touched.source as unknown as AlertSourceInput)?.userGroupIds && (formik.errors.source as AlertSourceInput)?.userGroupIds)}>
								<FormLabel fontWeight="bold">Source User Groups</FormLabel>

								<UserGroupSelector value={formik.values.source?.userGroupIds || []} onUpdate={(userGroupIds) => formik.setFieldValue("source.userGroupIds", userGroupIds)} />

								<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userGroupIds}</FormErrorMessage>
							</FormControl>
						)
					)}
				</VStack>
				<VStack w="full" align="stretch" borderStyle="solid">
					<VStack w="full" align="stretch" spacing={4}>
						<HStack>
							<Heading fontSize="md">{formik.values.levels.length} Target Levels</Heading>
							<ButtonGroup>
								<IconButton
									aria-label="add-level-btn"
									size="xs"
									colorScheme="success"
									onClick={() => {
										const levels = [...(formik.values.levels || [])]

										levels.push({ targetGroupIds: [], timeout: 0 })

										formik.setFieldValue("levels", levels)
									}}
								>
									<Icon as={Plus} />
								</IconButton>
							</ButtonGroup>
						</HStack>
						{formik.values.levels.map((_, index) => (
							<VStack key={index} w="full" align="stretch" borderWidth="1px" borderColor="gray.200" p="4" rounded="xl">
								<HStack>
									<Heading fontSize="sm" color="grayscale.label">
										Level {index + 1}
									</Heading>

									{index !== 0 && (
										<IconButton
											aria-label="remove-level-btn"
											size="xs"
											colorScheme="error"
											onClick={() => {
												const levels = [...(formik.values.levels || [])]

												levels.splice(index, 1)

												formik.setFieldValue("levels", levels)
											}}
										>
											<Icon as={X} />
										</IconButton>
									)}
								</HStack>

								<Divider />

								<FormControl isInvalid={Boolean(formik.touched.levels?.[index]?.targetGroupIds && (formik.errors.levels?.[index] as any)?.targetGroupIds)}>
									<FormLabel fontWeight="bold">Target Groups</FormLabel>

									<AlertTargetGroupSelector value={formik.values.levels?.[index]?.targetGroupIds || []} onUpdate={(targetGroupIds) => formik.setFieldValue(`levels[${index}].targetGroupIds`, targetGroupIds)} />

									<FormErrorMessage>{(formik.errors.levels?.[index] as any)?.targetGroupIds}</FormErrorMessage>
								</FormControl>

								{formik.values.levels.length - 1 !== index && (
									<FormControl isInvalid={Boolean(formik.touched.levels?.[index]?.timeout && (formik.errors.levels?.[index] as any)?.timeout)}>
										<FormLabel fontWeight="bold">Timeout</FormLabel>

										<NumberInput min={0} {...formik.getFieldProps(`levels[${index}].timeout`)} onChange={(_, valueAsNumber) => formik.setFieldValue(`levels[${index}].timeout`, valueAsNumber)}>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>

										<FormErrorMessage>{(formik.errors.levels?.[index] as any)?.timeout}</FormErrorMessage>
									</FormControl>
								)}
							</VStack>
						))}
					</VStack>

					<Button type="submit" colorScheme="primary" isLoading={fetching}>
						Create
					</Button>
				</VStack>
			</Stack>
		</VStack>
	)
}
