import React from "react"
import MarkManualUserAttendanceInForm from "../../forms/MarkManualUserAttendanceIn.form"
import { Card } from "../common"

export type UserAttendanceMarkManualInProps = {
	userId: string
}

export const UserAttendanceMarkManualIn: React.FC<UserAttendanceMarkManualInProps> = ({ userId }) => {
	return (
		<Card title="Mark Attendance">
			<MarkManualUserAttendanceInForm userId={userId} />
		</Card>
	)
}
