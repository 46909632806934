import { Select, VStack } from "@chakra-ui/react"
import React from "react"
import { ReaderTypes } from "../../graphql"

export type ReaderTypeSelectorProps = {
	onUpdate: (type: ReaderTypes) => void
	value?: string
}

export const ReaderTypeSelector: React.FC<ReaderTypeSelectorProps> = ({ onUpdate, value }) => {
	return (
		<VStack>
			<Select flex="1" maxW="24" variant="filled" bgColor="grayscale.input-background" placeholder="Type" value={value} onChange={(e) => onUpdate(e.target.value as ReaderTypes)}>
				{Object.values(ReaderTypes).map((type) => (
					<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
						{type}
					</option>
				))}
			</Select>
		</VStack>
	)
}
