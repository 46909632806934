/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Stack, Text, useToast, VStack } from "@chakra-ui/react"
import { isValid } from "date-fns"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { UserSelector } from "../components"
import { useMarkUserAttendanceBulkOutMutation } from "../graphql"
import "react-calendar/dist/Calendar.css"
import "react-clock/dist/Clock.css"
import DateTimePicker from "react-datetime-picker"
import "react-datetime-picker/dist/DateTimePicker.css"

type MarkManualUserAttendanceBulkOutFormValues = {
	userIds: string[]
	outTime: Date
	outRemarks: string
}
const validationSchema = yup.object<Record<keyof MarkManualUserAttendanceBulkOutFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	userIds: yup.array().of(yup.string()).min(1).required().label("Users"),
	outTime: yup.date().required().label("Out Time"),
	outRemarks: yup.string().required().label("Out Remarks"),
})

export const MarkManualUserAttendanceBulkOutForm: React.FC = () => {
	const toast = useToast()

	const [{ fetching }, markUserAttendanceBulkIn] = useMarkUserAttendanceBulkOutMutation()

	const [isOptionSelected, setIsOptionSelected] = React.useState(false)
	const [isOtherSelected, setIsOtherSelected] = React.useState(false)

	const onSubmit = async (values: MarkManualUserAttendanceBulkOutFormValues) => {
		const { data, error } = await markUserAttendanceBulkIn({
			input: {
				outRemarks: values.outRemarks,
				outTime: new Date(values.outTime),
				userIds: values.userIds,
			},
		})

		if (error) {
			toast({
				title: "Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
				duration: 5000,
				isClosable: true,
			})
			return
		}

		if (data?.markUserAttendanceBulkOut) {
			//reset from
			formik.resetForm()
			toast({
				title: `${data?.markUserAttendanceBulkOut.length} users attendances marked out`,
				description: "Bulk attendance marked out successfully",
				status: "success",
				duration: 5000,
				isClosable: true,
			})
		}
	}

	const formik = useFormik<MarkManualUserAttendanceBulkOutFormValues>({
		initialValues: {
			userIds: [],
			outTime: new Date(),
			outRemarks: "",
		},
		validationSchema: validationSchema,
		onSubmit,
	})

	const options = ["No electricity", "Internet issue", "Card Punch issue", "Machine Not wroking"]

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }}>
				<VStack w="full" align="stretch">
					<VStack w="full" maxW="lg" align="stretch" spacing={4}>
						<FormControl id="outTime">
							<FormLabel>Out Time</FormLabel>
							<DateTimePicker onChange={(value) => isValid(value) && formik.setFieldValue("outTime", value)} value={formik.values.outTime} format="dd/MM/yyyy hh:mm a" maxDate={new Date()} clearIcon={null} />
						</FormControl>

						<FormControl id="outRemarks" isInvalid={!!formik.errors.outRemarks && formik.touched.outRemarks}>
							<FormLabel>Out Remarks</FormLabel>
							<HStack justifyContent={"flex-start"} gap={5} wrap="wrap">
								{options.map((option, index) => (
									<Button
										variant={isOptionSelected ? (formik.values.outRemarks === option ? "solid" : "outline") : "outline"}
										colorScheme="primary"
										color={isOptionSelected ? (formik.values.outRemarks === option ? "white" : "primary.500") : "primary.500"}
										size="sm"
										p={2}
										key={index}
										onClick={() => {
											formik.setFieldValue("outRemarks", option)
											setIsOptionSelected(true)
										}}
									>
										{option}
									</Button>
								))}
								<Button
									variant={isOtherSelected ? "solid" : "outline"}
									colorScheme="primary"
									color={isOtherSelected ? "white" : "primary.500"}
									size="sm"
									p={2}
									onClick={() => {
										formik.setFieldValue("outRemarks", "")
										setIsOtherSelected(!isOtherSelected)

										formik.setFieldValue("outRemarks", "")
									}}
								>
									Other
								</Button>
							</HStack>
							<FormErrorMessage>{formik.errors.outRemarks}</FormErrorMessage>
						</FormControl>
						{isOtherSelected && (
							<FormControl id="outRemarks" isInvalid={!!formik.errors.outRemarks && formik.touched.outRemarks}>
								<FormLabel>Out Remarks</FormLabel>
								<Input {...formik.getFieldProps("outRemarks")} />
								<FormErrorMessage>{formik.errors.outRemarks}</FormErrorMessage>
							</FormControl>
						)}
					</VStack>
				</VStack>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.userIds && formik.errors.userIds)}>
						<FormLabel fontWeight="bold">Users</FormLabel>

						<UserSelector value={formik.values.userIds} onUpdate={(userIds) => formik.setFieldValue("userIds", userIds)} />
						<Text fontSize="sm" color="error.400">
							{formik.errors.userIds}
						</Text>
					</FormControl>
					<Button type="submit" colorScheme="primary" isLoading={fetching}>
						Mark Out
					</Button>
				</VStack>
			</Stack>
		</VStack>
	)
}
