import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { Fragment } from "react"

export type UserPatrollingRouteReportProps = {
	report: any
}

export const UserPatrollingRouteReport: React.FC<UserPatrollingRouteReportProps> = ({ report }) => {
	return (
		<Box w="full" overflow="auto">
			<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
				<Thead>
					<Tr>
						<Th textTransform="capitalize">Users</Th>
						{report.labels.map((label: any) => (
							<Th key={label} textTransform="capitalize" w="12">
								{`${format(new Date(label), "dd/MMM/yyyy")}`}{" "}
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{report?.records?.map((l: any, i: any) => (
						<Fragment key={i}>
							<Tr key={i} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td key={i}>
									{l.dateWisePatrollingStats[0].user.name} @{l.dateWisePatrollingStats[0].user.username}
								</Td>
								{l?.dateWisePatrollingStats?.map((o: any, i: any) => (
									<Td key={i}>
										<Text as="span" textAlign={"center"}>
											Expected : {o.expectedRounds || 0} | Completed : {o.completedRoundsWithTakenTime?.length || 0} |{" "}
											{o.completedRoundsWithTakenTime.length
												? `Time Taken: 
											${o.completedRoundsWithTakenTime.map((t: any) => `${t} mins.`).join(" : ")}`
												: ""}{" "}
											| Assigned: {o.assignedRoute}
										</Text>
									</Td>
								))}
							</Tr>
						</Fragment>
					))}
				</Tbody>
			</Table>
		</Box>
	)
}
