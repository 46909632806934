import { Center, chakra, Heading, HStack, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useActiveUsersQuery } from "../../graphql"
import { LiveIndicator } from "../common"

export const CurrentlyActiveUsers: React.FC = () => {
	const [{ data, fetching, error }] = useActiveUsersQuery()

	return (
		<VStack flex="1" align="stretch" spacing={4} p="1">
			<Heading fontSize="sm">Currently active users</Heading>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeUsers ? (
				<VStack align="stretch">
					<HStack>
						<VStack align="stretch">
							<Heading color="primary.400">
								{data.activeUsers.userIds.length || 0}{" "}
								<chakra.span fontSize="sm" fontWeight="normal">
									users
								</chakra.span>
							</Heading>

							<LiveIndicator />
						</VStack>
					</HStack>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
