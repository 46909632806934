import { Box, Button, ButtonGroup, Center, Flex, HStack, Icon, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { CheckCircle, Lock, XCircle } from "react-feather"
import { NavLink } from "react-router-dom"
import { ExtendedUserFragment, MeUserFragment, useMeQuery, UserRoleTypes, UserStatus } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type UserProfileProps = {
	user: ExtendedUserFragment | MeUserFragment
}

export const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
	const [{ data }] = useMeQuery()

	const canUpdate = useMemo(() => {
		if (user.isBlacklisted) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "users/update-user", "users/read-user")[0]
		}

		return checkForPermissions(data?.me, "users/update-user")[0]
	}, [data, user])

	const canDeactivate = useMemo(() => {
		if (user.status === UserStatus.Inactive) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "users/deactivate-user", "users/read-user")[0]
		}

		return checkForPermissions(data?.me, "users/deactivate-user")[0]
	}, [data, user])

	const canBlacklist = useMemo(() => {
		if (user.isBlacklisted) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "users/blacklist-user")[0]
		}

		return false
	}, [data, user])

	const canWhitelist = useMemo(() => {
		if (!user.isBlacklisted) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "users/whitelist-user")[0]
		}

		return false
	}, [data, user])

	const canActivate = useMemo(() => {
		if (user.status === UserStatus.Active || user.isBlacklisted) return false

		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "users/activate-user", "users/read-user")[0]
		}

		return checkForPermissions(data?.me, "users/activate-user")[0]
	}, [data, user])

	const canResetPassword = useMemo(() => {
		if (user.type.roleType === UserRoleTypes.Stationary || user.type.roleType === UserRoleTypes.Patrolling) {
			return checkForPermissions(data?.me, "users/read-user", "users/reset-user-password")[0]
		}

		if (user.type.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(data?.me, "users/read-admin-user", "users/reset-admin-user-password")[0]
		}

		return false
	}, [data, user])

	return (
		<Card
			title="Profile"
			action={
				<ButtonGroup>
					{canUpdate && (
						<Button as={NavLink} to={`/users/${user._id}/updateProfile`} colorScheme="primary" size="sm">
							Update
						</Button>
					)}
					{canDeactivate && (
						<Button as={NavLink} to={`/users/${user._id}/deactivate`} colorScheme="error" size="sm">
							Deactivate
						</Button>
					)}
					{canActivate && (
						<Button as={NavLink} to={`/users/${user._id}/activate`} colorScheme="success" size="sm">
							Activate
						</Button>
					)}

					{canBlacklist && (
						<Button as={NavLink} to={`/users/${user._id}/blacklist`} colorScheme="red" size="sm">
							Blacklist
						</Button>
					)}

					{canWhitelist && (
						<Button as={NavLink} to={`/users/${user._id}/whitelist`} colorScheme="green" size="sm">
							Whitelist
						</Button>
					)}
					{canResetPassword && (
						<Button as={NavLink} to={`/users/${user._id}/resetPassword`} rightIcon={<Icon as={Lock} color="white" />} colorScheme="primary" size="sm">
							Reset Password
						</Button>
					)}
				</ButtonGroup>
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Phone" value={user.phone?.countryCode && user.phone.number ? `+${user.phone.countryCode} ${user.phone.number}` : "Not available"} />
				<Stat
					label={
						<HStack>
							<Text>Email</Text>
							{user.email?.isVerified ? (
								<Center rounded="full" bgColor="success.50" p="1">
									<Icon as={CheckCircle} color="success.800" />
								</Center>
							) : data?.me?._id === user._id ? (
								<Button as={NavLink} to="/verifyEmail" variant="link" size="xs" colorScheme="success">
									Verify
								</Button>
							) : (
								<Center rounded="full" bgColor="error.50" p="1">
									<Icon as={XCircle} color="error.800" />
								</Center>
							)}
						</HStack>
					}
					value={user.email?.address || "Not available"}
				/>
				<Stat label="Reference Number" value={user.referenceNo || "Not available"} />
				{user.isBlacklisted && user.blacklistRemarks && (
					<Box p="4">
						<VStack w="full" spacing={0}>
							<Text textAlign="center" fontSize="sm" fontWeight="bold" color="error.600">
								{user.blacklistRemarks}
							</Text>

							<Text textAlign="center" fontSize="xs" color="grayscale.label">
								Blacklist Remarks
							</Text>
						</VStack>
					</Box>
				)}
				<Stat label="Address" value={user.address || "Not available"} />
				<Stat label="Type" value={user.type.label.name} />
				<Stat label="Status" value={user.status === UserStatus.Active ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				<Stat label="Since" value={format(new Date(user.createdAt), "MMM dd, yyyy HH:mm")} />
			</Flex>
		</Card>
	)
}
