import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useServicesByKeywordQuery } from "../../graphql"
import { ServicesList } from "./ServicesList"

export type ServicesByKeywordProps = {
	keyword: string
}

export const ServicesByKeyword: React.FC<ServicesByKeywordProps> = ({ keyword }) => {
	const [filter, setFilter] = useState({ keyword })

	const [{ data, fetching, error }] = useServicesByKeywordQuery({
		variables: { ...filter },
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ keyword })
		}, 1000)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.servicesByKeyword ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading services</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.servicesByKeyword && data.servicesByKeyword.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.servicesByKeyword.length || 0} service
						{(data.servicesByKeyword.length || 0) > 1 ? "s" : ""}
					</Text>

					<ServicesList services={data.servicesByKeyword} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more services</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any services.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
