import { Heading, HStack, LinkBox, LinkOverlay, ListItem, Tag, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedZoneFragment, ZoneFragment } from "../../graphql"

export type ZoneListItemProps = {
	zone: ExtendedZoneFragment | ZoneFragment
}

export const ZoneListItem: React.FC<ZoneListItemProps> = ({ zone }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/zones/${zone._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start">
					<Heading fontSize="md" color="grayscale.title">
						{zone.label.name}
					</Heading>
				</VStack>
				<VStack align="flex-end">
					<Text fontSize="sm">{zone.type}</Text>
					<Tag variant="outline" colorScheme={zone.isActive ? "success" : "error"}>
						{zone.isActive ? "Active" : "Idle"}
					</Tag>
				</VStack>
			</HStack>
		</ListItem>
	)
}
