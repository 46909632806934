import { Heading, HStack, LinkBox, LinkOverlay, ListItem, VStack } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertFlowFragment } from "../../graphql"

export type AlertFlowListItemProps = {
	alertFlow: AlertFlowFragment
}

export const AlertFlowListItem: React.FC<AlertFlowListItemProps> = ({ alertFlow }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/alerts/flows/${alertFlow._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{alertFlow.label.name}
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						{startCase(alertFlow.type)}
					</Heading>
				</VStack>
			</HStack>
		</ListItem>
	)
}
