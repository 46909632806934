import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { AlertsByFilterQueryVariables, AlertStatus, AlertTypes, useAlertsByFilterQuery } from "../../graphql"
import { AlertsList } from "./AlertsList"

export type AlertsByFilterProps = {
	status?: AlertStatus
	type?: AlertTypes
}

export const AlertsByFilter: React.FC<AlertsByFilterProps> = ({ status, type }) => {
	const [filter, setFilter] = useState({ status, type })

	const [pagination, setPagination] = useState<AlertsByFilterQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useAlertsByFilterQuery({
		variables: { filter, pagination },
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ status, type })
		}, 1000)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [status, type])

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.alertsByFilter.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.alertsByFilter ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading alerts</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.alertsByFilter.alerts && data.alertsByFilter.alerts.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.alertsByFilter.alerts.length || 0} alert
						{(data.alertsByFilter.alerts.length || 0) > 1 ? "s" : ""}
					</Text>

					<AlertsList alerts={data.alertsByFilter.alerts} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more alerts</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any alerts.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
