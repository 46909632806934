/* eslint-disable @typescript-eslint/indent */
import { AspectRatio, Box, Center, HStack, useOutsideClick, Icon, IconButton, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { TileFragment, useTilesQuery } from "../../graphql"
import { Home, Map } from "react-feather"

export type TileSelectorProps = {
	tileId?: string
}

export const TileSelector: React.FC<TileSelectorProps> = ({ tileId }) => {
	const { pathname } = useLocation()

	const navigate = useNavigate()

	const [{ data }] = useTilesQuery()

	const [selectedTile, setSelectedTile] = useState<TileFragment>()

	useEffect(() => {
		if (pathname.startsWith("/map") && data?.tiles.length && !selectedTile) {
			if (tileId) {
				setSelectedTile(data.tiles.find((tile) => tile._id === tileId))
			} else {
				setSelectedTile(data.tiles[0])
				navigate(`/map/${data.tiles[0]._id}`)
			}
		}
	}, [data, selectedTile, pathname])

	const ref = React.useRef()

	useOutsideClick({
		ref: ref as any,
	})

	return (
		<>
			{!pathname.startsWith("/map") ? (
				<Box ref={ref as any} className="tiles-selector" pos="fixed" zIndex={10} top={12} right={4} w={24} transition="width .2s ease-in-out" bgColor="grayscale.off-white" rounded="2xl" overflow="hidden">
					<HStack justify="flex-start" overflow="auto">
						<AspectRatio
							w="full"
							minW={24}
							ratio={4 / 3}
							onClick={() => {
								setSelectedTile(data?.tiles.find((t) => t.embedsAllZones === true) || data?.tiles[0])
								navigate("/map")
							}}
							border="6px solid"
							borderColor="primary.400"
							rounded="2xl"
							cursor="pointer"
						>
							<Center w="full" bgColor="grayscale.off-white" rounded="lg">
								<Icon as={Map} fontSize="2xl" />
							</Center>
						</AspectRatio>
					</HStack>
				</Box>
			) : (
				<VStack>
					<IconButton pos="fixed" top={5} left={5} aria-label="home-btn" rounded="full" colorScheme="purple" as={NavLink} to="/">
						<Icon as={Home} />
					</IconButton>
				</VStack>
			)}
		</>
	)
}
