import { Flex, HStack, Link } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedDepartmentFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type DepartmentDetailsProps = {
	department: ExtendedDepartmentFragment
}

export const DepartmentDetails: React.FC<DepartmentDetailsProps> = ({ department }) => {
	return (
		<Card title="Details">
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat
					label="Zones"
					value={
						<HStack flexWrap="wrap" justify="center" maxW="xs">
							{department.zones.map((o, i) => (
								<Link key={o._id} as={NavLink} to={`/zones/${o._id}`}>
									{o.label.name}
									{i < department.zones.length - 1 && ","}
								</Link>
							))}
						</HStack>
					}
				/>
				<Stat label="Created" value={format(new Date(department.createdAt), "MMM dd, yyyy HH:mm")} />
			</Flex>
		</Card>
	)
}
