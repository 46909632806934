import { HStack, LinkBox, LinkOverlay, ListItem, Stack, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserAttendanceFragment } from "../../graphql"
import { Stat, UserInAttendanceImage, UserOutAttendanceImage, UserTag } from "../common"

export type UserAttendanceListItemProps = {
	userAttendance: ExtendedUserAttendanceFragment
}

export const UserAttendanceListItem: React.FC<UserAttendanceListItemProps> = ({ userAttendance }) => {
	return (
		<ListItem key={userAttendance._id} w="full" bgColor="green.100" rounded="xl">
			<HStack w="full" justifyContent="space-between" p="4">
				<VStack w="full" align="flex-start" spacing={0}>
					<HStack w="full" justify="space-between">
						<Stack as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
							<LinkOverlay as={NavLink} to={`/users/${userAttendance.user._id}`} />
							<HStack justifyContent="space-between" p="3">
								<UserTag user={userAttendance.user} />
							</HStack>
						</Stack>
						<HStack w="full" maxW="2xl" justify="space-around" flexWrap="wrap">
							<Stat label="Status" value="Present" />
							<Stat label="In Time" value={format(new Date(userAttendance.inAt), "MMMM dd, yyyy HH:mm")} />
							{userAttendance.inRemarks && <Stat label="In Remarks" value={userAttendance.inRemarks} />}
							{userAttendance.outRemarks && <Stat label="Out Remarks" value={userAttendance.outRemarks} />}
							{userAttendance.inImage && <UserInAttendanceImage userAttendance={userAttendance} />}
							{userAttendance.outAt && <Stat label="Out Time" value={format(new Date(userAttendance.outAt), "MMMM dd, yyyy HH:mm")} />}
							{userAttendance.outImage && <UserOutAttendanceImage userAttendance={userAttendance} />}
						</HStack>
					</HStack>
				</VStack>
			</HStack>
		</ListItem>
	)
}
