import { Heading, HStack, LinkBox, LinkOverlay, ListItem, Text, VStack } from "@chakra-ui/react"
import differenceInMinutes from "date-fns/differenceInMinutes"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ServiceFragment } from "../../graphql"

export type ServiceListItemProps = {
	service: ServiceFragment
}

export const ServiceListItem: React.FC<ServiceListItemProps> = ({ service }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/services/${service._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{service.label.name}
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						{service.key}
					</Heading>
				</VStack>
				<VStack align="flex-end" spacing={0}>
					{service.isActive && differenceInMinutes(new Date(), new Date(service.lastHeartbeatAt)) < 5 ? (
						<Text color="success.600" fontSize="sm" fontWeight="bold">
							Active
						</Text>
					) : (
						<Text color="error.600" fontSize="sm" fontWeight="bold">
							Inactive
						</Text>
					)}
					{service.lastHeartbeatAt && (
						<Text fontSize="xs" color="grayscale.label">
							{format(new Date(service.lastHeartbeatAt), "MMM dd, yyyy HH:mm")}
						</Text>
					)}
				</VStack>
			</HStack>
		</ListItem>
	)
}
