import { VStack } from "@chakra-ui/react"
import React from "react"
import { ExtendedAlertTargetGroupFragment } from "../../graphql"
import { UsersList } from "../users/UsersList"

export type UsersOfAlertTargetGroupProps = {
	alertTargetGroup: ExtendedAlertTargetGroupFragment
}

export const UsersOfAlertTargetGroup: React.FC<UsersOfAlertTargetGroupProps> = ({ alertTargetGroup }) => {
	return (
		<VStack w="full" maxW="2xl" align="stretch">
			<UsersList users={alertTargetGroup.users} />
		</VStack>
	)
}
