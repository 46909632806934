import { Select, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useUserTypesQuery } from "../../graphql"

export type UserTypeSelectorProps = {
	onUpdate: (userTypeId: string) => void
	value?: string
	isDisabled?: boolean
}

export const UserTypeSelector: React.FC<UserTypeSelectorProps> = ({ onUpdate, value, isDisabled }) => {
	const [{ data, error, fetching }] = useUserTypesQuery()

	return (
		<VStack>
			{fetching ? (
				<Text>Fetching types</Text>
			) : error ? (
				<VStack>
					<Text>Couldn&apos;t fetch types</Text>
					<Text>{error.message.replace("[GraphQL] ", "")}</Text>
				</VStack>
			) : !data?.userTypes.length ? (
				<VStack>
					<Text>Couldn&apos;t fetch types</Text>
				</VStack>
			) : (
				<Select flex="1" maxW="24" variant="filled" bgColor="grayscale.input-background" placeholder="Type" isDisabled={isDisabled} value={value} onChange={(e) => onUpdate(e.target.value)}>
					{data.userTypes.map((type) => (
						<option key={type._id} style={{ backgroundColor: "transparent" }} value={type._id}>
							{type.label.name}
						</option>
					))}
				</Select>
			)}
		</VStack>
	)
}
