import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useTileByIdQuery } from "../../graphql"
import { PatrollingRouteTile } from "./PatrollingRouteTile"

export type PatrollingRouteTileContainerProps = {
	tileId: string
	patrollingRouteId: string
}

export const PatrollingRouteTileContainer: React.FC<PatrollingRouteTileContainerProps> = ({ tileId, patrollingRouteId }) => {
	const [{ data, fetching, error }] = useTileByIdQuery({ variables: { tileId }, pause: !tileId })

	return (
		<VStack w="full" h="full" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading tile</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.tileById ? (
				<PatrollingRouteTile tile={data.tileById} patrollingRouteId={patrollingRouteId} />
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find the tile.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
