import { Center } from "@chakra-ui/react"
import React from "react"
import { Logo } from "../components"

export const SplashPage: React.FC = () => {
	return (
		<Center w="full" h="100vh">
			<Logo />
		</Center>
	)
}
