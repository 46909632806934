import { Select, VStack } from "@chakra-ui/react"
import React from "react"
import { ZoneTypes } from "../../graphql"

export type ZoneTypeSelectorProps = {
	onUpdate: (type: ZoneTypes) => void
	value?: string
}

export const ZoneTypeSelector: React.FC<ZoneTypeSelectorProps> = ({ onUpdate, value }) => {
	return (
		<VStack>
			<Select flex="1" maxW="24" variant="filled" bgColor="grayscale.input-background" placeholder="Type" value={value} onChange={(e) => onUpdate((e.target.value as ZoneTypes) || undefined)}>
				{Object.values(ZoneTypes).map((type) => (
					<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
						{type}
					</option>
				))}
			</Select>
		</VStack>
	)
}
