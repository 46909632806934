export enum TopBannerContextActions {
	SET_VISIBLE,
	SET_STATUS,
	SET_INVISIBLE,
	SET_CHILDREN,
}

export enum TopBannerStatus {
	SUCCESS,
	ERROR,
	WARNING,
	INFO,
}

export type TopBannerContextState = {
	isVisible: boolean
	status: TopBannerStatus
	children?: React.ReactNode
}

export type TopBannerContextAction = { type: TopBannerContextActions; payload?: Partial<TopBannerContextState> }
