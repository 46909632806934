/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Select, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { MobileAppTypes, UploadMobileAppMutationVariables, useUploadMobileAppMutation } from "../graphql"

export type UploadMobileAppFormProps = {
	uploadCompleted: () => void
}

type UploadMobileAppFormValues = UploadMobileAppMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UploadMobileAppFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	type: yup.string().oneOf(Object.values(MobileAppTypes)).required().label("Mobile App Type"),
	version: yup.string().required().label("Version"),
	changes: yup.string().required().label("Changes"),
	url: yup.string().label("Url"),
})

export const UploadMobileAppForm: React.FC<UploadMobileAppFormProps> = ({ uploadCompleted }) => {
	const initialValues: UploadMobileAppFormValues = {
		type: MobileAppTypes.Android,
		version: "",
		changes: "",
		url: "https://play.google.com/store/apps/details?id=com.lighthouse.smartworld",
	}

	const [{ fetching }, uploadMobileApp] = useUploadMobileAppMutation()

	const toast = useToast()

	const onSubmit = async (values: UploadMobileAppFormValues) => {
		const { data, error } = await uploadMobileApp({ input: { ...values } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.uploadMobileApp) {
			uploadCompleted()
			return
		}
	}

	const formik = useFormik<UploadMobileAppFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)} isRequired>
					<FormLabel fontWeight="bold">Type</FormLabel>

					<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("type")}>
						{Object.values(MobileAppTypes).map((type) => (
							<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
								{type}
							</option>
						))}
					</Select>

					<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.version && formik.errors.version)} isRequired>
					<FormLabel fontWeight="bold">Version</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter version" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("version")} />

					<FormErrorMessage>{formik.errors.version}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.changes && formik.errors.changes)} isRequired>
					<FormLabel fontWeight="bold">Changes</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter changes" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("changes")} />

					<FormErrorMessage>{formik.errors.changes}</FormErrorMessage>
				</FormControl>
			</VStack>

			<VStack w="full" align="flex-start" rounded="xl" py={2} px={4} spacing={0}>
				<HStack w="full" justify="space-between">
					<Button type="submit" colorScheme="green" isLoading={fetching}>
						Update
					</Button>
				</HStack>
			</VStack>
		</VStack>
	)
}
