import { Flex, Link, ListItem } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserLocationRecordFragment } from "../../graphql/generated"

import { transformDateTime } from "../../utils/transformDateTime"
import { Stat } from "../common"

export type ZonedUserLocationListItemProps = {
	userLocationRecord: ExtendedUserLocationRecordFragment
}

export const ZonedUserLocationListItem: React.FC<ZonedUserLocationListItemProps> = ({ userLocationRecord }) => {
	return (
		<ListItem bgColor={userLocationRecord.isRestricted ? "red.100" : "transparent"} rounded="lg">
			<Flex justify="center" align="center">
				<Stat
					label="User"
					value={
						<Link as={NavLink} to={`/users/${userLocationRecord.userId}`}>
							{userLocationRecord.user.name}
						</Link>
					}
				/>
				<Stat label="Since" value={transformDateTime(new Date(userLocationRecord.startAt))} />
				<Stat label="Status" value={userLocationRecord.status} />
				<Stat label="Until" value={transformDateTime(new Date(userLocationRecord.endAt))} />
			</Flex>
		</ListItem>
	)
}
