import { TopBannerContextAction, TopBannerContextActions, TopBannerStatus } from "./types"

export const setVisible = (): TopBannerContextAction => ({
	type: TopBannerContextActions.SET_VISIBLE,
})

export const setInvisible = (): TopBannerContextAction => ({
	type: TopBannerContextActions.SET_INVISIBLE,
})

export const setStatus = (status: TopBannerStatus): TopBannerContextAction => ({
	type: TopBannerContextActions.SET_STATUS,
	payload: { status },
})

export const setChildren = (children: React.ReactNode): TopBannerContextAction => ({
	type: TopBannerContextActions.SET_CHILDREN,
	payload: { children },
})
