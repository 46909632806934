import { Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalProps, Text, VStack } from "@chakra-ui/react"
import React from "react"

export type UserRouteFindingImageModalProps = {
	remarks: string
	imageUrl: string
} & Omit<ModalProps, "children">

export const UserRouteFindingImageModal: React.FC<UserRouteFindingImageModalProps> = ({ remarks, imageUrl, isOpen, onClose }) => {
	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>
					<Heading fontSize="md" alignSelf="flex-start">
						Route Finding
					</Heading>
				</ModalHeader>
				<ModalBody as={VStack} w="full" spacing={4}>
					<Image src={imageUrl} alt={remarks} />
					<Text fontSize="sm" alignSelf="flex-start">
						{remarks}
					</Text>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
