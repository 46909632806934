import { authExchange as authE } from "@urql/exchange-auth"
import { makeOperation } from "urql"
import { SESSION_TOKEN_KEY } from "../../constants"

interface AuthState {
	sessionToken: string
}

export const authExchange = authE<AuthState>({
	addAuthToOperation: ({ authState, operation }) => {
		if (!authState || !authState.sessionToken) {
			return operation
		}

		const fetchOptions = typeof operation.context.fetchOptions === "function" ? operation.context.fetchOptions() : operation.context.fetchOptions || {}

		return makeOperation(operation.kind, operation, {
			...operation.context,
			fetchOptions: {
				...fetchOptions,
				headers: {
					...fetchOptions.headers,
					Authorization: "Bearer " + authState.sessionToken,
				},
			},
		})
	},
	willAuthError: () => {
		const sessionToken = localStorage.getItem(SESSION_TOKEN_KEY) as string

		if (!sessionToken) return true

		return false
	},
	didAuthError: ({ error }) => {
		return error.graphQLErrors.some((e) => e.message.includes("Access denied!"))
	},
	getAuth: async () => {
		const sessionToken = localStorage.getItem(SESSION_TOKEN_KEY)

		if (sessionToken) {
			return { sessionToken }
		}

		return null
	},
})
