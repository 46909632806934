import { Box, BoxProps, HStack, Text } from "@chakra-ui/react"
import { motion, MotionProps } from "framer-motion"
import React, { ComponentType } from "react"

const MotionBox = motion<MotionProps & Omit<BoxProps, "transition">>(Box as unknown as ComponentType<MotionProps & Omit<BoxProps, "transition">>)

export const LiveIndicator: React.FC = () => {
	return (
		<HStack>
			<Box pos="relative">
				<MotionBox
					pos="absolute"
					top="50%"
					left="50%"
					initial={{ transform: "translate(-50%, -50%) scale(1)", opacity: 1 }}
					animate={{ transform: "translate(-50%, -50%) scale(2.5)", opacity: 0 }}
					transition={{ repeat: Infinity, repeatType: "loop", duration: 1 }}
					w="2"
					h="2"
					bgColor="success.600"
					rounded="full"
				/>{" "}
				<Box pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" w="2" h="2" bgColor="success.600" rounded="full" />
			</Box>
			<Text color="success.600" fontWeight="semibold">
				Live
			</Text>
		</HStack>
	)
}
