import { DashboardContextAction, DashboardContextActions, DashboardContextState } from "./types"

export const initialState: DashboardContextState = {}

export const dashboardContextReducer = (state: DashboardContextState, action: DashboardContextAction): DashboardContextState => {
	switch (action.type) {
		case DashboardContextActions.SET_DASHBOARD_TYPE: {
			localStorage.setItem("@LIGHTHOUSE_SMARTWORLD/DASHBOARD_TYPE", action.payload)

			return { type: action.payload }
		}

		default:
			return state
	}
}
