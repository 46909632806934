import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { UsersByDepartmentQueryVariables, useUsersByDepartmentQuery } from "../../graphql"
import { UsersList } from "./UsersList"

export type UsersByDepartmentProps = {
	departmentId: string
}

export const UsersByDepartment: React.FC<UsersByDepartmentProps> = ({ departmentId }) => {
	const [filter, setFilter] = useState({ departmentId })

	const [pagination, setPagination] = useState<UsersByDepartmentQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useUsersByDepartmentQuery({
		variables: { ...filter, pagination },
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ departmentId })
		}, 1000)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [departmentId])

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.usersByDepartment.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.usersByDepartment ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.usersByDepartment.users && data.usersByDepartment.users.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.usersByDepartment.users.length || 0} user
						{(data.usersByDepartment.users.length || 0) > 1 ? "s" : ""}
					</Text>

					<UsersList users={data.usersByDepartment.users} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more users</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any users.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
