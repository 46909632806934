import { HStack, VStack } from "@chakra-ui/react"
import React from "react"
import { Outlet } from "react-router-dom"
import { Sidebar } from "../sidebar"
import { TopBanner } from "./TopBanner"

export const Scaffold: React.FC = () => {
	return (
		<>
			<HStack w="full" align="stretch" h="full">
				<Sidebar />
				<VStack pos="relative" marginStart={0} marginInlineStart="0 !important" pl="320px !important" transition="all 200ms ease-in-out" w="full" h="100vh" align="flex-start" overflowY="auto" overflowX="hidden">
					<TopBanner />
					<Outlet />
				</VStack>
			</HStack>
		</>
	)
}
