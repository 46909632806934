import { HStack, Icon, Text, Tooltip, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { Check, X } from "react-feather"
import { PictureObjectFragment } from "../../graphql"
import { UserRouteCheckpointImageModal } from "./UserRouteCheckpointImageModal"

type RouteCheckpointProps = {
	label: string
	isOk?: boolean | null
	image?: PictureObjectFragment | null
}

export const RouteCheckpoint: React.FC<RouteCheckpointProps> = ({ label, isOk, image }) => {
	const { isOpen, onClose, onOpen } = useDisclosure()

	if (image) {
		return (
			<>
				<Tooltip label="Click to see picture">
					<HStack
						as="button"
						_hover={{
							textDecoration: "underline",
						}}
						onClick={() => {
							if (!image) return
							onOpen()
						}}
					>
						{isOk ? <Icon as={Check} color="green.600" /> : <Icon as={X} color="red.600" />}
						<Text fontSize="xs" color={isOk ? "green.600" : "red.600"}>
							{label}
						</Text>
					</HStack>
				</Tooltip>
				<UserRouteCheckpointImageModal label={label} imageUrl={image.url} isOpen={isOpen} onClose={onClose} />
			</>
		)
	} else if (typeof isOk === "boolean") {
		return (
			<HStack>
				{isOk ? <Icon as={Check} color="green.600" /> : <Icon as={X} color="red.600" />}
				<Text fontSize="xs" color={isOk ? "green.600" : "red.600"}>
					{label}
				</Text>
			</HStack>
		)
	}

	return <></>
}
