import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, useToast, VStack } from "@chakra-ui/react"
import { isValid } from "date-fns"
import { useFormik } from "formik"
import React, { useState } from "react"
import "react-calendar/dist/Calendar.css"
import "react-clock/dist/Clock.css"
import DateTimePicker from "react-datetime-picker"
import "react-datetime-picker/dist/DateTimePicker.css"
import * as Yup from "yup"
import { useMarkUserAttendanceOutMutation } from "../graphql"

type MarkManualUserAttendanceOutFormValues = {
	outTime: Date
	outRemarks: string
}

const validationSchema = Yup.object({
	outTime: Yup.date().required().label("Out Time"),
	outRemarks: Yup.string().required().label("Out Remarks"),
})

export type MarkManualUserAttendanceOutFormProps = {
	userId: string
}

const MarkManualUserAttendanceOutForm: React.FC<MarkManualUserAttendanceOutFormProps> = ({ userId }) => {
	const toast = useToast()

	const [{ fetching }, markUserAttendanceOutMutation] = useMarkUserAttendanceOutMutation()
	const handleSubmit = async (values: MarkManualUserAttendanceOutFormValues) => {
		const { data, error } = await markUserAttendanceOutMutation({
			userId: userId,
			outTime: new Date(values.outTime),
			outRemarks: values.outRemarks,
		})

		if (error) {
			toast({
				title: "Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
				duration: 5000,
				isClosable: true,
			})
			return
		}

		if (data?.markUserAttendanceOut) {
			toast({
				title: "Attendance marked out",
				description: "Attendance marked successfully",
				status: "success",
				duration: 5000,
				isClosable: true,
			})
		}
	}
	const formik = useFormik<MarkManualUserAttendanceOutFormValues>({
		initialValues: {
			outTime: new Date(),
			outRemarks: "",
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit,
	})
	const options = ["No electricity", "Internet issue", "Card Punch issue", "Machine Not wroking"]
	const [isOtherSelected, setIsOtherSelected] = useState(false)
	const [isOptionSelected, setIsOptionSelected] = useState(false)

	return (
		<VStack w="fit-content" align="flex-start" pl="4">
			<form onSubmit={formik.handleSubmit}>
				<VStack w="full" maxW="lg" align="stretch" spacing={4}>
					<FormControl id="outTime">
						<FormLabel>Out Time</FormLabel>
						<DateTimePicker onChange={(value) => isValid(value) && formik.setFieldValue("outTime", value)} value={formik.values.outTime} format="dd/MM/yyyy hh:mm a" maxDate={new Date()} clearIcon={null} />
					</FormControl>

					<HStack justifyContent={"flex-start"} gap={5} wrap="wrap">
						{options.map((option, index) => (
							<Button
								variant={isOptionSelected ? (formik.values.outRemarks === option ? "solid" : "outline") : "outline"}
								colorScheme="primary"
								color={isOptionSelected ? (formik.values.outRemarks === option ? "white" : "primary.500") : "primary.500"}
								size="sm"
								p={2}
								key={index}
								onClick={() => {
									formik.setFieldValue("outRemarks", option)
									setIsOptionSelected(true)
									setIsOtherSelected(false)
								}}
							>
								{option}
							</Button>
						))}
						<Button
							variant={isOtherSelected ? "solid" : "outline"}
							colorScheme="primary"
							color={isOtherSelected ? "white" : "primary.500"}
							size="sm"
							p={2}
							onClick={() => {
								formik.setFieldValue("inRemarks", "")
								setIsOtherSelected(!isOtherSelected)

								formik.setFieldValue("outRemarks", "")
							}}
						>
							Other
						</Button>
					</HStack>
					{isOtherSelected && (
						<FormControl id="outRemarks" isInvalid={!!formik.errors.outRemarks && formik.touched.outRemarks}>
							<FormLabel>Out Remarks</FormLabel>
							<Input {...formik.getFieldProps("outRemarks")} />
							<FormErrorMessage>{formik.errors.outRemarks}</FormErrorMessage>
						</FormControl>
					)}

					<Button size="sm" alignSelf="flex-start" type="submit" isLoading={fetching} colorScheme="primary">
						Mark Out
					</Button>
				</VStack>
			</form>
		</VStack>
	)
}

export default MarkManualUserAttendanceOutForm
