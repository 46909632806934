import { Box, BoxProps, Button, CloseButton, FormControl, FormErrorMessage, FormLabel, HStack, Input, NumberInput, NumberInputField, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import { motion, MotionProps } from "framer-motion"
import React, { ComponentType, FormEvent } from "react"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { useCreatePatrollingRouteMutation, ZoneFragment } from "../graphql"

type CreatePatrollingRouteFormValues = {
	label: {
		name: string
		description: string
	}
	minTime: number
	maxTime: number
}

const validationSchema = yup.object<Record<keyof CreatePatrollingRouteFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	minTime: yup.number().min(0).required().label("Minimum Time"),
	maxTime: yup.number().min(1).required().label("Maximum Time"),
})

const initialValues: CreatePatrollingRouteFormValues = {
	label: { name: "", description: "" },
	minTime: 0,
	maxTime: 1,
}

export type CreatePatrollingRouteFormProps = {
	zones: ZoneFragment[]
	onDeselectZone: (index: number) => void
	onFinish?: () => void
}

const MotionBox = motion<MotionProps & Omit<BoxProps, "transition">>(Box as unknown as ComponentType<MotionProps & Omit<BoxProps, "transition">>)

export const CreatePatrollingRouteForm: React.FC<CreatePatrollingRouteFormProps> = ({ zones, onDeselectZone, onFinish }) => {
	const [{ fetching }, createPatrollingRoute] = useCreatePatrollingRouteMutation()

	const toast = useToast()

	const onSubmit = async (values: CreatePatrollingRouteFormValues) => {
		const { data, error } = await createPatrollingRoute({ input: { ...values, zoneIds: zones.map((zone) => zone._id) } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createPatrollingRoute) {
			onFinish?.()
			return toast({ description: "Route created successfully", status: "success" })
		}
	}

	const formik = useFormik<CreatePatrollingRouteFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<VStack w="full" maxH="xs" overflowY="auto">
					{zones.map((z, index) => (
						<MotionBox key={z._id} w="full" initial={{ opacity: 0, translateX: "24px" }} animate={{ opacity: 1, translateX: 0 }} transition={{ delay: Math.min(index / 10, 2) }}>
							<HStack w="full" h="full" align="stretch">
								<Box pos="relative" w="2" bgColor="purple.400" ml={2} mb={index === zones.length - 1 ? 0 : -2} roundedTop={index === 0 ? "full" : undefined} roundedBottom={index === zones.length - 1 ? "full" : undefined}>
									<Box pos="absolute" w="4" h="4" top="50%" left="50%" transform="translate(-50%, -50%)" bgColor="purple.500" rounded="full" />
								</Box>
								<HStack w="full" justifyContent="space-between" bgColor="whiteAlpha.600" px="4" py="2" rounded="md">
									<Text>{z.label.name}</Text>
									<CloseButton onClick={() => onDeselectZone(index)} />
								</HStack>
							</HStack>
						</MotionBox>
					))}
				</VStack>
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>

				<HStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.minTime && formik.errors.minTime)}>
						<FormLabel fontWeight="bold">Min. Time (in minutes)</FormLabel>
						<NumberInput>
							<NumberInputField
								min={0}
								pattern="(-)?[0-9]*(.[0-9]+)?"
								bgColor="whiteAlpha.400"
								backdropFilter="blur(24px)"
								border="none"
								_focus={{ border: "none" }}
								placeholder="Min. Time"
								value={formik.values.minTime}
								onChange={(e) => formik.setFieldValue("minTime", Number(e.target.value))}
							/>
						</NumberInput>
						<FormErrorMessage>{formik.errors.minTime}</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={Boolean(formik.touched.maxTime && formik.errors.maxTime)}>
						<FormLabel fontWeight="bold">Max. Time (in minutes)</FormLabel>
						<NumberInput>
							<NumberInputField
								min={0}
								pattern="(-)?[0-9]*(.[0-9]+)?"
								bgColor="whiteAlpha.400"
								backdropFilter="blur(24px)"
								border="none"
								_focus={{ border: "none" }}
								placeholder="Max. Time"
								value={formik.values.maxTime}
								onChange={(e) => formik.setFieldValue("maxTime", Number(e.target.value))}
							/>
						</NumberInput>
						<FormErrorMessage>{formik.errors.maxTime}</FormErrorMessage>
					</FormControl>
				</HStack>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
