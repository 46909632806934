import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format, isValid } from "date-fns"
import React, { Fragment } from "react"

export type SummarizedUserAttendanceReportProps = {
	report: any
}

export const SummarizedUserAttendanceReport: React.FC<SummarizedUserAttendanceReportProps> = ({ report }) => {
	return (
		<Box w="full" overflow="auto">
			<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
				<Thead>
					<Tr>
						<Th textTransform="capitalize">Users</Th>
						{report.labels.map((label: string) => (
							<Th key={label} textTransform="capitalize" w="12">
								{isValid(new Date(label)) ? format(new Date(label), "dd/MMM/yyyy") : "Invalid Date"}
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{report.attendances.map((a: any, i: any) => {
						return (
							<Fragment key={i}>
								<Tr key={i} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
									<Td key={i}>{a.user}</Td>
									{a?.labeledAttendances?.map((l: string) => (
										<Td key={i}>
											<VStack gap={2} justifyContent="flex-start">
												<Text as="span" textAlign={"center"}>
													{l}
												</Text>
											</VStack>
										</Td>
									))}
								</Tr>
							</Fragment>
						)
					})}
				</Tbody>
			</Table>
		</Box>
	)
}
