import { Accordion, Badge, Button, ButtonGroup, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Aperture, Bell, Box, Clock, File, FilePlus, Grid, HardDrive, Hexagon, Key, Link2, PieChart, Server, Shield, Tag, User, Users } from "react-feather"
import { NavLink } from "react-router-dom"
import { DashboardTypes } from "../../context/Dashboard/types"
import { useAlertsUpdateSubscription, useMeQuery } from "../../graphql"
import { useDashboardContext } from "../../hooks"
import { Logo } from "../common"
import { AccountPopover } from "../common/AccountPopover"
import { SidebarAccordionItem } from "./SidebarAccordionItem"
import { SidebarButton } from "./SidebarButton"

export const Sidebar: React.FC = () => {
	const [{ data }] = useMeQuery()

	const [newAlerts, setNewAlerts] = useState(new Set<string>())

	const [{ data: alertsData }] = useAlertsUpdateSubscription()

	useEffect(() => {
		if (alertsData?.alertsUpdate) {
			setNewAlerts((prev) => new Set([...prev, alertsData.alertsUpdate._id]))
		}
	}, [alertsData])

	const { type, dispatch, setDashboardType } = useDashboardContext()

	return (
		<VStack pos="absolute" top="0" left="0" w="320px" h="100vh" bgColor="grayscale.background" backdropFilter="blur(24px)" align="stretch" justify="space-between" overflow="hidden" zIndex={10} p="4">
			<VStack h="full" align="stretch" overflow="auto" pb="4" spacing={6}>
				<NavLink to="/">
					<Logo />
				</NavLink>

				{data && data.me && (
					<Badge fontSize="xs" textAlign="center" color="primary.500" variant="outline" p="2">
						{data.me.site.label.name}
					</Badge>
				)}

				<ButtonGroup alignSelf="center" spacing={0}>
					<Button
						size="xs"
						variant={type === "Standard" ? "solid" : "outline"}
						opacity={type === "Standard" ? 1 : 0.5}
						colorScheme="primary"
						roundedEnd="none"
						onClick={() => {
							dispatch(setDashboardType(DashboardTypes.STANDARD))
						}}
					>
						Standard
					</Button>
					<Button
						size="xs"
						variant={type === "Confidentiality" ? "solid" : "outline"}
						opacity={type === "Confidentiality" ? 1 : 0.5}
						colorScheme="primary"
						roundedStart="none"
						onClick={() => {
							dispatch(setDashboardType(DashboardTypes.CONFIDENTIALITY))
						}}
					>
						Confidentiality
					</Button>
				</ButtonGroup>

				<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
					<SidebarButton to="/" icon={PieChart}>
						Dashboard
					</SidebarButton>

					<SidebarAccordionItem
						tos={["/users", "/users/create", "/users/types", "/users/types/create"]}
						permissionTags={["users/read-user"]}
						button={(isActive) => (
							<SidebarButton isNested isActive={isActive} icon={User}>
								Users
							</SidebarButton>
						)}
					>
						<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
							<SidebarButton to="/users" permissionTags={["users/read-user"]}>
								Users
							</SidebarButton>
							<SidebarButton to="/users/create" permissionTags={["users/create-user"]}>
								Create User
							</SidebarButton>
							<SidebarAccordionItem
								tos={["/users/types", "/users/types/create"]}
								permissionTags={["user-types/read-user-type"]}
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Users}>
										User Types
									</SidebarButton>
								)}
							>
								<SidebarButton to="/users/types" permissionTags={["user-types/read-user-type"]}>
									User Types
								</SidebarButton>
								<SidebarButton to="/users/types/create" permissionTags={["user-types/create-user-type"]}>
									Create User Type
								</SidebarButton>
							</SidebarAccordionItem>
							<SidebarAccordionItem
								tos={["/users/groups", "/users/groups/create"]}
								permissionTags={["users/read-user-group", "users/create-user-group"]}
								permissionsType="or"
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Users}>
										User Groups
									</SidebarButton>
								)}
							>
								<SidebarButton to="/users/groups" permissionTags={["users/read-user-group"]}>
									User Groups
								</SidebarButton>
								<SidebarButton to="/users/groups/create" permissionTags={["users/create-user-group"]}>
									Create User Group
								</SidebarButton>
							</SidebarAccordionItem>
						</Accordion>
					</SidebarAccordionItem>

					<SidebarAccordionItem
						tos={["/zones", "/zones/groups", "/zones/groups/create"]}
						permissionTags={["zones/read-zone"]}
						button={(isActive) => (
							<SidebarButton icon={Hexagon} isActive={isActive} isNested>
								Zones
							</SidebarButton>
						)}
					>
						<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
							<SidebarButton to="/zones" permissionTags={["zones/read-zone"]}>
								Zones
							</SidebarButton>
							<SidebarAccordionItem
								tos={["/zones/groups", "/zones/groups/create"]}
								permissionTags={["zones/read-zone-group"]}
								button={(isActive) => (
									<SidebarButton icon={Hexagon} isActive={isActive} isNested>
										Zone Groups
									</SidebarButton>
								)}
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/zones/groups" permissionTags={["zones/read-zone-group"]}>
										Zone Groups
									</SidebarButton>
									<SidebarButton to="/zones/groups/create" permissionTags={["zones/create-zone-group"]}>
										Create Zone Group
									</SidebarButton>
								</VStack>
							</SidebarAccordionItem>
						</Accordion>
					</SidebarAccordionItem>

					<SidebarAccordionItem
						tos={["/patrollingRoutes", "/patrollingRoutes/create"]}
						permissionTags={["patrolling-routes/read-patrolling-route", "zones/read-zone"]}
						button={(isActive) => (
							<SidebarButton icon={Aperture} isActive={isActive} isNested>
								Patrolling Routes
							</SidebarButton>
						)}
					>
						<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
							<SidebarButton to="/patrollingRoutes" permissionTags={["patrolling-routes/read-patrolling-route", "zones/read-zone"]}>
								Patrolling Routes
							</SidebarButton>
						</Accordion>
					</SidebarAccordionItem>

					<SidebarAccordionItem
						tos={["/reports/past", "/reports/generate"]}
						permissionTags={["reports/read-user-location-records-report"]}
						button={(isActive) => (
							<SidebarButton isNested isActive={isActive} icon={File}>
								Reports
							</SidebarButton>
						)}
					>
						<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
							<SidebarButton to="/reports/past">Past</SidebarButton>
							<SidebarAccordionItem
								tos={["/reports/generate/userMovement"]}
								permissionTags={["reports/create-user-location-records-report"]}
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={FilePlus}>
										Generate
									</SidebarButton>
								)}
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/reports/generate/userMovement" permissionTags={["reports/create-user-location-records-report"]}>
										User Movement
									</SidebarButton>
									<SidebarButton to="/reports/generate/userPatrollingRoute" permissionTags={["reports/create-user-location-records-report"]}>
										User Patrolling Route
									</SidebarButton>

									<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
										<SidebarAccordionItem
											tos={["/reports/generate/comprehensiveUserAttendance", "/reports/generate/summarizedUserAttendance"]}
											permissionTags={["reports/create-comprehensive-user-attendance-report", "reports/create-summarized-user-attendance-report"]}
											permissionsType="or"
											button={(isActive) => (
												<SidebarButton isNested isActive={isActive}>
													User Attendance
												</SidebarButton>
											)}
										>
											<VStack h="full" align="stretch" overflow="auto" pb="2">
												<SidebarButton to="/reports/generate/comprehensiveUserAttendance" permissionTags={["reports/create-comprehensive-user-attendance-report"]}>
													Comprehensive
												</SidebarButton>
											</VStack>

											<VStack h="full" align="stretch" overflow="auto" pb="2">
												<SidebarButton to="/reports/generate/summarizedUserAttendance" permissionTags={["reports/create-summarized-user-attendance-report"]}>
													Summarized
												</SidebarButton>
											</VStack>
										</SidebarAccordionItem>
									</Accordion>
									<SidebarButton to="/reports/generate/readersStatusLogs" permissionTags={["reports/create-reader-status-logs-report"]}>
										Readers Status Logs
									</SidebarButton>
									<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
										<SidebarAccordionItem
											tos={["/reports/generate/restrictedEntries/users", "/reports/generate/restrictedEntries/zones", "/reports/generate/restrictedEntries/zoneGroups"]}
											permissionTags={["reports/create-user-restricted-entry-report", "reports/create-zone-restricted-entry-report", "reports/create-zone-group-restricted-entry-report"]}
											permissionsType="or"
											button={(isActive) => (
												<SidebarButton isNested isActive={isActive}>
													Restricted Entries
												</SidebarButton>
											)}
										>
											<VStack h="full" align="stretch" overflow="auto" pb="4">
												<SidebarButton to="/reports/generate/restrictedEntries/users" permissionTags={["reports/create-user-restricted-entry-report"]}>
													Users
												</SidebarButton>
												<SidebarButton to="/reports/generate/restrictedEntries/zones" permissionTags={["reports/create-zone-restricted-entry-report"]}>
													Zones
												</SidebarButton>
												<SidebarButton to="/reports/generate/restrictedEntries/zoneGroups" permissionTags={["reports/create-zone-group-restricted-entry-report"]}>
													Zone Groups
												</SidebarButton>
											</VStack>
										</SidebarAccordionItem>
									</Accordion>
								</VStack>
							</SidebarAccordionItem>
						</Accordion>
					</SidebarAccordionItem>

					<SidebarAccordionItem
						tos={["/alerts", "/alerts/flows", "/alerts/flows/create", "/alerts/targetGroups", "/alerts/targetGroups/create"]}
						permissionTags={["alerts/read-alert", "alerts/read-alert-flow", "alerts/create-alert-flow", "alerts/read-alert-target-group", "alerts/create-alert-target-group"]}
						permissionsType="or"
						button={(isActive) => (
							<SidebarButton
								isNested
								isActive={isActive}
								icon={Bell}
								rightIcon={
									newAlerts.size ? (
										<Badge variant="solid" rounded="full" colorScheme="warning">
											{newAlerts.size}
										</Badge>
									) : undefined
								}
							>
								Alerts
							</SidebarButton>
						)}
					>
						<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
							<VStack h="full" align="stretch" overflow="auto" pb="4">
								<SidebarButton
									to="/alerts"
									rightIcon={
										newAlerts.size ? (
											<Badge variant="solid" rounded="full" colorScheme="warning">
												{newAlerts.size}
											</Badge>
										) : undefined
									}
								>
									Alerts
								</SidebarButton>
							</VStack>
							<SidebarAccordionItem
								tos={["/alerts/flows", "/alerts/flows/create"]}
								permissionTags={["alerts/read-alert-flow", "alerts/create-alert-flow"]}
								permissionsType="or"
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Link2}>
										Alert Flows
									</SidebarButton>
								)}
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/alerts/flows" permissionTags={["alerts/read-alert-flow"]}>
										Alert Flows
									</SidebarButton>
									<SidebarButton to="/alerts/flows/create" permissionTags={["alerts/create-alert-flow"]}>
										Create Alert Flow
									</SidebarButton>
								</VStack>
							</SidebarAccordionItem>
							<SidebarAccordionItem
								tos={["/alerts/targetGroups", "/alerts/targetGroups/create"]}
								permissionTags={["alerts/read-alert-target-group", "alerts/create-alert-target-group"]}
								permissionsType="or"
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Users}>
										Alert Target Groups
									</SidebarButton>
								)}
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/alerts/targetGroups" permissionTags={["alerts/read-alert-target-group"]}>
										Alert Target Groups
									</SidebarButton>
									<SidebarButton to="/alerts/targetGroups/create" permissionTags={["alerts/create-alert-target-group"]}>
										Create Alert Target Group
									</SidebarButton>
								</VStack>
							</SidebarAccordionItem>
						</Accordion>
					</SidebarAccordionItem>

					<SidebarAccordionItem
						tos={["/departments", "/departments/create", "/workShifts", "/workShifts/create", "/permissionsGroups", "/permissionsGroups/create", "/userRoleGroups", "/userRoleGroups/create"]}
						permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}
						button={(isActive) => (
							<SidebarButton isNested isActive={isActive} icon={Box}>
								More
							</SidebarButton>
						)}
					>
						<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
							<SidebarAccordionItem
								tos={["/departments", "/departments/create"]}
								permissionTags={["departments/read-department"]}
								button={(isActive) => (
									<SidebarButton icon={Grid} isActive={isActive} isNested>
										Departments
									</SidebarButton>
								)}
							>
								<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
									<SidebarButton to="/departments" permissionTags={["departments/read-department"]}>
										Departments
									</SidebarButton>
									<SidebarButton to="/departments/create" permissionTags={["departments/create-department"]}>
										Create Department
									</SidebarButton>
								</Accordion>
							</SidebarAccordionItem>
							<SidebarAccordionItem
								tos={["/workShifts", "/workShifts/create"]}
								permissionTags={["work-shifts/read-work-shift"]}
								button={(isActive) => (
									<SidebarButton icon={Clock} isActive={isActive} isNested>
										Work Shifts
									</SidebarButton>
								)}
							>
								<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
									<SidebarButton to="/workShifts" permissionTags={["work-shifts/read-work-shift"]}>
										Work Shifts
									</SidebarButton>
									<SidebarButton to="/workShifts/create" permissionTags={["work-shifts/create-work-shift"]}>
										Create Work Shift
									</SidebarButton>
								</Accordion>
							</SidebarAccordionItem>

							<SidebarAccordionItem
								tos={["/permissionsGroups", "/permissionsGroups/create"]}
								permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Key}>
										Permissions Groups
									</SidebarButton>
								)}
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/permissionsGroups" permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
										Permissions Groups
									</SidebarButton>
									<SidebarButton to="/permissionsGroups/create" permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group", "permissions/create-permissions-group"]}>
										Create Permissions Group
									</SidebarButton>
								</VStack>
							</SidebarAccordionItem>

							<SidebarAccordionItem
								tos={["/userRoleGroups", "/userRoleGroups/create"]}
								permissionTags={["user-roles/read-group", "user-roles/create-group"]}
								permissionsType="or"
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Shield}>
										User Role Groups
									</SidebarButton>
								)}
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/userRoleGroups" permissionTags={["user-roles/read-group"]} permissionsType="or">
										User Role Groups
									</SidebarButton>
									<SidebarButton to="/userRoleGroups/create" permissionTags={["user-roles/create-group"]} permissionsType="or">
										Create User Role Group
									</SidebarButton>
								</VStack>
							</SidebarAccordionItem>

							<SidebarAccordionItem
								tos={["/readers", "/readers/create"]}
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={HardDrive}>
										Readers
									</SidebarButton>
								)}
								permissionTags={["readers/read-reader"]}
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/readers" permissionTags={["readers/read-reader"]}>
										Readers
									</SidebarButton>
									<SidebarButton to="/readers/create" permissionTags={["readers/create-reader"]}>
										Create Reader
									</SidebarButton>
								</VStack>
							</SidebarAccordionItem>

							<SidebarAccordionItem
								tos={["/tags", "/tags/create"]}
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Tag}>
										Tags
									</SidebarButton>
								)}
								permissionTags={["tags/read-tag"]}
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/tags" permissionTags={["tags/read-tag"]}>
										Tags
									</SidebarButton>
									<SidebarButton to="/tags/create" permissionTags={["tags/create-tag"]}>
										Create Tag
									</SidebarButton>
								</VStack>
							</SidebarAccordionItem>

							<SidebarAccordionItem
								tos={["/services", "/services/create"]}
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Server}>
										Services
									</SidebarButton>
								)}
								permissionTags={["services/read-service", "services/create-service"]}
								permissionsType="or"
							>
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton to="/services" permissionTags={["services/read-service"]}>
										Services
									</SidebarButton>
									<SidebarButton to="/services/create" permissionTags={["services/create-service"]}>
										Create Service
									</SidebarButton>
								</VStack>
							</SidebarAccordionItem>
						</Accordion>
					</SidebarAccordionItem>
				</Accordion>
			</VStack>
			<AccountPopover />
		</VStack>
	)
}
