import { Button, FormControl, FormErrorMessage, FormLabel, Input, NumberInput, NumberInputField, Select, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ExtendedZoneFragment, UpdateZoneMutationVariables, useUpdateZoneMutation, ZoneTypes } from "../graphql"

type UpdateZoneFormValues = UpdateZoneMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UpdateZoneFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	type: yup.string().oneOf(Object.values(ZoneTypes)).required().label("Type"),
	crowdLimit: yup.number().required().label("Crowd Limit"),
})

export type UpdateZoneFormProps = {
	zone: ExtendedZoneFragment
}

export const UpdateZoneForm: React.FC<UpdateZoneFormProps> = ({ zone }) => {
	const initialValues: UpdateZoneFormValues = {
		label: { name: zone.label.name, description: zone.label.description },
		type: zone.type,
		crowdLimit: zone.crowdLimit,
	}

	const [{ fetching }, updateZone] = useUpdateZoneMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateZoneFormValues) => {
		const { data, error } = await updateZone({ zoneId: zone._id, input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateZone) {
			navigate(`/zones/${data.updateZone._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateZoneFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)}>
					<FormLabel fontWeight="bold">Type</FormLabel>

					<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select type" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("type")}>
						{Object.values(ZoneTypes).map((type) => (
							<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
								{type}
							</option>
						))}
					</Select>

					<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.crowdLimit && formik.errors.crowdLimit)}>
					<FormLabel fontWeight="bold">Crowd Limit</FormLabel>
					<NumberInput value={formik.values.crowdLimit}>
						<NumberInputField
							min={1}
							pattern="(-)?[0-9]*(.[0-9]+)?"
							bgColor="whiteAlpha.400"
							backdropFilter="blur(24px)"
							border="none"
							_focus={{ border: "none" }}
							placeholder="Crowd Limit"
							value={formik.values.crowdLimit}
							onChange={(e) => formik.setFieldValue("crowdLimit", Number(e.target.value))}
						/>
					</NumberInput>
					<FormErrorMessage>{formik.errors.crowdLimit}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
