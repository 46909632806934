import React, { lazy } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

const LoginPage = lazy(() => import("../pages/Login.page"))

export const UnauthorizedRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<LoginPage />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	)
}
