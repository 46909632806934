import { VStack } from "@chakra-ui/react"
import React from "react"
import { Header } from "../../components"
import { MarkManualUserAttendanceBulkInForm } from "../../forms"

const MarkAttendanceInPage: React.FC = () => {
	return (
		<VStack w="full" align="stretch" p="8" spacing={4}>
			<Header title="Mark User Attendance In" />
			<VStack w="full" align="stretch">
				<VStack w="full" align="stretch" spacing={4}>
					<MarkManualUserAttendanceBulkInForm />
				</VStack>
			</VStack>
		</VStack>
	)
}

export default MarkAttendanceInPage
