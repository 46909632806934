import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { AlertFragment } from "../../graphql"
import { AlertListItem } from "./AlertListItem"

export type AlertsListProps = {
	alerts: AlertFragment[]
	loadMore: () => void
}

export const AlertsList: React.FC<AlertsListProps> = ({ alerts, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{alerts.map((alert) => (
				<>
					<AlertListItem key={alert._id} alert={alert} />
					<Divider />
				</>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
