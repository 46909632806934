import { Flex, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { ReaderFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type ReaderDetailsProps = {
	reader: ReaderFragment
}

export const ReaderDetails: React.FC<ReaderDetailsProps> = ({ reader }) => {
	return (
		<Card title="Details">
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Name" value={reader.label.name} />
				<Stat label="Type" value={reader.type} />
				<Stat label="UID" value={reader.readerDecimalId} />
				<Stat label="Status" value={reader.isActive ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				<Stat label="Last Heartbeat" value={reader.lastHeartbeatAt ? format(new Date(reader.lastHeartbeatAt), "MMM dd, yyyy HH:mm") : "Not available"} />
				<Stat label="Last Connection" value={reader.lastConnectedAt ? format(new Date(reader.lastConnectedAt), "MMM dd, yyyy HH:mm") : "Not available"} />

				<Stat label="Since" value={format(new Date(reader.createdAt), "MMM dd, yyyy HH:mm")} />
			</Flex>
		</Card>
	)
}
