import { Button, ButtonGroup, Input, Text, useToast, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { ExtendedUserFragment } from "../../graphql"
import { useBlacklistUserMutation } from "../../graphql/generated"
import { UserTag } from "../common"

export type BlacklistUserProps = {
	user: ExtendedUserFragment
}

export const BlacklistUser: React.FC<BlacklistUserProps> = ({ user }) => {
	const [{ fetching }, blacklist] = useBlacklistUserMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const [remarks, setRemarks] = useState<string>("")

	const handleBlacklisting = async () => {
		const { error, data } = await blacklist({ userId: user._id, remarks })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.blacklistUser) {
			toast({ description: "Blacklisted the user successfully", status: "success" })
			navigate(`/users/${user._id}`)
			return
		} else {
			toast({ description: "Couldn't blacklist the user", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<UserTag user={user} />
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to blacklist {user.name}?
				</Text>
				<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter remarks" minLength={2} _placeholder={{ color: "grayscale.placeholer" }} value={remarks} onChange={(e) => setRemarks(e.target.value)} />
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/users/${user._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="red" isLoading={fetching} isDisabled={!remarks.trim()} onClick={handleBlacklisting}>
						Blacklist
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
