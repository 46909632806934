import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useToast } from "@chakra-ui/react"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { MeUserFragment, useSwitchSiteMutation } from "../../graphql"

export type SwitchSiteModalProps = {
	isOpen: boolean
	onClose: () => void
	user: MeUserFragment
}

export const SwitchSiteModal: React.FC<SwitchSiteModalProps> = ({ isOpen, onClose, user }) => {
	const toast = useToast()

	const navigate = useNavigate()

	const [selectedSite, setSelectedSite] = useState<string>(user.siteId)

	const [{ fetching: switchingSite }, switchSite] = useSwitchSiteMutation()

	const onSwitch = async () => {
		const { data, error } = await switchSite({ userId: user._id, siteId: selectedSite })

		if (error) {
			toast({
				title: "Switch Site Failed",
				description: error.message,
				status: "error",
			})

			setTimeout(onClose, 1000)

			return
		}

		if (data) {
			toast({
				title: "Site Switched Successfully",
				status: "success",
			})

			setTimeout(() => {
				navigate("/", { replace: true })
				window.location.reload()
			}, 500)

			return
		}
	}

	return (
		<Modal size="2xl" closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay bgColor="rgba(0,0,0,0.7)" backdropFilter="blur(8px)" />
			<ModalContent>
				<ModalHeader>Switch Site</ModalHeader>
				<ModalCloseButton />
				<ModalBody p={6}>
					<Flex w="full" flexWrap="wrap">
						{user.scopedSites.map((scopedSite) => (
							<Stack
								onClick={() => setSelectedSite(scopedSite._id)}
								key={scopedSite._id}
								maxW="2xs"
								align="center"
								p="4"
								m="4"
								bgColor="grayscale.background"
								transition="background-color, border-color .1s ease-in"
								borderWidth="3px"
								borderStyle="solid"
								backgroundColor={selectedSite === scopedSite._id ? "green.50" : ""}
								borderColor={selectedSite === scopedSite._id ? "green.500" : ""}
								_hover={{ bgColor: "primary.50", borderColor: "primary.100" }}
								rounded="xl"
								cursor="pointer"
								justify="space-between"
							>
								<Text fontSize="md" fontWeight={selectedSite === scopedSite._id ? "semibold" : "medium"}>
									{scopedSite.label.name}
								</Text>
							</Stack>
						))}
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button onClick={onSwitch} isLoading={switchingSite} colorScheme="primary" mr={3}>
						Switch
					</Button>
					<Button onClick={onClose}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
