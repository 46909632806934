import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { DepartmentsByKeywordQueryVariables, useDepartmentsByKeywordQuery } from "../../graphql"
import { DepartmentsList } from "./DepartmentsList"

export type DepartmentsByKeywordProps = {
	keyword: string
}

export const DepartmentsByKeyword: React.FC<DepartmentsByKeywordProps> = ({ keyword }) => {
	const [filter, setFilter] = useState({ keyword })

	const [pagination, setPagination] = useState<DepartmentsByKeywordQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useDepartmentsByKeywordQuery({
		variables: { ...filter, pagination },
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ keyword })
		}, 1000)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.departmentsByKeyword.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.departmentsByKeyword ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading departments</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.departmentsByKeyword.departments && data.departmentsByKeyword.departments.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.departmentsByKeyword.departments.length || 0} department
						{(data.departmentsByKeyword.departments.length || 0) > 1 ? "s" : ""}
					</Text>

					<DepartmentsList departments={data.departmentsByKeyword.departments} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more departments</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any departments.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
