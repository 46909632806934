/* eslint-disable @typescript-eslint/indent */
import { Center, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { CheckCircle, Clock, TrendingDown, TrendingUp, XCircle } from "react-feather"
import { useUserCurrentRouteRecordQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Card } from "../common"
import { UserRouteRecord } from "./UserRouteRecord"

export type UserCurrentRouteProps = {
	userId: string
}

export const UserCurrentRoute: React.FC<UserCurrentRouteProps> = ({ userId }) => {
	const [{ data, fetching, error }] = useUserCurrentRouteRecordQuery({ variables: { userId } })

	return (
		<Card title="Current Route">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading current route</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userCurrentRouteRecord ? (
				<VStack w="full" align="flex-start" bgColor="whiteAlpha.400" p="4" rounded="xl" spacing={4}>
					<HStack w="full" justify="space-between">
						{!data.userCurrentRouteRecord.isRouteCorrect ? (
							<HStack bgColor="red.100" px="2" py="1" rounded="lg">
								<Heading fontSize="sm" color="red.700" fontWeight="semibold">
									Route
								</Heading>
								<Icon fontSize="sm" color="red.700" as={XCircle} />
							</HStack>
						) : (
							<HStack bgColor="green.100" px="2" py="1" rounded="lg">
								<Heading fontSize="sm" color="green.700" fontWeight="semibold">
									Route
								</Heading>
								<Icon fontSize="sm" color="green.700" as={CheckCircle} />
							</HStack>
						)}
						{!data.userCurrentRouteRecord.isTimeCorrect ? (
							<HStack bgColor={data.userCurrentRouteRecord.isTimeHigh ? "yellow.100" : data.userCurrentRouteRecord.isTimeLow ? "blue.100" : "red.100"} px="2" py="1" rounded="lg">
								<Heading fontSize="sm" color={data.userCurrentRouteRecord.isTimeHigh ? "yellow.700" : data.userCurrentRouteRecord.isTimeLow ? "blue.700" : "red.700"} fontWeight="semibold">
									Time
								</Heading>
								<Icon
									fontSize="sm"
									color={data.userCurrentRouteRecord.isTimeHigh ? "yellow.700" : data.userCurrentRouteRecord.isTimeLow ? "blue.700" : "red.700"}
									as={data.userCurrentRouteRecord.isTimeHigh ? TrendingUp : data.userCurrentRouteRecord.isTimeLow ? TrendingDown : XCircle}
								/>
							</HStack>
						) : (
							<HStack bgColor="green.100" px="2" py="1" rounded="lg">
								<Heading fontSize="sm" color="green.700" fontWeight="semibold">
									Time
								</Heading>
								<Icon fontSize="sm" color="green.700" as={CheckCircle} />
							</HStack>
						)}
						{data.userCurrentRouteRecord.timeTakenInMinutes && (
							<HStack bgColor={data.userCurrentRouteRecord.isTimeCorrect ? "green.100" : "red.100"} px="2" py="1" rounded="lg">
								<Heading fontSize="sm" color={data.userCurrentRouteRecord.isTimeCorrect ? "green.700" : "red.700"} fontWeight="semibold">
									{getDurationInWords(data.userCurrentRouteRecord.timeTakenInMinutes * 60)}
								</Heading>
								<Icon fontSize="sm" color={data.userCurrentRouteRecord.isTimeCorrect ? "green.700" : "red.700"} as={Clock} />
							</HStack>
						)}
					</HStack>

					<UserRouteRecord userRouteRecord={data.userCurrentRouteRecord} />
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find current route
					</Text>
				</Center>
			)}
		</Card>
	)
}
