import { Button, Center, Heading, chakra, HStack, Spinner, Text, useDisclosure, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { useMeQuery, useTodayAttendanceStatsQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { UpdateManpowerCountModal } from "../common"

export const TodayPresentUsers: React.FC = () => {
	const [{ data, fetching, error }] = useTodayAttendanceStatsQuery({ variables: { date: format(new Date(), "yyyy-MM-dd") } })

	const [{ data: meData }] = useMeQuery()

	const canUpdateManpowerCount = useMemo(() => {
		if (!meData) return false

		return checkForPermissions(meData.me, "sites/update-manpower-count")[0]
	}, [data, meData])

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<VStack flex="1" align="stretch" spacing={4} p="1">
			<Heading fontSize="sm">Today&apos;s present users</Heading>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.todayAttendanceStats ? (
				<VStack w="full" align="stretch">
					<Button onClick={onOpen} h="full" variant={canUpdateManpowerCount ? "link" : "unstyled"}>
						<VStack w="full" align="stretch">
							<HStack>
								<VStack align="flex-start">
									<Heading color="primary.400">
										{data.todayAttendanceStats?.present.length || 0}
										{meData?.me?.site.manpowerCount ? `/${meData.me.site.manpowerCount}` : ""}{" "}
										<chakra.span fontSize="sm" fontWeight="normal">
											users
										</chakra.span>
									</Heading>
								</VStack>
							</HStack>
						</VStack>
						{isOpen && canUpdateManpowerCount && <UpdateManpowerCountModal isOpen={isOpen} onClose={onClose} meData={meData?.me} />}
					</Button>
					<Text fontSize="sm">{format(new Date(), "MMM dd, yyyy")}</Text>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
