import { AspectRatio, Badge, Box, BoxProps, Center, HStack, Icon, Link, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds, format, isToday } from "date-fns"
import { motion, MotionProps } from "framer-motion"
import React, { ComponentType, Fragment } from "react"
import { Shield } from "react-feather"
import { NavLink } from "react-router-dom"
import { ExtendedRoutePointFragment, RouteFindingFragment } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { RouteCheckpoint } from "./RouteCheckpoint"
import { RouteFinding } from "./RouteFinding"

export type UserRouteRecordProps = {
	index: number
	totalRoutePointsLength: number
	routeFindings: RouteFindingFragment[]
	userRoutePoint: ExtendedRoutePointFragment
}

const MotionBox = motion<MotionProps & Omit<BoxProps, "transition">>(Box as unknown as ComponentType<MotionProps & Omit<BoxProps, "transition">>)

export const UserRoutePoint: React.FC<UserRouteRecordProps> = ({ index, totalRoutePointsLength, routeFindings, userRoutePoint }) => (
	<Fragment>
		<MotionBox key={index} w="full" initial={{ opacity: 0, translateX: "24px" }} animate={{ opacity: 1, translateX: 0 }} transition={{ delay: Math.min(index / 10, 2) }}>
			<HStack w="full" h="full" align="stretch">
				<Box
					pos="relative"
					w="2"
					bgColor={userRoutePoint.isSkipped ? "error.500" : "success.500"}
					ml={2}
					mb={index === (totalRoutePointsLength ?? 1) - 1 ? 0 : -2}
					roundedTop={index === 0 ? "full" : undefined}
					roundedBottom={index === (totalRoutePointsLength ?? 1) - 1 ? "full" : undefined}
				/>
				<VStack w="full" align="stretch">
					<HStack w="full" justifyContent="space-between" bgColor="whiteAlpha.600" px="4" py="2" rounded="md">
						<AspectRatio pos="absolute" left="0" w="6" ratio={1}>
							<Center p="1" bgColor={userRoutePoint.isSkipped ? "error.500" : "success.500"} rounded="full">
								<Icon as={Shield} fontSize="md" color="white" />
							</Center>
						</AspectRatio>
						<VStack align="flex-start" spacing={0}>
							<VStack align="flex-start" spacing={0}>
								<Link as={NavLink} to={`/zones/${userRoutePoint.zoneId}`} fontSize="sm" fontWeight="bold" color={userRoutePoint.isSkipped ? "error.500" : "success.500"}>
									{userRoutePoint.zone.label.name}
								</Link>

								{userRoutePoint.locationRecord && (
									<HStack>
										<Text fontSize="xs" color="blackAlpha.700">
											{format(new Date(userRoutePoint.locationRecord.startAt), isToday(new Date(userRoutePoint.locationRecord.startAt)) ? "HH:mm" : "MMM dd, HH:mm")} -{" "}
											{format(new Date(userRoutePoint.locationRecord.endAt), isToday(new Date(userRoutePoint.locationRecord.endAt)) ? "HH:mm" : "MMM dd, HH:mm")}
										</Text>
										<Badge fontSize="xs" fontWeight="semibold" textTransform="unset" color="blackAlpha.700">
											{getDurationInWords(differenceInSeconds(new Date(userRoutePoint.locationRecord.endAt), new Date(userRoutePoint.locationRecord.startAt)) || 1)}
										</Badge>
									</HStack>
								)}
							</VStack>

							{userRoutePoint.isSkipped && userRoutePoint.skipRemarks && (
								<Text fontSize="xs" color="yellow.500">
									Skipped for <strong>{userRoutePoint.skipRemarks}</strong>
								</Text>
							)}

							<RouteCheckpoint label={`Guard is ${userRoutePoint.guardOnDuty ? "" : " not "} on duty`} image={userRoutePoint.guardImage} isOk={userRoutePoint.guardOnDuty} />
							<RouteCheckpoint label={`Area is ${userRoutePoint.areaIntact ? "" : " not "} intact`} image={userRoutePoint.areaImage} isOk={userRoutePoint.areaIntact} />
						</VStack>

						<Text fontSize="xs" fontWeight="semibold">
							{format(new Date(userRoutePoint.createdAt), isToday(new Date(userRoutePoint.createdAt)) ? "HH:mm" : "MMM dd, HH:mm")}
						</Text>
					</HStack>
					{routeFindings.map((rf, index) => (
						<RouteFinding key={`${userRoutePoint.zoneId}.findings.${index}`} finding={rf} bgColor={userRoutePoint.isSkipped ? "error.500" : "success.500"} />
					))}
				</VStack>
			</HStack>
		</MotionBox>
	</Fragment>
)
