import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react"
import React from "react"
import { UpdateManpowerCountForm } from "../../forms"
import { MeUserFragment } from "../../graphql"

export type UpdateManpowerCountModalProps = {
	isOpen: boolean
	onClose: () => void
	meData: MeUserFragment | undefined | null
}

export const UpdateManpowerCountModal: React.FC<UpdateManpowerCountModalProps> = ({ isOpen, onClose, meData }) => {
	return (
		<Modal size="md" closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay bgColor="rgba(0,0,0,0.7)" backdropFilter="blur(8px)" />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody p={6}>
					<UpdateManpowerCountForm site={meData?.site} onClose={onClose} />
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
