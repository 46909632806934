import { List, VStack } from "@chakra-ui/react"
import React, { Fragment } from "react"
import { ExtendedAlertFragment } from "../../graphql"
import { Card } from "../common"
import { UserListItem } from "../users/UserListItem"

export type EmailAlertedUsersProps = {
	alert: ExtendedAlertFragment
}

export const EmailAlertedUsers: React.FC<EmailAlertedUsersProps> = ({ alert }) => {
	return (
		<Fragment>
			{alert.levels.map((level, index) => (
				<Card key={index} title={`Email Alerted Users - Level ${index + 1}`}>
					<List spacing={2}>
						<VStack w="full" align="stretch" key={index}>
							{level.emailedUsers?.map((user) => (
								<UserListItem key={user._id} user={user} />
							))}
						</VStack>
					</List>
				</Card>
			))}
		</Fragment>
	)
}
