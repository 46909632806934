import { Link, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { Fragment } from "react"
import { MobileAppFragment } from "../../graphql"

export type MobileAppTableProps = {
	mobileApps: MobileAppFragment[]
}

export const MobileAppTable: React.FC<MobileAppTableProps> = ({ mobileApps }) => {
	return (
		<VStack w="full" align="stretch" h="full" overflow="auto" spacing={0}>
			<Table size="sm">
				<Thead>
					<Tr>
						<Th>Version</Th>
						<Th>Type</Th>
						<Th>Changes</Th>
						<Th>Upload Date</Th>
					</Tr>
				</Thead>
				<Tbody>
					{mobileApps.map((mobileApp, index) => (
						<Fragment key={index}>
							<Tr key={index} bgColor="transparent">
								<Td>
									<Link onClick={() => window.open(mobileApp.url)} key={index} textColor="primary.500">
										{mobileApp.version}
									</Link>
								</Td>
								<Td>{mobileApp.type}</Td>
								<Td whiteSpace="pre-line">{mobileApp.changes}</Td>
								<Td>{format(new Date(mobileApp.createdAt), "MMM dd, yyyy HH:mm")}</Td>
							</Tr>
						</Fragment>
					))}
				</Tbody>
			</Table>
		</VStack>
	)
}
