import { Box, HStack, Icon, IconButton } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { X } from "react-feather"
import { TopBannerStatus } from "../../context/TopBanner/types"
import { useTopBannerContext } from "../../hooks"

export type TopBannerType = {
	isClosable?: boolean
}

export const TopBanner: React.FC<TopBannerType> = ({ isClosable = true }) => {
	const { isVisible, status, children, dispatch, setInvisible } = useTopBannerContext()

	const colorScheme = useMemo(() => {
		switch (status) {
			case TopBannerStatus.INFO: {
				return "secondary"
			}

			case TopBannerStatus.ERROR: {
				return "error"
			}

			case TopBannerStatus.SUCCESS: {
				return "success"
			}

			case TopBannerStatus.WARNING: {
				return "warning"
			}
		}
	}, [status])

	if (!isVisible) return <></>

	return (
		<HStack w="full" justify="space-between" p="2" bgColor={`${colorScheme}.50`}>
			<Box w="full">{children}</Box>
			{isClosable && (
				<IconButton aria-label="close-btn" alignSelf="flex-start" size="sm" variant="ghost" colorScheme={colorScheme} onClick={() => dispatch(setInvisible())}>
					<Icon as={X} />
				</IconButton>
			)}
		</HStack>
	)
}
