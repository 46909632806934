import { Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalProps, VStack } from "@chakra-ui/react"
import React from "react"

export type UserRouteCheckpointImageModalProps = {
	label: string
	imageUrl: string
} & Omit<ModalProps, "children">

export const UserRouteCheckpointImageModal: React.FC<UserRouteCheckpointImageModalProps> = ({ label, imageUrl, isOpen, onClose }) => {
	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>
					<Heading fontSize="md" alignSelf="flex-start">
						{label}
					</Heading>
				</ModalHeader>
				<ModalBody as={VStack} w="full" spacing={4}>
					<Image src={imageUrl} alt={label} />
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
