/* eslint-disable @typescript-eslint/indent */
import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Table, Tbody, Td, Text, Tr, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ExtendedUserFragment, useMeQuery, useUpdateUserScopedSitesMutation } from "../graphql"

type UpdateUserScopedSitesFormValues = {
	scopedSiteIds: string[]
}

const validationSchema = yup.object<Record<keyof UpdateUserScopedSitesFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	scopedSiteIds: yup.array(yup.string()).required().min(1).label("Scoped Sites"),
})

export type UpdateUserScopedSitesFormProps = {
	user: ExtendedUserFragment
}

export const UpdateUserScopedSitesForm: React.FC<UpdateUserScopedSitesFormProps> = ({ user }) => {
	const initialValues: UpdateUserScopedSitesFormValues = {
		scopedSiteIds: user.scopedSiteIds,
	}

	const [{ fetching }, updateUserScopedSites] = useUpdateUserScopedSitesMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ scopedSiteIds }: UpdateUserScopedSitesFormValues) => {
		const { data, error } = await updateUserScopedSites({ userId: user._id, siteIds: scopedSiteIds })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateUserScopedSites) {
			navigate(`/users/${user._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateUserScopedSitesFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: meData }] = useMeQuery()

	const handleChange = (siteId: string, isChecked: boolean) => {
		if (isChecked) {
			const updatedValues = [...formik.values.scopedSiteIds, siteId]
			formik.setFieldValue("scopedSiteIds", updatedValues)
		} else {
			const updatedValues = formik.values.scopedSiteIds.filter((id) => id !== siteId)
			formik.setFieldValue("scopedSiteIds", updatedValues)
		}
	}

	const isSiteSelected = useCallback((siteId: string) => formik.values.scopedSiteIds.includes(siteId), [formik.values.scopedSiteIds])

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.scopedSiteIds && formik.errors.scopedSiteIds)}>
					<FormLabel fontWeight="bold">Scoped Sites</FormLabel>

					<Table>
						<Tbody>
							{meData?.me?.scopedSites.map((scopedSite) => (
								<Tr key={scopedSite._id}>
									<Td>
										<Checkbox isChecked={isSiteSelected(scopedSite._id)} onChange={(e) => handleChange(scopedSite._id, e.target.checked)} />
									</Td>
									<Td>
										<Text>{scopedSite.label.name}</Text>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>

					<FormErrorMessage>{formik.errors.scopedSiteIds}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
