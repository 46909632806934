import { Icon, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"
import { Info } from "react-feather"

export const DescriptionPopover: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<Popover>
			<PopoverTrigger>
				<Icon as={Info} color="grayscale.placeholer" />
			</PopoverTrigger>
			<PopoverContent w="fit-content">
				<PopoverBody>
					{typeof children === "string" ? (
						<Text fontSize="sm" color="grayscale.body" textAlign="center">
							{children}
						</Text>
					) : (
						children
					)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
