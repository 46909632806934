import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateUserPermissionsGroupsForm } from "../../forms"
import { ExtendedUserFragment, useUserPermissionsGroupsQuery } from "../../graphql"

export type UpdateUserPermissionsGroupsProps = {
	user: ExtendedUserFragment
}

export const UpdateUserPermissionsGroups: React.FC<UpdateUserPermissionsGroupsProps> = ({ user }) => {
	const [{ data, fetching, error }] = useUserPermissionsGroupsQuery({ variables: { userId: user._id } })

	return (
		<VStack w="full" maxW="sm" spacing={6}>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading permissions groups</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userPermissionsGroups ? (
				<UpdateUserPermissionsGroupsForm user={user} userPermissionsGroupIds={data.userPermissionsGroups.map((g) => g._id)} />
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find permissions groups.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
