import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Icon, IconButton, Input, InputGroup, InputRightElement, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent, useState } from "react"
import { Eye, EyeOff } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ChangePasswordMutationVariables, useChangePasswordMutation } from "../graphql"

type ChangePasswordFormValues = ChangePasswordMutationVariables

const validationSchema = yup.object<Record<keyof ChangePasswordFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	currentPassword: yup.string().required().label("Current Password"),
	newPassword: yup.string().required().min(6).label("New Password"),
	shouldLogOutOnOtherDevices: yup.boolean().required().label("Should log out on other devices"),
})

const initialValues: ChangePasswordFormValues = {
	currentPassword: "",
	newPassword: "",
	shouldLogOutOnOtherDevices: true,
}

export const ChangePasswordForm: React.FC = () => {
	const [{ fetching }, changePassword] = useChangePasswordMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: ChangePasswordFormValues) => {
		const { data, error } = await changePassword(values)

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.changePassword) {
			toast({
				description: "Password changed successfully",
				status: "success",
			})

			navigate("/", { replace: true })

			return
		}
	}

	const formik = useFormik<ChangePasswordFormValues>({ initialValues, validationSchema, onSubmit })

	const [showCurrentPassword, setShowCurrentPassword] = useState(false)
	const [showNewPassword, setShowNewPassword] = useState(false)

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.currentPassword && formik.errors.currentPassword)}>
					<FormLabel fontWeight="bold">Current Password</FormLabel>
					<InputGroup>
						<Input
							autoComplete="current-password"
							variant="filled"
							bgColor="grayscale.input-background"
							type={showCurrentPassword ? "text" : "password"}
							placeholder="Enter current password"
							_placeholder={{ color: "grayscale.placeholer" }}
							{...formik.getFieldProps("currentPassword")}
						/>
						<InputRightElement>
							<IconButton size="sm" aria-label="show-password-btn" icon={<Icon as={showCurrentPassword ? EyeOff : Eye} />} variant="ghost" onClick={() => setShowCurrentPassword((prev) => !prev)} />
						</InputRightElement>
					</InputGroup>
					<FormErrorMessage>{formik.errors.currentPassword}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.newPassword && formik.errors.newPassword)}>
					<FormLabel fontWeight="bold">New Password</FormLabel>
					<InputGroup>
						<Input
							autoComplete="new-password"
							variant="filled"
							bgColor="grayscale.input-background"
							type={showNewPassword ? "text" : "password"}
							placeholder="Enter new password"
							_placeholder={{ color: "grayscale.placeholer" }}
							{...formik.getFieldProps("newPassword")}
						/>
						<InputRightElement>
							<IconButton size="sm" aria-label="show-password-btn" icon={<Icon as={showNewPassword ? EyeOff : Eye} />} variant="ghost" onClick={() => setShowNewPassword((prev) => !prev)} />
						</InputRightElement>
					</InputGroup>
					<FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.shouldLogOutOnOtherDevices && formik.errors.shouldLogOutOnOtherDevices)}>
					<FormLabel fontWeight="bold">Security</FormLabel>
					<Checkbox isChecked={formik.values.shouldLogOutOnOtherDevices || false} onChange={(e) => formik.setFieldValue("shouldLogOutOnOtherDevices", e.target.checked)} colorScheme="primary">
						Should log out from other devices?
					</Checkbox>
					<FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Change
			</Button>
		</VStack>
	)
}
