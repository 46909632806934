import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { ExtendedUserFragment, useActivateUserMutation } from "../../graphql"
import { UserTag } from "../common"

export type ActivateUserProps = {
	user: ExtendedUserFragment
}

export const ActivateUser: React.FC<ActivateUserProps> = ({ user }) => {
	const [{ fetching }, activate] = useActivateUserMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleActivate = async () => {
		const { error, data } = await activate({ userId: user._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.activateUser) {
			toast({ description: "Activated the user successfully", status: "success" })
			navigate(`/users/${user._id}`)
			return
		} else {
			toast({ description: "Couldn't activate the user", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<UserTag user={user} />
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to activate {user.name}?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/users/${user._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="success" isLoading={fetching} onClick={handleActivate}>
						Activate
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
