import { TopBannerContextAction, TopBannerContextActions, TopBannerContextState, TopBannerStatus } from "./types"

export const initialState: TopBannerContextState = {
	isVisible: false,
	status: TopBannerStatus.INFO,
}

export const topBannerContextReducer = (state: TopBannerContextState, action: TopBannerContextAction): TopBannerContextState => {
	switch (action.type) {
		case TopBannerContextActions.SET_VISIBLE: {
			return { ...state, isVisible: true }
		}

		case TopBannerContextActions.SET_INVISIBLE: {
			return { ...state, isVisible: false, children: null }
		}

		case TopBannerContextActions.SET_STATUS: {
			return { ...state, status: action.payload?.status || state.status }
		}

		case TopBannerContextActions.SET_CHILDREN: {
			return { ...state, children: action.payload?.children }
		}

		default:
			return state
	}
}
