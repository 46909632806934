import { Flex, HStack, Link, Text, VStack } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserRoleGroupFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type UserRoleGroupDetailsProps = {
	userRoleGroup: ExtendedUserRoleGroupFragment
}

export const UserRoleGroupDetails: React.FC<UserRoleGroupDetailsProps> = ({ userRoleGroup }) => {
	return (
		<Card title="Details">
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(userRoleGroup.type)} />
				<Stat
					label="Department"
					value={
						<Link as={NavLink} to={`/departments/${userRoleGroup.departmentId}`}>
							{userRoleGroup.department.label.name}
						</Link>
					}
				/>
				{userRoleGroup.workShift && (
					<Stat
						label="Work Shift"
						value={
							<Link as={NavLink} to={`/workShifts/${userRoleGroup.workShiftId}`}>
								{userRoleGroup.workShift.label.name}
							</Link>
						}
					/>
				)}
				{userRoleGroup.allowedZoneGroups?.length ? (
					<Stat
						label="Allowed Zone Groups"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{userRoleGroup.allowedZoneGroups.map((o, i) => (
									<Link key={o._id} as={NavLink} to={`/zones/groups/${o._id}`}>
										{o.label.name}
										{i < (userRoleGroup.allowedZoneGroups?.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						}
					/>
				) : (
					<></>
				)}
				{userRoleGroup.allowedZones?.length ? (
					<Stat
						label="Allowed Zones"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{userRoleGroup.allowedZones.map((o, i) => (
									<Link key={o._id} as={NavLink} to={`/zones/${o._id}`}>
										{o.label.name}
										{i < (userRoleGroup.allowedZones?.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						}
					/>
				) : (
					<></>
				)}
				{userRoleGroup.patrollingRoute ? (
					<Stat
						label="Patrolling Route"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								<Stat label="Name" value={userRoleGroup.patrollingRoute.label.name} />
								<Stat label="Min. Time" value={userRoleGroup.patrollingRoute.minTime} />
								<Stat label="Max. Time" value={userRoleGroup.patrollingRoute.maxTime} />
								<VStack w="full" align="stretch">
									<HStack flexWrap="wrap" justify="center" maxW="xs">
										{userRoleGroup.patrollingRoute.zoneIds
											.map((id) => userRoleGroup.patrollingRoute?.zones.find((z) => z._id === id))
											.filter(Boolean)
											.map((zone, index) => (
												<Link key={index} as={NavLink} to={`/zones/${zone?._id}`}>
													{zone?.label.name}
													{index < (userRoleGroup.patrollingRoute?.zones?.length || 0) && ","}
												</Link>
											))}
									</HStack>
									<Text textAlign="center" fontSize="xs" color="grayscale.label">
										Route
									</Text>
								</VStack>
							</HStack>
						}
					/>
				) : (
					<></>
				)}
			</Flex>
		</Card>
	)
}
