import { Box, Text, VStack } from "@chakra-ui/react"
import React from "react"

export type StatProps = {
	label: string | number | React.ReactNode
	value: string | number | React.ReactNode
}

export const Stat: React.FC<StatProps> = ({ label, value }) => {
	return (
		<Box p="4">
			<VStack w="full" spacing={0}>
				{typeof value === "string" || typeof value === "number" ? (
					<Text textAlign="center" fontSize="sm" fontWeight="bold" color="grayscale.body">
						{value}
					</Text>
				) : (
					<VStack w="full" textAlign="center" fontSize="sm" fontWeight="bold" color="grayscale.body">
						{value}
					</VStack>
				)}
				{typeof label === "string" || typeof label === "number" ? (
					<Text textAlign="center" fontSize="xs" color="grayscale.label">
						{label}
					</Text>
				) : (
					<VStack w="full" textAlign="center" fontSize="xs" color="grayscale.label">
						{label}
					</VStack>
				)}
			</VStack>
		</Box>
	)
}
