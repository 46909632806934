import { Text } from "@chakra-ui/react"
import React, { Fragment, PropsWithChildren, useMemo } from "react"
import { useMeQuery } from "../../graphql"
import { checkForPermissions, checkForSomePermissions } from "../../utils"

export type PermissionsProps = {
	tags: string[]
	checkType?: "and" | "or"
}

export const Permissions: React.FC<PermissionsProps & PropsWithChildren> = ({ tags, checkType = "and", children }) => {
	const [{ data }] = useMeQuery()

	const [isAllowed, requiredTags] = useMemo(() => {
		if (!data?.me) return [false, tags]

		if (checkType === "and") {
			return checkForPermissions(data.me, ...tags)
		}

		return checkForSomePermissions(data.me, ...tags)
	}, [data, tags, children])

	return isAllowed ? <Fragment>{children}</Fragment> : <Text>Not allowed, require {requiredTags.join(",")}</Text>
}
