import { Select, VStack } from "@chakra-ui/react"
import { startCase } from "lodash"
import React, { useState } from "react"
import { AlertsByFilter, AllAlerts, Header } from "../../components"
import { AlertStatus, AlertTypes } from "../../graphql"

export const StandardDashboardAlertsTab: React.FC = () => {
	const [status, setStatus] = useState<AlertStatus | undefined>()
	const [type, setType] = useState<AlertTypes | undefined>()

	return (
		<VStack w="full" align="stretch" p="0" spacing={4}>
			<Header title="Alerts">
				<Select variant="filled" bgColor="grayscale.input-background" placeholder="Type" _placeholder={{ color: "grayscale.placeholer" }} value={type} onChange={(e) => setType((e.target.value as AlertTypes) || undefined)}>
					{Object.values(AlertTypes).map((type) => (
						<option key={type} value={type}>
							{startCase(type)}
						</option>
					))}
				</Select>
				<Select variant="filled" bgColor="grayscale.input-background" placeholder="Status" _placeholder={{ color: "grayscale.placeholer" }} value={status} onChange={(e) => setStatus((e.target.value as AlertStatus) || undefined)}>
					{Object.values(AlertStatus).map((status) => (
						<option key={status} value={status}>
							{status}
						</option>
					))}
				</Select>
			</Header>

			{status || type ? <AlertsByFilter status={status} type={type} /> : <AllAlerts />}
		</VStack>
	)
}
