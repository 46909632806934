import { Center, HStack, LinkBox, LinkOverlay, Icon, ListItem, Spinner, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, List, VStack } from "@chakra-ui/react"
import React from "react"
import { Clock } from "react-feather"
import { NavLink } from "react-router-dom"
import { usePatrollingUsersDashboardStatsQuery } from "../../graphql"
import { UserTag } from "../common"

export const PatrollingUsersStatsTable: React.FC = () => {
	const [{ data, fetching, error }] = usePatrollingUsersDashboardStatsQuery()

	return (
		<VStack w="full" align="stretch" h="full" spacing={0}>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading stats</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.patrollingUsersDashboardStats && data.patrollingUsersDashboardStats.length > 0 ? (
				<VStack w="full" align="stretch" spacing={0}>
					<Table size="sm">
						<Thead>
							<Tr>
								<Th>User</Th>
								<Th>Completed Rounds</Th>
								<Th>Time Taken</Th>
								<Th>Expected Rounds</Th>
								<Th>Assigned Route</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data.patrollingUsersDashboardStats.map((data, index) => (
								<Tr key={index} bgColor="transparent">
									<Td>
										<Stack as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
											<LinkOverlay as={NavLink} to={`/users/${data.user._id}`} />
											<HStack justifyContent="space-between" p="2">
												<UserTag user={data.user} />
											</HStack>
										</Stack>
									</Td>
									<Td>{data.completedRoundsWithTimeTaken.length} </Td>
									<Td>
										<List spacing={3}>
											{data.completedRoundsWithTimeTaken.map((cr, i) => (
												<ListItem key={i}>
													<HStack spacing={2}>
														<Icon as={Clock} />
														<Text fontWeight="normal">{cr} mins.</Text>
													</HStack>
												</ListItem>
											))}
										</List>
									</Td>
									<Td>{data.expectedRounds}</Td>
									<Td>{data.assignedRoute}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find the data.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
